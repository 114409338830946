import { Icon, ImagePicker, NavBar } from "antd-mobile";
import React, { ComponentClass } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import "./index.css";
import { get, post, postFile } from "../../axios/axios";
import { PhoneOutlined } from "@ant-design/icons";
import { message } from "antd";
import { connect } from "react-redux";
import { FROM } from "../../store/enmu";
import { LoginInfo, UserInfo } from "../../store/type";
import Modal from "antd/lib/modal/Modal";
interface IProps extends LoginInfo, RouteComponentProps {}
interface IState {
  detail?: DetailType;
  screenshotImages: [];
  type?: string;
  telephone: string;
  zoomModal: boolean;
  imgIndex: number;
}
interface DetailType {
  title: string;
  content: string;
  screenshotImages: string;
  replyContent: string;
  createTime: string;
  userName: string;
}

const mapStateToProps = (
  state: LoginInfo
): {
  userInfo?: UserInfo;
  from: FROM | null;
  padding?: string;
} => ({
  userInfo: state.userInfo,
  from: state.from,
  padding: state.padding,
});
declare var window: {
  [key: string]: any; // missing index defintion
  prototype: Window;
  new (): Window;
};
class ComplaintDetail extends React.PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      screenshotImages: [],
      telephone: "",
      zoomModal: false,
      imgIndex: 0,
    };
    this.getData();
  }
  getData = async () => {
    let path = this.props.location.pathname;
    let array = path.split("/");
    let id = array[array.length - 1];
    let type = array[array.length - 2];
    let res = await get("/api/complaints/details", {
      complaintId: parseInt(id),
    });
    let imgPath = res.data.complaint.screenshotImages;
    let imgList = [];
    if (imgPath) {
      imgList = imgPath.split(",");
    }
    this.setState({
      detail: res.data.complaint,
      screenshotImages: imgList,
      type: type,
    });
    let telephone = await get("/api/phone/details");
    this.setState({
      telephone: telephone.data.searchPhone,
    });
  };
  formatDate(time: string) {
    const date = new Date(parseInt(time));
    const year = date.getFullYear();
    const mon = date.getMonth() + 1;
    const day = date.getDate();
    const H = date.getHours();
    const Min = date.getMinutes();
    const S = date.getSeconds();
    return year + "-" + mon + "-" + day + " " + H + ":" + Min + ":" + S;
  }
  tel(phone: string) {
    if (this.props.from === FROM.APP) {
      window["jsTel"].postMessage(phone);
    }
  }
  render() {
    const { detail, screenshotImages, type, zoomModal, imgIndex } = this.state;
    return (
      <div className="complaintDetail">
        <div
          className="orderHeader"
          style={
            this.props.padding
              ? { marginTop: JSON.parse(this.props.padding).top + "px" }
              : {}
          }
        >
          {this.props.from != FROM.WXMINI? <NavBar
            className="navBar"
            icon={
              <Icon type="left" onClick={() => this.props.history.goBack()} />
            }
          >
            {(() => {
              switch (type) {
                case "complaint":
                  return "投诉举报详情";
                case "suggestion":
                  return "咨询建议详情";
                case "find":
                  return "寻人寻物";
              }
            })()}
          </NavBar>: null}
        </div>

        {type === "find" && detail ? (
          <div className="findMainBox">
            <div className="title">{detail.title}</div>
            <div className="time">
              <span>{this.formatDate(detail.createTime)}</span>
              <span></span>
            </div>
            <div
              className="content"
              dangerouslySetInnerHTML={{
                __html: detail.content ? detail.content : "",
              }}
            ></div>
            <div
              className="footer"
              onClick={() => {
                this.tel(this.state.telephone);
              }}
            >
              <PhoneOutlined />

              {this.props.from === FROM.APP ? (
                <a>呼叫服务人员</a>
              ) : (
                <a href={"tel: " + this.state.telephone}>呼叫服务人员</a>
              )}
            </div>
          </div>
        ) : (
          <div className="main">
            {detail ? (
              <div className="formBox">
                <div className="title">
                  <span>标题</span>
                  {detail.title}
                </div>
                <div className="content">
                  <span>内容</span>
                  {detail.content}
                </div>
                {screenshotImages.length >= 1 ? (
                  <div className="selectImg">
                    {screenshotImages.map((item, key) => {
                      return (
                        <div className="imgBox">
                          <img
                            src={item}
                            alt=""
                            onClick={this.showImg.bind(this.setState, key)}
                          />
                          <Modal
                            visible={zoomModal}
                            className="zoomModal"
                            footer={null}
                            maskClosable={true}
                            closable={false}
                            onCancel={this.onClose}
                          >
                            <div className="zoomImg">
                              <img src={screenshotImages[imgIndex]} alt="" />
                            </div>
                          </Modal>
                        </div>
                      );
                    })}
                  </div>
                ) : null}
                <div className="reply">
                  <span>回复</span>
                  {detail.replyContent}
                </div>
              </div>
            ) : null}
          </div>
        )}
      </div>
    );
  }
  showImg = (e: any) => {
    this.setState({
      zoomModal: true,
      imgIndex: e,
    });
  };
  onClose = () => {
    this.setState({
      zoomModal: false,
    });
    return;
  };
}

export default connect(mapStateToProps)(
  withRouter<any, ComponentClass<IProps, IState>>(ComplaintDetail)
);
