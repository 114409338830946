import React, { ComponentClass, FunctionComponent } from 'react'
import { RouterProps, withRouter } from 'react-router-dom';
import style from './index.module.css';

export interface IContentPageProps {
    content: string
}
interface IProps extends RouterProps{
    content: string
}
const ContentPage: React.FC<IProps> =  (props: IProps) => {

    return (<div className={style.contentPage} style={{float: 'left',textAlign: 'left',width: '100%'}} dangerouslySetInnerHTML={{__html: props.content}}></div>)
}

export default withRouter<any, FunctionComponent<IProps>>(ContentPage);