import { Icon, InputItem, NavBar, Tabs, Toast } from "antd-mobile";
import React, { ComponentClass } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import style from "./index.module.css";
import { post } from "../../axios/axios";
import { LoginInfo, UserInfo as user } from "../../store/type";
import { Dispatch } from "redux";
import { login } from "../../store/actions";
import { FROM } from "../../store/enmu";
interface IProps extends RouteComponentProps, LoginInfo {
  onSignout: () => {};
  onLogin: (userinfo: user, token: string) => {};
}
interface IState {
  username: string;
}
const mapDispatchToProps = (dispatch: Dispatch) => ({
  onLogin: (userinfo: user, token: string) => dispatch(login(userinfo, token)),
});
const mapStateToProps = (
  state: LoginInfo
): {
  userInfo?: user;
  from: FROM | null;
  padding?: string;
} => ({
  userInfo: state.userInfo,
  from: state.from,
  padding: state.padding,
});
class EditUsername extends React.PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      username: "",
    };
  }
  render() {
    const { userInfo } = this.props;
    return (
      <div className={style.EditUsername}>
        <div
          className={style.orderHeader}
          style={
            this.props.padding
              ? { marginTop: JSON.parse(this.props.padding).top + "px" }
              : {}
          }
        >
          {this.props.from != FROM.WXMINI ? (
            <NavBar
              className={style.navBar}
              icon={
                <Icon type="left" onClick={() => this.props.history.goBack()} />
              }
              onLeftClick={() => console.log("onLeftClick")}
            >
              修改昵称
            </NavBar>
          ) : null}
        </div>

        <div className={style.main}>
          <div className={style.input}>
            <span>昵称</span>
            {userInfo ? (
              <input
                placeholder={userInfo.username}
                type="text"
                onChange={(e) => {
                  this.setState({
                    username: e.target.value,
                  });
                }}
              />
            ) : null}
          </div>
          <div className={style.submitBtn} onClick={this.submit}>
            提交
          </div>
        </div>
      </div>
    );
  }
  submit = async () => {
    let userInfo = this.props.userInfo;
    if (userInfo && this.state.username) {
      this.props.onLogin(
        { ...userInfo, username: this.state.username },
        localStorage.getItem("userToken") as string
      );
      let res = await post("/api/basis/editUserInfo", {
        ...userInfo,
        username: this.state.username,
      });
      if (res.code === 200) {
        Toast.success("修改成功", 1);
        this.props.history.goBack();
      }
    } else {
      Toast.fail("请输入内容", 1);
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter<any, ComponentClass<IProps, IState>>(EditUsername));
