import React, { ComponentClass } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  Button,
  DatePicker,
  Icon,
  List,
  Modal,
  NavBar,
  Picker,
  Toast,
} from "antd-mobile";
import "./index.css";
import { FROM, LOGINSTATE } from "../../store/enmu";
import { CheckCircleFilled } from "@ant-design/icons";
import { Dispatch } from "redux";
import { login, signout } from "../../store/actions";
import image from "../../asset//Reservation/successImage.png";
import { LoginInfo, UserInfo as user } from "../../store/type";
import { get, post } from "../../axios/axios";
import moment from "moment";
import { Alert, Image } from "antd";
interface IProps extends RouteComponentProps, LoginInfo {
  onSignout: () => {};
  onLogin: (userinfo: user, token: string) => {};
}
interface IState {
  noticeBox: boolean;
  readBtnText: string;
  readBtn: boolean;
  enterDate: any;
  periodAll: any[];
  defaultPeriod: any[];
  periodValue: any;
  abnormal?: any;
  accompanyNum?: number;
  userName?: string;
  idCard?: string;
  phone?: string;
  userInfo: {};
  success: boolean;
  maxNum?: number;
  newIdCard?: string;
  noticText: string;
  notice: string;
  telephone: string;
}
const now = new Date(Date.now());
const mapDispatchToProps = (dispatch: Dispatch) => ({
  onSignout: () => dispatch(signout()),
  onLogin: (userinfo: user, token: string) => dispatch(login(userinfo, token)),
});
const mapStateToProps = (
  state: LoginInfo
): {
  userInfo?: user;
  from: FROM | null;
  padding?: string;
  userToken: string;
  state: LOGINSTATE
} => ({
  userInfo: state.userInfo,
  from: state.from,
  padding: state.padding,
  userToken: state.userToken as string,
  state: state.state
});

function checkIdCard(idCard: string | undefined) {

  if (!idCard) {
    return false;

  }
  // areaCode:地区码  checkCode：最后一位的校验码
  const areaCode = [11, 12, 13, 14, 15, 21, 22, 23, 31, 32, 33, 34, 35, 36, 37, 41, 42, 43, 44, 45, 46, 50, 51, 52, 53, 54, 61, 62, 63, 64, 65, 71, 81, 82, 91];
  const checkCode = ['1', '0', 'X', '9', '8', '7', '6', '5', '4', '3', '2'];

  if (idCard.length === 15) {               // 如果是15位的身份证号码

    // 判断地区码
    const idCardAreaCode = parseInt(idCard.substr(0, 2));
    if (areaCode.indexOf(idCardAreaCode) === -1) {
      return false;
    }

    // 判断时间
    const borthYear = parseInt(idCard.substr(6, 2)) + 1900;
    const isRunNian = (borthYear % 400 === 0) || (borthYear % 100 !== 0 && borthYear % 4 === 0);
    let regStr = /^[1-9][0-9]{5}[0-9]{2}((01|03|05|07|08|10|12)(0[1-9]|[1-2][0-9]|3[0-1])|(04|06|09|11)(0[1-9]|[1-2][0-9]|30)|02(0[1-9]|1[0-9]|2[0-8]))[0-9]{3}$/;
    if (isRunNian) {
      regStr = /^[1-9][0-9]{5}[0-9]{2}((01|03|05|07|08|10|12)(0[1-9]|[1-2][0-9]|3[0-1])|(04|06|09|11)(0[1-9]|[1-2][0-9]|30)|02(0[1-9]|[1-2][0-9]))[0-9]{3}$/;
    }
    if (!idCard.match(regStr)) {
      return false;
    }

    return true;
  } else if (idCard.length === 18) {        // 如果是18位的身份证号码

    // 判断地区码
    const idCardAreaCode = parseInt(idCard.substr(0, 2));
    if (areaCode.indexOf(idCardAreaCode) === -1) {
      return false;
    }

    // 判断时间
    const borthYear = parseInt(idCard.substr(6, 4));
    const isRunNian = (borthYear % 400 === 0) || (borthYear % 100 !== 0 && borthYear % 4 === 0);
    let regStr = /^[1-9][0-9]{5}(19|20)[0-9]{2}((01|03|05|07|08|10|12)(0[1-9]|[1-2][0-9]|3[0-1])|(04|06|09|11)(0[1-9]|[1-2][0-9]|30)|02(0[1-9]|1[0-9]|2[0-8]))[0-9]{3}[0-9Xx]$/;
    if (isRunNian) {
      regStr = /^[1-9][0-9]{5}(19|20)[0-9]{2}((01|03|05|07|08|10|12)(0[1-9]|[1-2][0-9]|3[0-1])|(04|06|09|11)(0[1-9]|[1-2][0-9]|30)|02(0[1-9]|[1-2][0-9]))[0-9]{3}[0-9Xx]$/;
    }
    if (!idCard.match(regStr)) {
      return false;
    }

    // 判断最后一位---校验码
    const sumIdCard = (parseInt(idCard.substr(0, 1)) + parseInt(idCard.substr(10, 1))) * 7 +
      (parseInt(idCard.substr(1, 1)) + parseInt(idCard.substr(11, 1))) * 9 +
      (parseInt(idCard.substr(2, 1)) + parseInt(idCard.substr(12, 1))) * 10 +
      (parseInt(idCard.substr(3, 1)) + parseInt(idCard.substr(13, 1))) * 5 +
      (parseInt(idCard.substr(4, 1)) + parseInt(idCard.substr(14, 1))) * 8 +
      (parseInt(idCard.substr(5, 1)) + parseInt(idCard.substr(15, 1))) * 4 +
      (parseInt(idCard.substr(6, 1)) + parseInt(idCard.substr(16, 1))) * 2 +
      parseInt(idCard.substr(7, 1)) * 1 + parseInt(idCard.substr(8, 1)) * 6 +
      parseInt(idCard.substr(9, 1)) * 3;
    const modNum = checkCode[sumIdCard % 11];
    if (modNum !== idCard.substr(17, 1).toUpperCase()) {
      return false;
    }

    return true
  } else {
    return false;
  }

}
function checkIDCard(idcode: any) {
  // 加权因子
  var weight_factor = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2];
  // 校验码
  var check_code = ['1', '0', 'X', '9', '8', '7', '6', '5', '4', '3', '2'];

  var code = idcode + "";
  var last = idcode[17];//最后一位

  var seventeen = code.substring(0, 17);

  // ISO 7064:1983.MOD 11-2
  // 判断最后一位校验码是否正确
  let arr: any;
  arr = seventeen.split("");
  var len = arr.length;
  var num = 0;
  for (var i = 0; i < len; i++) {
    num = num + arr[i] * weight_factor[i];
  }

  // 获取余数
  var resisue = num % 11;
  var last_no = check_code[resisue];

  // 格式的正则
  // 正则思路
  /*
  第一位不可能是0
  第二位到第六位可以是0-9
  第七位到第十位是年份，所以七八位为19或者20
  十一位和十二位是月份，这两位是01-12之间的数值
  十三位和十四位是日期，是从01-31之间的数值
  十五，十六，十七都是数字0-9
  十八位可能是数字0-9，也可能是X
  */
  var idcard_patter = /^[1-9][0-9]{5}([1][9][0-9]{2}|[2][0][0|1][0-9])([0][1-9]|[1][0|1|2])([0][1-9]|[1|2][0-9]|[3][0|1])[0-9]{3}([0-9]|[X])$/;

  // 判断格式是否正确
  var format = idcard_patter.test(idcode);

  // 返回验证结果，校验码和格式同时正确才算是合法的身份证号码
  return last === last_no && format ? true : false;
}
class Reservation extends React.PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    if (props.state !== LOGINSTATE.SIGNIN) {
      // debugger
      localStorage.setItem('redirectURL', '/reservation')
      localStorage.setItem('jumpLink', '/main/home')
      this.props.history.push('/accountLogin')
    }
    this.state = {
      noticeBox: localStorage.getItem('readKnow') === null,
      readBtnText: "请认真阅读须知（5s）",
      readBtn: true,
      enterDate: null,
      periodAll: [],
      defaultPeriod: [],
      periodValue: "",
      userInfo: {},
      success: false,
      newIdCard: props.userInfo?.idCard && props.userInfo?.idCard !== '' && props.userInfo?.idCard !== null ? props.userInfo?.idCard : "",
      noticText: "",
      notice: "",
      telephone: ""
    };
    this.getRuleData();
    this.getNotice();
    this.getPhone();
  }
  componentDidMount() {
    let maxTime = 5;
    setInterval(() => {
      if (maxTime > 0) {
        --maxTime;
        this.setState({
          readBtnText: "请认真阅读须知（" + maxTime + "s)",
          readBtn: true,
        });
      } else {
        this.setState({
          readBtnText: "我已阅读并同意",
          readBtn: false,
        });

      }
    }, 1000);
    this.getOpening();
  }
  async getPhone() {
    let telephone = await get("/api/phone/details");
    this.setState({
      telephone: telephone.data.searchPhone,
    });
  }

  async getNotice() {
    let notice = await get('/api/notice/list');
    if (notice.code === 200) {
      this.setState({ notice: notice.data[0].noticeContent })
    }
  }
  async getOpening() {
    let res = await get("/api/expoinformat/searchOpeningTime");
    let openingText: string;
    if (res.data.length !== 0) {
      openingText = res.data[0].openingHours;
    } else {
      openingText = "";
    }
    this.setState({ noticText: openingText })
  }

  render() {
    const {
      noticeBox,
      readBtnText,
      readBtn,
      periodAll,
      defaultPeriod,
      success,
      enterDate,
      periodValue,
    } = this.state;
    const { userInfo } = this.props;


    if (userInfo !== undefined) {
      this.setState({ idCard: userInfo?.idCard, phone: userInfo.phone });
      console.log(this.state)
    }

    const options = [
      [
        { label: "无", value: "0" },
        { label: "发热", value: "1" },
        { label: "咳嗽", value: "2" },
        { label: "乏力", value: "3" },
      ],
    ];
    return (
      <div className="Reservation">
        <div
          style={
            this.props.padding
              ? { marginTop: JSON.parse(this.props.padding).top + "px" }
              : {}
          }
        >
          {this.props.from != FROM.WXMINI ? <NavBar
            className="navBar"
            icon={
              <Icon type="left" onClick={() => this.props.history.goBack()} />
            }
          >
            {success ? "预约成功" : "预约入园"}
          </NavBar> : null}
        </div>
        {success ? (
          <div className="reservationSuccess">
            <div className="img">
              <img src={image} alt="" />
            </div>
            <div className="title">
              <CheckCircleFilled
                style={{
                  fontSize: "7vw",
                  color: "#048779",
                }}
              />
              预约成功
            </div>
            <div className="content">
              您{moment(enterDate).format("YYYY-MM-DD")}
              {'\u00A0'}
              {periodValue[0]}
              的入园预约已经提交成功，请入园当日凭预约时填写的证件到园游玩。
              <br />
              感谢您使用第十三届中国（徐州）国际园林博览会预约系统，祝您游园愉快。
              <br />
            </div>
            <p>咨询电话：{this.state.telephone}</p>
            <div className="specialText">
              游玩前可查看 <span onClick={() => {
                this.props.history.replace('/touristNotice/contentList/line');
              }}>路线推荐</span>和<span onClick={() => {
                this.props.history.replace('/touristNotice/contentPage/searchOpeningTime');
              }}>游客须知</span>
            </div>
            <div className="footerBtn">
              <button onClick={() => {
                if (this.props.from === FROM.APP) {
                  this.props.history.replace('/OutURL/' + encodeURIComponent(`https://h5ticket.xzyby.cn/?access_token=${localStorage.getItem('userToken')}&type=1`) + '/预约购票')
                } else if (this.props.from === FROM.H5) {
                  this.props.history.replace('/OutURL/' + encodeURIComponent(`https://wxticket.xzyby.cn/?access_token=${localStorage.getItem('userToken')}&type=1`) + '/预约购票')
                } else if (this.props.from === FROM.WXMINI) {
                  this.props.history.replace('/OutURL/' + encodeURIComponent(`https://ticket.xzyby.cn/?access_token=${localStorage.getItem('userToken')}&type=1`) + '/预约购票')
                }
              }}>预定门票</button>
              <button onClick={() => {
                this.props.history.replace('/reservationList')
              }}>查看预约</button>
            </div>
          </div>
        ) : (
          <div>
            <Modal
              visible={noticeBox}
              transparent
              maskClosable={false}
              className="noticeModal"
              title="入园须知："
            >
              <div className="footer">
                <Button className="agreeBtn" disabled={readBtn} onClick={this.cancelNoticeBox}>
                  {readBtnText}
                </Button>
              </div>
              <div className="noticeModalText" dangerouslySetInnerHTML={{ __html: this.state.notice }}>
              </div>
            </Modal>
            <div className="ReservationBody" style={{
              height: this.props.padding ? 'calc(100vh - ' + (JSON.parse(this.props.padding).top + 45) + 'px)' : 'calc(100vh - 45px)'
            }}>
              <div className="smallText">选择入园时间</div>
              <div className="ReservationItem">
                <DatePicker
                  mode="date"
                  title="入园日期"
                  minDate={new Date()}
                  // defaultValue = {moment()}
                  value={this.state.enterDate}
                  onChange={(date) => {
                    let now;
                    // console.log(periodValue)
                    // alert(periodValue)
                    if (periodValue[0] && periodValue[0].split('-')[1]) {
                      // now = new Date(moment(date).format("YYYY-MM-DD") + ' ' + periodValue[0].split('-')[1])
                      now = new Date((moment(date).format("YYYY-MM-DD") + ' ' + periodValue[0].split('-')[1]).replace(/-/g,'/'))
                    }
                    // console.log(now)
                    // alert(now)
                    // console.log(moment(date).format("YYYY-MM-DD"))
                    // console.log(moment(new Date()).format("YYYY-MM-DD"))
                    // console.log(defaultPeriod[0])
                    // alert(defaultPeriod[0])
                    if (moment(date).format("YYYY-MM-DD") === moment(new Date()).format("YYYY-MM-DD") && now && now < new Date()) {
                      this.setState({
                        enterDate: date,
                        periodValue: [],
                        periodAll: [defaultPeriod[0].filter((e: any) => {
                          if (e.value.split('-')[1]) {
                            // let now = new Date(moment(date).format("YYYY-MM-DD") + ' ' + e.value.split('-')[1]);
                            let now = new Date((moment(date).format("YYYY-MM-DD") + ' ' + e.value.split('-')[1]).replace(/-/g,'/'));
                            if (now > new Date()) {
                              return e;
                            }
                          }
                        })]
                      })
                    } else {
                      this.setState({
                        enterDate: date,
                        periodAll: [defaultPeriod[0].filter((e: any) => {
                          if (e.value.split('-')[1]) {
                            // console.log(moment(date).format("YYYY-MM-DD"))
                            // console.log(e.value.split('-')[1])
                            // alert(moment(date).format("YYYY-MM-DD"))
                            // alert(e.value.split('-')[1])
                            // alert((moment(date).format("YYYY-MM-DD") + ' ' + e.value.split('-')[1]).replace(/-/g,'/'))
                            let now = new Date((moment(date).format("YYYY-MM-DD") + ' ' + e.value.split('-')[1]).replace(/-/g,'/'));
                            // console.log(now)
                            // alert(now)
                            // alert(new Date())
                            // console.log(new Date())
                            if (now > new Date()) {
                              return e;
                            }
                          }
                        })]
                      })
                    }
                  }
                  }
                >
                  <List.Item arrow="horizontal">入园日期</List.Item>
                </DatePicker>
              </div>
              <div className="ReservationItem">
                <Picker
                  data={periodAll}
                  title="选择时段"
                  cascade={false}
                  value={this.state.periodValue}
                  onChange={(e) => this.setState({ periodValue: e })}
                >
                  <List.Item arrow="horizontal">入园时段</List.Item>
                </Picker>
              </div>
              <div className="smallText">完善入园信息</div>
              <div className="ReservationItem">
                <div className="ReservationItemTitle">姓名</div>
                <input
                  type="text"
                  placeholder="请输入"
                  onChange={(e) => {
                    this.setState({
                      userName: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="ReservationItem">
                <div className="ReservationItemTitle">身份证</div>
                {/* defaultValue={userInfo ? userInfo.idCard?.substring(0, 6) + "********" + userInfo.idCard?.substring(14) : undefined} */}
                <input
                  type="text"
                  placeholder="请输入"
                  defaultValue={userInfo && userInfo.idCard ? userInfo.idCard : ''}
                  maxLength={18}
                  onChange={(e) => {
                    console.log(this.state.idCard)
                    this.setState({
                      newIdCard: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="ReservationItem">
                <div className="ReservationItemTitle">手机号</div>
                <input
                  disabled={true}
                  type="text"
                  placeholder="请输入"
                  defaultValue={userInfo ? userInfo.phone?.substring(0, 3) + "****" + userInfo.phone?.substring(7) : undefined}
                  onChange={(e) => {
                    this.setState({
                      phone: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="ReservationItem">
                <Picker
                  data={options}
                  title="选择状况"
                  cascade={false}
                  extra="有无发烧咳嗽乏力等状况"
                  value={this.state.abnormal}
                  onChange={(e) => this.setState({ abnormal: e })}
                >
                  <List.Item arrow="horizontal">有无异常</List.Item>
                </Picker>
              </div>
              <div className="ReservationItem">
                <div className="ReservationItemTitle">随行人数</div>
                <input
                  type="text"
                  placeholder={`最多${this.state.maxNum}人`}
                  onChange={(e) => {
                    this.setState({
                      accompanyNum: Number(e.target.value),
                    });
                  }}
                />
              </div>
              <div className="smallText">入园游客须知</div>
              <div className="noticeBox" dangerouslySetInnerHTML={{ __html: this.state.noticText }}>
                {/* 一、开园时间 <br />{" "}
                1、全年开放时间:全年全天开放（法定节假日除外) <br />{" "}
                2、每日开园时间: <br /> 1) 周一到周五:9:00-17:00 <br />
                2) 周六、周日:9:00-18:00 3) 法定节假日:9:00-19:00
                每日闭园时间提前30分钟停止入园。
                <br />
                3、如有调整，另行通知，敬请关注。
                <br /> 二、售票时间 每日7:30——23:30为预约、售票时间 */}
              </div>
              <div className="footerBox">
                <button
                  onClick={() => this.props.history.push("/reservationList")}
                >
                  预约记录
                </button>
                <button onClick={this.onFinish}>立即预约</button>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
  cancelNoticeBox = () => {
    localStorage.setItem('readKnow', 'read');
    this.setState({
      noticeBox: false,
    });
  };

  getRuleData = async () => {
    let res = await get("/api/reservation/getrules");
    if (res) {
      if (res.code === 200) {
        let arr = res.data.period;
        let maxNum = res.data.accompanyNum;
        let array = arr.split(",");
        let data: Array<any>;
        data = [];
        let dataNew: Array<any>;
        dataNew = [];
        array.forEach((element: any) => {
          let t = {
            label: element,
            value: element,
          };
          dataNew.push(t);
        });
        data.push(dataNew);
        this.setState({
          periodAll: data,
          defaultPeriod: data,
          maxNum: maxNum,
        });
      }
    }
  };

  onFinish = async (values: any) => {
    const {
      enterDate,
      abnormal,
      periodValue,
      userName,
      accompanyNum,
      phone,
      newIdCard
    } = this.state;
    if (!enterDate) {
      Toast.fail("请选择入园时间", 1);
      return;
    } else if (!periodValue) {
      Toast.fail("请选择入园时段", 1);
      return;
    } else if (!userName || userName === "") {
      Toast.fail("请输入姓名", 1);
      return;
    } else if (newIdCard === "") {
      Toast.fail("请输入身份证", 1);
      return;
    } else if (!abnormal) {
      Toast.fail("请选择有无异常", 1);
      return;
    } else if (!accompanyNum && accompanyNum != 0) {
      Toast.fail("请输入随行人数", 1);
      return;
    } else {
      if (Number(abnormal[0]) !== 0) {
        Toast.fail("身体异常，无法预约入园.");
        return;
      }
      let CardNum = this.state.idCard;
      if (newIdCard !== "") {
        CardNum = newIdCard;
      }
      let ret = checkIdCard(CardNum);
      if (!ret) {
        Toast.fail("身份证号码错误，请检查！");
        return;
      }
      // if(accompanyNum === 0) {
      //   Toast.fail('随行人数不可为0');
      // }
      if (accompanyNum > Number(this.state.maxNum)) {
        Toast.fail(`随行人数不能超过${this.state.maxNum}人`);
        return;
      }

      if (this.props.userInfo) {
        let date = moment(enterDate).format("YYYY-MM-DD");
        let type = abnormal[0];
        this.props.userInfo.idCard = CardNum;
        console.log("身份证号：", CardNum)
        let res1 = await post("/api/basis/editUserInfo", {
          userId: this.props.userInfo.userId,
          idCard: CardNum
        });
        if (res1.code === 200) {
          let res = await post("/api/user/addreserve", {
            userId: this.props.userInfo.userId,
            userName: userName,
            status: "0",
            date: date,
            period: periodValue[0],
            abnormalType: type,
            accompanyNum: accompanyNum,
            phone: phone,
          });
          if (res.code === 200) {
            Toast.success("预约成功", 1);
            this.setState({
              success: true,
            });
          } else {
            Toast.fail(res.message, 1);
            // Toast.success("预约失败", 1);
          }
        } else {
          Toast.success("预约失败", 1);
          return;
        }
      }
    }
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter<any, ComponentClass<IProps>>(Reservation));
