import { get,post } from "../../axios/axios";
import { ymd } from "../../components/ymd";

interface KF {
    [key: string]: Function
}
async function honor(id: number) {
    const requestData = await post('/api/honor/details', {gloryId: id});
    if(requestData) {
        return {
            id: requestData.data.glory.gloryId,
            title: requestData.data.glory.gloryTitle,
            content: requestData.data.glory.gloryContent,
            imgUrl: requestData.data.glory.gloryThumbnail,
            createTime: ymd(new Date(requestData.data.glory.createtime)),
            fileUrl: requestData.data.upload,
            up: requestData.data.up === ""? {}: {
            id: requestData.data.up.gloryId,
            title: requestData.data.up.gloryTitle,
            content: requestData.data.up.gloryContent,
            imgUrl: requestData.data.up.gloryThumbnail,
            createTime: ymd(new Date(requestData.data.up.createtime)),
            },
            down: requestData.data.down === ""? {}: {
            id: requestData.data.down.gloryId,
            title: requestData.data.down.gloryTitle,
            content: requestData.data.down.gloryContent,
            imgUrl: requestData.data.down.gloryThumbnail,
            createTime: ymd(new Date(requestData.data.down.createtime)),
            },
            more: requestData.data.more === ""? []: requestData.data.more.map((more: any) => {
                return {
                    id: more.gloryId,
                    title: more.gloryTitle,
                    content: more.gloryContent,
                    imgUrl: more.gloryThumbnail,
                    createTime: ymd(new Date(more.createtime)),
                }
            })
        }
    }
}
async function memorabilia(id: number) {
    const requestData = await post('/api/memorabilia/details', {memorabiliaId: id});
    if(requestData) {
        return {
            id: requestData.data.memorabilia.memorabiliaId,
            title: requestData.data.memorabilia.memorabiliaTitle,
            content: requestData.data.memorabilia.memorabiliaContent,
            imgUrl: requestData.data.memorabilia.memorabiliaThumbnail,
            createTime: ymd(new Date(requestData.data.memorabilia.createtime)),
            fileUrl: requestData.data.upload,
            up: requestData.data.up === ""? {}: {
            id: requestData.data.up.memorabiliaId,
            title: requestData.data.up.memorabiliaTitle,
            content: requestData.data.up.memorabiliaContent,
            imgUrl: requestData.data.up.memorabiliaThumbnail,
            createTime: ymd(new Date(requestData.data.up.createtime)),
            },
            down: requestData.data.down === ""? {}: {
            id: requestData.data.down.memorabiliaId,
            title: requestData.data.down.memorabiliaTitle,
            content: requestData.data.down.memorabiliaContent,
            imgUrl: requestData.data.down.memorabiliaThumbnail,
            createTime: ymd(new Date(requestData.data.down.createtime)),
            },
            more: requestData.data.more === ""? []: requestData.data.more.map((more: any) => {
                return {
                    id: more.memorabiliaId,
                    title: more.memorabiliaTitle,
                    content: more.memorabiliaContent,
                    imgUrl: more.memorabiliaThumbnail,
                    createTime: ymd(new Date(more.createtime)),
                }
            })
        }
    }
}
async function promo(id: number) {
    const requestData = await post('/api/promo/details', {promoId: id});
    if(requestData) {
        return {
            id: requestData.data.promo.promoId,
            title: requestData.data.promo.promoTitle,
            content: requestData.data.promo.promoContent,
            imgUrl: requestData.data.promo.promoThumbnail,
            fileUrl: requestData.data.upload,
            pathUrl: requestData.data.promo.pathUrl,
            createTime: ymd(new Date(requestData.data.promo.createtime)),
            up: requestData.data.up === ""? {}: {
            id: requestData.data.up.promoId,
            title: requestData.data.up.promoTitle,
            content: requestData.data.up.promoContent,
            imgUrl: requestData.data.up.promoThumbnail,
            pathUrl: requestData.data.up.pathUrl,
            createTime: ymd(new Date(requestData.data.up.createtime)),
            },
            down: requestData.data.down === ""? {}: {
            id: requestData.data.down.promoId,
            title: requestData.data.down.promoTitle,
            content: requestData.data.down.promoContent,
            imgUrl: requestData.data.down.promoThumbnail,
            pathUrl: requestData.data.down.pathUrl,
            createTime: ymd(new Date(requestData.data.down.createtime)),
            },
            more: requestData.data.more === ""? []: requestData.data.more.map((more: any) => {
                return {
                    id: more.promoId,
                    title: more.promoTitle,
                    content: more.promoContent,
                    imgUrl: more.promoThumbnail,
                    pathUrl: more.pathUrl,
                    createTime: ymd(new Date(more.createtime)),
                }
            })
        }
    }
}
async function plan(id: number) {
    const requestData = await post('/api/plan/details', {planId: id});
    if(requestData) {
        return {
            id: requestData.data.planning.planningId,
            title: requestData.data.planning.planningTitle,
            content: requestData.data.planning.planningContent,
            imgUrl: requestData.data.planning.planningThumbnail,
            createTime: ymd(new Date(requestData.data.planning.createtime)),
            fileUrl: requestData.data.upload,
            up: requestData.data.up === ""? {}: {
            id: requestData.data.up.planningId,
            title: requestData.data.up.planningTitle,
            content: requestData.data.up.planningContent,
            imgUrl: requestData.data.up.planningThumbnail,
            createTime: ymd(new Date(requestData.data.up.createtime)),
            },
            down: requestData.data.down === ""? {}: {
            id: requestData.data.down.planningId,
            title: requestData.data.down.planningTitle,
            content: requestData.data.down.planningContent,
            imgUrl: requestData.data.down.planningThumbnail,
            createTime: ymd(new Date(requestData.data.down.createtime)),
            },
            more: requestData.data.more === ""? []: requestData.data.more.map((more: any) => {
                return {
                    id: more.planningId,
                    title: more.planningTitle,
                    content: more.planningContent,
                    imgUrl: more.planningThumbnail,
                    createTime: ymd(new Date(more.createtime)),
                }
            })
        }
    }
}
async function plant(id: number) {
    const requestData = await post('/api/v1/getPlantInfoById', {id: id});
    if(requestData) {
        return {
            id: requestData.data.plant.id,
            title: requestData.data.plant.nameZh,
            content: `<p>${requestData.data.plant.distribution}</p><p>${requestData.data.plant.habit}</p><p>${requestData.data.plant.introduction}</p>`,
            imgUrl: requestData.data.plant.planningThumbnail,
            createTime: '',
            fileUrl: '',
            up: requestData.data.up === ""? {}: {
                id: requestData.data.up.id,
                title: requestData.data.up.nameZh,
                content: requestData.data.down.distribution,
                imgUrl: requestData.data.up.planningThumbnail,
                createTime: '',
                fileUrl: '',
            },
            down: requestData.data.down === ""? {}: {
                id: requestData.data.down.id,
                title: requestData.data.down.nameZh,
                content: requestData.data.down.distribution,
                imgUrl: requestData.data.down.planningThumbnail,
                createTime: '',
                fileUrl: '',
            },
            more: []
            // more: requestData.data.more === ""? []: requestData.data.more.map((more: any) => {
            //     return {
            //         id: requestData.more.id,
            //         title: requestData.more.nameZh,
            //         content: `<p>${requestData.more.distribution}</p><p>${requestData.more.habit}</p><p>${requestData.more.introduction}</p>`,
            //         imgUrl: requestData.more.planningThumbnail,
            //         createTime: '',
            //         fileUrl: '',
            //     }
            // })
        }
    }
}
const aboutMap: KF = {
    honor: honor,
    memorabilia: memorabilia,
    promo: promo,
    plan: plan,
    plant: plant
}
export {aboutMap};