import React, { ComponentClass } from "react";
import { RouterProps } from "react-router";
import * as echarts from "echarts/lib/echarts";
import "echarts/lib/chart/tree";
import { withRouter } from "react-router-dom";

interface IProps extends RouterProps {
  getData: Function;
}
interface OrganizationTree {
  id: number;
  name: string;
}
interface IState {
  path: string;
}
var questBlock = false;
const main = React.createRef<HTMLDivElement>();
class Organization extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      path: "",
    };
  }
  componentDidMount() {
    if (
      this.props.history.location.pathname !== this.state.path &&
      !questBlock
    ) {
      questBlock = true;
      this.setState(
        {
          path: this.props.history.location.pathname,
        },
        () => this.createMain()
      );
    }
  }
  render() {
    return (
      <div
        ref={main}
        style={{ width: "100%", height: "100%", float: "left" }}
      ></div>
    );
  }
  async createMain() {
    questBlock = false;
    var myChart = echarts.init(main.current as HTMLDivElement);
    const data = await this.props.getData();
    const option = {
      series: [
        {
          type: "tree",
          id: "0",
          name: "tree1",
          data: data,

          top: "10%",
          left: "8%",
          bottom: "22%",
          right: "20%",

          orient: "vertical",
          symbolSize: 0,

          edgeShape: "polyline",
          edgeForkPosition: "63%",
          initialTreeDepth: 3,

          lineStyle: {
            width: 1,
            Distinct: 50,
          },

          label: {
            backgroundColor: "#fff",
            position: "bottom",
            verticalAlign: "middle",
            align: "middle",
            borderType: "solid",
            borderColor: "#a3a3a3",
            borderWidth: 1,
            padding: 10,
          },

          leaves: {
            label: {
              position: "bottom",
              verticalAlign: "middle",
              align: "middle",
              borderType: "solid",
              borderColor: "#a3a3a3",
              borderWidth: 1,
              padding: 10,
            },
          },
        },
      ],
    };

    option && myChart.setOption(option);
  }
}

export default withRouter<any, ComponentClass<IProps, IState>>(Organization);
