import { Icon, ImagePicker, NavBar, TextareaItem, Toast, Button } from "antd-mobile";
import React, { ComponentClass } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { LoginInfo, UserInfo } from "../../store/type";
import "./index.css";
import { all, post, postFile, postQuest } from "../../axios/axios";
import { message } from "antd";
import { FROM } from "../../store/enmu";
import { url } from "inspector";
import { disConnect } from "echarts/lib/echarts";
interface IProps extends LoginInfo, RouteComponentProps { }
interface IState {
  files: any[];
  title: string;
  content: string;
  loading: boolean;
}

declare var window: {
  [key: string]: any; // missing index defintion
  prototype: Window;
  new(): Window;
};
const mapStateToProps = (
  state: LoginInfo
): {
  userInfo?: UserInfo;
  from: FROM | null;
  padding?: string;
  userToken: string;
} => ({
  userInfo: state.userInfo,
  from: state.from,
  padding: state.padding,
  userToken: state.userToken as string,
});
class Suggestion extends React.PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      files: [],
      title: "",
      content: "",
      loading: false,
    };
  }
  render() {
    const { files } = this.state;
    return (
      <div className="suggestion">
        <div
          className="orderHeader"
          style={
            this.props.padding
              ? { marginTop: JSON.parse(this.props.padding).top + "px" }
              : {}
          }
        >
          {this.props.from != FROM.WXMINI ? <NavBar
            className="navBar"
            icon={
              <Icon type="left" onClick={() => this.props.history.goBack()} />
            }
          >
            咨询建议
          </NavBar> : null}
        </div>

        <div className="main">
          <div className="formBox">
            <input
              type="text"
              placeholder="标题"
              onChange={(e) => {
                this.setState({
                  title: e.target.value,
                });
              }}
            />
            <TextareaItem
              rows={5}
              count={100}
              placeholder="写下咨询/建议内容"
              onChange={(e) => {
                if (e) {
                  this.setState({
                    content: e?.toString(),
                  });
                }
              }}
            />
            <div className="text">图片（选填，提供相关的图片）</div>
            <div className="selectImg">
              {this.props.from === FROM.APP ? (
                <div>
                  {files
                    ? files.map((item: any, key: number) => {
                      return (
                        <div className="imgDiv" key={key}>
                          <img src={item} alt="" />
                          <div
                            className="removeIcon"
                            onClick={this.removeIcon.bind(this, key)}
                          ></div>
                        </div>
                      );
                    })
                    : null}
                  <div
                    className="addImageDiv"
                    onClick={() => this.getImage()}
                  ></div>
                </div>
              ) : (
                <ImagePicker
                  style={{ width: "280px" }}
                  files={files}
                  onChange={this.onChange}
                  selectable={files.length < 10}
                />
              )}
            </div>
            <Button className="submitBtn" loading={this.state.loading} onClick={this.submit}>
              提交
            </Button>
          </div>
        </div>
      </div>
    );
  }
  onChange = (files: any, type: any, index: any) => {
    this.setState({
      files,
    });
  };
  changeFiles(url: string) {
    let fileArray = this.state.files;
    fileArray.push(url);
    this.setState({
      files: JSON.parse(JSON.stringify(fileArray)),
    });
  }

  getImage() {
    const that = this;
    window["appSetImagePath"] = function (url: string) {
      that.changeFiles(url);
    };
    if (this.props.from === FROM.APP) {
      if (window["jsGetImage"]) {
        // window["jsGetImage"].postMessage(this.props.userToken);
        window['jsGetImage'].postMessage(JSON.stringify({
          type: 0,
          token: this.props.userToken,
        }));
      }
    }
  }
  removeIcon = (e: any) => {
    let array = this.state.files;
    array.splice(e, 1);
    this.setState({
      files: JSON.parse(JSON.stringify(array)),
    });
  };
  submit = async () => {
    const { title, content } = this.state;
    if (!title) {
      Toast.fail("请输入标题", 1);
    } else if (!content) {
      Toast.fail("请输入内容", 1);
    } else {
      let arr = localStorage.getItem("userInfo");
      if (arr) {
        let userInfo = JSON.parse(arr);
        if (this.props.from === FROM.APP) {
          this.setState({ loading: true })
          let res = await post("/api/complaints/add", {
            userId: userInfo.userId,
            userName: userInfo.username,
            title: this.state.title,
            content: this.state.content,
            complaintTypeId: 2,
            screenshotImages: this.state.files.join(","),
            status: 0,
          });
          if (res.code === 200) {
            this.setState({ loading: false })
            Toast.info("提交成功");
            this.props.history.goBack();
          } else {
            this.setState({ loading: false })
            Toast.info("提交失败");
          }
        } else {
          this.setState({ loading: true })
          let url = await all(
            this.state.files.map((item: any) => {
              let subImg = new FormData();
              subImg.append("file", item.file);
              return postQuest("/api/basis/uploadPhoto", subImg);
            })
          );
          url = [...url];
          let pathUrlArr = url.join(",");
          let res = await post("/api/complaints/add", {
            userId: userInfo.userId,
            userName: userInfo.username,
            title: this.state.title,
            content: this.state.content,
            complaintTypeId: 2,
            screenshotImages: pathUrlArr,
            status: 0,
          });
          if (res.code === 200) {
            this.setState({ loading: false })
            Toast.info("提交成功");
            this.props.history.goBack();
          } else {
            this.setState({ loading: false })
            Toast.info("提交失败");
          }
        }
      }
    }
  };
}

export default connect(mapStateToProps)(
  withRouter<any, ComponentClass<IProps, IState>>(Suggestion)
);
