import React, { ComponentClass } from 'react';
import { all, get, getQuest, post } from '../../axios/axios';
import Entrance, { IEntranceProps } from '../../components/Entrance';
import Impression, { IImpressionProps } from '../../components/Impression';
import Notice, { INoticeProps } from '../../components/Notic';
import OpenInfo, { IOpenInfoProps } from '../../components/OpenInfo';
import Strategy, { IStrategyProps } from '../../components/Strategy';
import TopCarousel, { ITopCarouselProps } from '../../components/TopCarousel';
import style from './index.module.css'
import wx from 'weixin-js-sdk';
import { Toast } from 'antd-mobile';
import { message } from 'antd';
import { RouterProps, withRouter } from 'react-router-dom';
import { LoginInfo, UserInfo } from '../../store/type';
import { FROM, LOGINSTATE } from '../../store/enmu';
import { connect } from 'react-redux';

declare var window: {
    [key: string]: any; // missing index defintion
    prototype: Window;
    new(): Window;
};
interface IProps extends RouterProps, LoginInfo { }
interface IState {
    TopCarouselConfig: ITopCarouselProps
    NoticeConfig: INoticeProps
    EntranceConfig: IEntranceProps
    OpenInfoConfig: IOpenInfoProps
    impressionConfig: IImpressionProps
    strategyConfig: IStrategyProps
}
var StrategyRequestTime: number = 1;
const ref = React.createRef<HTMLDivElement>();
class Home extends React.PureComponent<IProps, IState> {
    constructor(props: IProps) {
        StrategyRequestTime = 1;
        super(props);
        this.state = {
            TopCarouselConfig: {
                imgList: []
            },
            NoticeConfig: {
                noticeList: []
            },
            EntranceConfig: {
                entranceList: []
            },
            OpenInfoConfig: {
                content: '',
                people: 0,
                weather: '-',
                temperature: '0',
                no: '0'
            },
            impressionConfig: {
                imgList: []
            },
            strategyConfig: {
                strategyList: []
            }
        }
        window['goOutUrl'] = function (url: string) {
            props.history.push('/OutURL/' + encodeURIComponent(url) + '/' + encodeURIComponent('返回'))
        }
        // const appProps = props
        // window['getUserInfo'] = props.userInfo?.phone;
        window['getUserInfo'] = () => {
            if (props.state === LOGINSTATE.SIGNIN) {
                return props.userInfo?.phone;
            } else {
                props.history.push('/main/mine');
                return null
            }
        }
        this.getWeather();
        this.getCoursel();
        this.getNotice();
        this.getImpression();
        this.getStrategy();
        this.getEntrance();
    }
    async getCoursel() {
        let data = await post('/api/giantscreen/list', {
            dispalyStatus: 1,
            reviewStatus: 3
        });
        if (data && data.data !== null) {
            this.setState({
                TopCarouselConfig: {
                    imgList: data.data
                }
            })
        }
    }
    async getNotice() {
        let data = await post('/api/announcement/list', {
            reviewStatus: 3
        });
        if (data && data.data) {
            this.setState({
                NoticeConfig: {
                    noticeList: data.data
                }
            })
        }
    }
    async getImpression() {
        let impression = await post('/api/impression/list', { reviewStatus: 3 });
        if (impression.data && impression.data !== null) {
            this.setState({
                impressionConfig: {
                    imgList: impression.data
                },
            })
        }
    }
    async getWeather() {
        // 还需拉取人数接口 和负氧离子 
        // let [ weather, ticket, getTemAndNum ] = await all([
        //     getQuest('/api/basis/getWeatherInfo'),
        //     getQuest('/api/v1/getParkPeople'),
        //     getQuest('/api/v1/getTemAndNum')
        // ]);
        // var nowElement: {no: string} = {
        //     no: '0'
        // }
        // for(let key in getTemAndNum.data.data) {
        //     if(Number(getTemAndNum.data.data[key].no) > Number(nowElement.no)) {
        //         nowElement = getTemAndNum.data.data[key]
        //     }
        // }
        // this.setState({
        //     OpenInfoConfig: {
        //         content: '',
        //         no: nowElement.no,
        //         people: ticket.data.data.inNum,
        //         weather: JSON.parse(weather.data).weather,
        //         temperature: JSON.parse(weather.data).temp
        //     }
        // });
        this.getWeatherInfo();
        this.getTemAndNum();
        this.getParkPeople();
    }
    async getWeatherInfo() {
        let weather = await get('/api/basis/getWeatherInfo');
        try {
            if (weather.code === 200) {
                this.setState({
                    OpenInfoConfig: {
                        ...this.state.OpenInfoConfig,
                        content: '',
                        weather: JSON.parse(weather.data).weather,
                        // temperature: JSON.parse(weather.data).temp,
                    }
                }, () => {
                });
            }
        } catch (e) {
            if (weather.code === 200) {
                this.setState({
                    OpenInfoConfig: {
                        ...this.state.OpenInfoConfig,
                        content: '',
                        weather: JSON.parse(weather.data.split('}{')).weather,
                        // temperature: JSON.parse(weather.data.split('}{')).temp,
                    }
                }, () => {
                });
            }
        }
    }
    async getTemAndNum() {
        var nowElement: { no: string, tem: string } = {
            no: '0',
            tem: '0'
        }
        let getTemAndNum = await get('/api/v1/getTemAndNum');
        if (getTemAndNum.code === 200 && getTemAndNum.data !== null) {
            for (let key in getTemAndNum.data.data) {
                if (Number(getTemAndNum.data.data[key].no) > Number(nowElement.no)) {
                    nowElement = getTemAndNum.data.data[key]
                }
            }
            console.log(nowElement)
            if (getTemAndNum.code === 200) {
                this.setState({
                    OpenInfoConfig: {
                        ...this.state.OpenInfoConfig,
                        content: '',
                        no: nowElement.no,
                        temperature: nowElement.tem
                    }
                });
            }
        }
    }
    async getParkPeople() {
        let ticket = await get('/api/v1/getParkPeople');
        if (ticket.code === 200 && ticket.data !== null) {
            this.setState({
                OpenInfoConfig: {
                    ...this.state.OpenInfoConfig,
                    content: '',
                    people: ticket.data.data.inNum,
                }
            });
        }
    }
    async getStrategy() {
        let result = await get('/api/power/list', {
            pageNum: StrategyRequestTime,
            pageSize: 10,
            userId: 0,
            menuId: 0,
            reviewStatus: 3
        })
        if (result.code === 200) {
            this.setState({
                strategyConfig: {
                    strategyList: [...this.state.strategyConfig.strategyList, ...result.data]
                }
            })
            StrategyRequestTime++;
        }
    }
    async getEntrance() {
        let result = await post('/api/basis/searchQuickEntry', { pageNum: 1, pageSize: 0 });
        if (result.code === 200) {
            let entrance = result.data.map((res: {
                entryIcon: string,
                entryLink: string,
                entryName: string,
                delFlag: string
            }) => ({
                icon: res.entryIcon,
                title: res.entryName,
                url: res.entryLink,
                delFlag: res.delFlag
            }))
            this.setState({
                EntranceConfig: {
                    entranceList: entrance
                },
            })
        }
    }
    touchMove() {
        if (ref.current && ref.current.clientHeight + ref.current?.scrollTop - ref.current?.scrollHeight >= -2) {
            this.getStrategy();
        }
    }
    render() {
        const { TopCarouselConfig, NoticeConfig, EntranceConfig, OpenInfoConfig, impressionConfig, strategyConfig } = this.state;
        return (
            <div className={style.Home} ref={ref} onTouchEnd={() => this.touchMove()}>
                <div className={style.HomeContent}>
                    <div className={style.TopCarousel}>
                        <TopCarousel {...{ ...OpenInfoConfig, ...TopCarouselConfig }} />
                    </div>
                    <div className={style.notice}>
                        <Notice {...NoticeConfig} />
                    </div>
                    <div className={style.entrance}>
                        <Entrance {...EntranceConfig} />
                    </div>
                    <div className={style.Impression}>
                        <Impression {...impressionConfig} />
                    </div>
                    <div className={style.Strategy}>
                        <Strategy {...strategyConfig} />
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (
    state: LoginInfo
): {
    userInfo?: UserInfo;
    from: FROM | null;
    padding?: string;
    state: LOGINSTATE
} => ({
    userInfo: state.userInfo,
    from: state.from,
    padding: state.padding,
    state: state.state
});
export default connect(mapStateToProps)((withRouter<any, ComponentClass<IProps>>(Home)));