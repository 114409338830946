import { GETPADDING, LOGIN, SETFROM, SETTOP, SIGNOUT } from "../constants";
import { FROM } from "../enmu";
import { UserInfo } from "../type";

export interface ILOGIN {
    type: LOGIN,
    userInfo: UserInfo
    userToken: string
}
export interface ISIGNOUT {
    type: SIGNOUT;
}
 
export interface IGETPADDING {
    type: GETPADDING;
    padding: string;
}
export interface ISETFROM {
    type: SETFROM;
    from: FROM
}
 
export interface ISETTOP {
    type: SETTOP;
    top: string
}
// 定义 modifyAction 类型，包含 IINCREMENTAction 和 IDECREMENTAction 接口类型
export type ModifyAction = ILOGIN | ISIGNOUT | IGETPADDING | ISETFROM | ISETTOP;
 
 
// 登录
export const login = (userInfo: UserInfo, userToken: string): ILOGIN => ({
    type: LOGIN,
    userInfo: userInfo,
    userToken: userToken
})
 
// 注销
export const signout = (): ISIGNOUT => ({
    type: SIGNOUT
})

export const getPadding = (padding: string): IGETPADDING => ({
    type: GETPADDING,
    padding: padding
})

export const setFrom = (from: FROM): ISETFROM => ({
    type: SETFROM,
    from: from
})


export const setTop = (top: string): ISETTOP => ({
    type: SETTOP,
    top: top
})