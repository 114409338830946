import { get,post } from "../../axios/axios";
import { ymd } from "../../components/ymd";

interface KF {
    [key: string]: Function
}

async function notice(id: number) {
    const requestData = await post('/api/notice/details', {noticeId: id});
    if(requestData) {
        return {
            id: requestData.data.notice.noticeId,
            title: requestData.data.notice.noticeTitle,
            content: requestData.data.notice.noticeContent,
            imgUrl: requestData.data.notice.noticeThumbnail,
            createTime: ymd(new Date(requestData.data.notice.createtime)),
            fileUrl: requestData.data.upload,
            up: requestData.data.up === ""? {}: {
            id: requestData.data.up.noticeId,
            title: requestData.data.up.noticeTitle,
            content: requestData.data.up.noticeContent,
            imgUrl: requestData.data.up.noticeThumbnail,
            createTime: ymd(new Date(requestData.data.up.createtime)),
            },
            down: requestData.data.down === ""? {}: {
            id: requestData.data.down.noticeId,
            title: requestData.data.down.noticeTitle,
            content: requestData.data.down.noticeContent,
            imgUrl: requestData.data.down.noticeThumbnail,
            createTime: ymd(new Date(requestData.data.down.createtime)),
            },
            more: requestData.data.more === ""? []: requestData.data.more.map((more: any) => {
                return {
                    id: more.noticeId,
                    title: more.noticeTitle,
                    content: more.noticeContent,
                    imgUrl: more.noticeThumbnail,
                    createTime: ymd(new Date(more.createtime)),
                }
            })
        }
    }
}
async function line(id: number) {
    const requestData = await post('/api/line/details', {lineId: id});
    if(requestData) {
        return {
            id: requestData.data.line.lineId,
            title: requestData.data.line.lineTitle,
            content: requestData.data.line.lineContent,
            imgUrl: requestData.data.line.lineThumbnail,
            createTime: ymd(new Date(requestData.data.line.createtime)),
            fileUrl: requestData.data.upload,
            up: requestData.data.up === ""? {}: {
            id: requestData.data.up.lineId,
            title: requestData.data.up.lineTitle,
            content: requestData.data.up.lineContent,
            imgUrl: requestData.data.up.lineThumbnail,
            createTime: ymd(new Date(requestData.data.up.createtime)),
            },
            down: requestData.data.down === ""? {}: {
            id: requestData.data.down.lineId,
            title: requestData.data.down.lineTitle,
            content: requestData.data.down.lineContent,
            imgUrl: requestData.data.down.lineThumbnail,
            createTime: ymd(new Date(requestData.data.down.createtime)),
            },
            more: requestData.data.more === ""? []: requestData.data.more.map((more: any) => {
                return {
                    id: more.lineId,
                    title: more.lineTitle,
                    content: more.lineContent,
                    imgUrl: more.lineThumbnail,
                    createTime: ymd(new Date(more.createtime)),
                }
            })
        }
    }
}
async function trafficservice(id: number) {
    const requestData = await post('/api/trafficservice/details', {trafficId: id});
    if(requestData) {
        return {
            id: requestData.data.line.trafficId,
            title: requestData.data.line.trafficTitle,
            content: requestData.data.line.trafficContent,
            imgUrl: requestData.data.line.trafficThumbnail,
            createTime: ymd(new Date(requestData.data.line.createtime)),
            fileUrl: requestData.data.upload,
            up: requestData.data.up === ""? {}: {
                id: requestData.data.up.trafficId,
                title: requestData.data.up.trafficTitle,
                content: requestData.data.up.trafficContent,
                imgUrl: requestData.data.up.trafficThumbnail,
                createTime: ymd(new Date(requestData.data.up.createtime)),
            },
                down: requestData.data.down === ""? {}: {
                id: requestData.data.down.trafficId,
                title: requestData.data.down.trafficTitle,
                content: requestData.data.down.trafficContent,
                imgUrl: requestData.data.down.trafficThumbnail,
                createTime: ymd(new Date(requestData.data.down.createtime)),
            },
            more: requestData.data.more === ""? []: requestData.data.more.map((more: any) => {
                return {
                    id: more.trafficId,
                    title: more.trafficTitle,
                    content: more.trafficContent,
                    imgUrl: more.trafficThumbnail,
                    createTime: ymd(new Date(more.createtime)),
                }
            })
        }
    }
}
async function commonproblem(id: number) {
    const requestData = await post('/api/commonproblem/details', {problemId: id});
    if(requestData) {
        return {
            id: requestData.data.problrm.problemId,
            title: requestData.data.problrm.problemTitle,
            content: requestData.data.problrm.problemContent,
            imgUrl: requestData.data.problrm.problemThumbnail,
            createTime: ymd(new Date(requestData.data.problrm.createtime)),
            fileUrl: requestData.data.upload,
            up: requestData.data.up === ""? {}: {
            id: requestData.data.up.problrm,
            title: requestData.data.up.problemTitle,
            content: requestData.data.up.problemContent,
            imgUrl: requestData.data.up.problemThumbnail,
            createTime: ymd(new Date(requestData.data.up.createtime)),
            },
            down: requestData.data.down === ""? {}: {
            id: requestData.data.down.problemId,
            title: requestData.data.down.problemTitle,
            content: requestData.data.down.problemContent,
            imgUrl: requestData.data.down.problemThumbnail,
            createTime: ymd(new Date(requestData.data.down.createtime)),
            },
            more: requestData.data.more === ""? []: requestData.data.more.map((more: any) => {
                return {
                    id: more.problemId,
                    title: more.problemTitle,
                    content: more.problemContent,
                    imgUrl: more.problemThumbnail,
                    createTime: ymd(new Date(more.createtime)),
                }
            })
        }
    }
}
const touristNoticeMap: KF = {
    notice: notice,
    line: line,
    trafficservice: trafficservice,
    commonproblem: commonproblem
}
export {touristNoticeMap};