import { title } from 'process';
import React, { ComponentClass } from 'react';
import { RouterProps, withRouter } from 'react-router-dom';
import { titleList } from '../../pages/About';
import { titleInfo } from '../OptionTitle';
import style from './index.module.css'

interface card {
    imgUrl: string,
    title: string,
    time: string,
    content: string,
}
export interface ICardListProps {
    cardList: card[]
}
interface IProps extends RouterProps {
    cardList: card[]
}
class CardList extends React.Component<IProps> {
    render() {
        return (
            <div className={style.cardList}>
                {this.props.cardList.map((card, index) => (
                    <div className={style.card} key={index} onClick={() => {
                        let name = titleList.find((title:titleInfo) => (this.props.history.location.pathname.split('/')[2] === title.path))?.title;
                        localStorage.setItem('title', name? name: '');
                        localStorage.setItem('content', JSON.stringify(card))
                    }}>
                        <div className={style.imgBox} style={{background: 'url("' + card.imgUrl + '") no-repeat', backgroundSize: 'auto 100%', backgroundPosition: 'center'}}>
                        </div>
                        <div className={style.contentBox}>
                            <div className={style.title}>{card.title}</div>
                            <div className={style.time}>{card.time}</div>
                            <div className={style.content}>
                                {/* 取出文本编辑器中的全部文字,如果没有显示title */}
                                {card.content? card.content.replace('\\', '').split('>').map(text => (text.substr(0, 1) === "<"? null: text.split('<')[0])) === null?card.title: card.content.replace('\\', '').split('>').map(text => (text.substr(0, 1) === "<"?null: text.split('<')[0])):card.title}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        )
    }
}

export default withRouter<any, ComponentClass<IProps>>(CardList);