import { ModifyAction } from '../actions';
import { GETPADDING, LOGIN, SETFROM, SETTOP, SIGNOUT } from '../constants';
import { FROM, LOGINSTATE } from '../enmu';
import { LoginInfo } from '../type';

var signoutTimeouter: any;
export var loginInfo: LoginInfo = {
  top: '0',
  appid: 'wx13bdbd82f451118a',
  from: localStorage.getItem('from') as FROM,
  state: localStorage.getItem('loginState') ? localStorage.getItem('loginState') as LOGINSTATE : LOGINSTATE.SIGNOUT,
  userInfo: localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo') as string) : {},
  userToken: localStorage.getItem('userToken') ? (localStorage.getItem('userToken') as string) : '',
  padding: localStorage.getItem('padding') ? (localStorage.getItem('padding') as string) : '',
};
// 处理并返回 state
export default (state = loginInfo, action: ModifyAction): LoginInfo => {
  switch (action.type) {
    case LOGIN:
      // 登录方法
      action.userInfo.password && delete action.userInfo.password;
      localStorage.setItem("userInfo", JSON.stringify(action.userInfo));
      localStorage.setItem("loginState", LOGINSTATE.SIGNIN);
      state = {
        ...state,
        state: LOGINSTATE.SIGNIN,
        userInfo: action.userInfo,
        userToken: action.userToken,
      };
      // 清除登陆状态计时器
      clearTimeout(signoutTimeouter);
      // 计时器 30分钟后退出登录
      signoutTimeouter = setTimeout(() => {
        state = {
          ...state,
          state: LOGINSTATE.SIGNOUT,
          userInfo: {},
          userToken: "",
        };
      }, 30 * 60 * 1000);
      return state;
    case SIGNOUT:
      localStorage.removeItem("userInfo");
      localStorage.removeItem("userToken");
      localStorage.setItem("loginState", LOGINSTATE.SIGNOUT);
      state = {
        ...state,
        state: LOGINSTATE.SIGNOUT,
        userInfo: {},
        userToken: "",
      };
      return state;
    case GETPADDING:
      state = {
        ...state,
        padding: action.padding,
      };
      localStorage.setItem('padding', action.padding);
      return state;
    case SETFROM:
      localStorage.setItem('from', action.from);
      state = {
        ...state,
        from: action.from
      };
      return state;
    case SETTOP: 
      state = {
        ...state,
        top: action.top
      };
      return state;
    default:
      return state;
  }
};
