import React from "react";
import { Component, ComponentClass } from "react";
import { RouterProps, withRouter } from "react-router-dom";
import ContentPage from "../../components/ContentPage";
import style from './index.module.css';

interface IProps{
    getData: Function,
    goItem?: Function
}
interface IState{
  dataList: content[],
  page: number,
  total: string,
  pageSize: number,
  // path: string
}
interface content {
    id: number,
    title: string,
    content: string,
    imgUrl: string,
    createTime: string
}
export interface ListData{
    total: number,
    dataList: content[]
} 
var questBlock = false;

class ContentList extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
          dataList: [],
          page:1,
          total:'0',
          pageSize:10,
          // path: props.history.location.pathname
        };
        this.getData(10,1);
    }
    async getData(pageSize: number, page: number) {
        if(!questBlock){
          questBlock = true;
          let data = await this.props.getData(pageSize, page);
          if(data.dataList) {
            this.setState({
              dataList: [...this.state.dataList,...data.dataList],
              page: this.state.page + 1
            }, ()=> {
              if(data.dataList.length !== 0)
                questBlock = false
            })
          }
        }
    }
  componentWillUpdate() {
    // if(this.props.history.location.pathname !== this.state.path && !questBlock){
    //   questBlock = true;
    //   this.setState({
    //     path: this.props.history.location.pathname
    //   },() => this.getData(10,1));
    // }
  }
  onChange=(p: number)=>{
    this.setState({
      page: p,
    },() => this.getData(this.state.pageSize, p));
  }
  onShowSizeChange=()=>{
    this.getData(this.state.pageSize, this.state.page)
  }
  goDetail(item: content){
      localStorage.setItem("infomarion", JSON.stringify(item));
      // localStorage.setItem("title", "园博新闻");
      // localStorage.setItem("infomarions", JSON.stringify(dataList));
      // this.props.history.push("/contentPage");
  }
  refresh(){
    questBlock = false;
    this.setState({
      page: 1,
      dataList: []
    }, () => this.getData(10,1))
  }
    render() {
        const { dataList,page,total,pageSize, } = this.state
        const that=this
          return (
            <div className={style.contentList} >
                <div className={style.newsWrap} >
                {dataList.map((item, index) =>{
                    return (
                        <div className={style.itemContainer} onClick={()=> {if(this.props.goItem) {this.props.goItem(item.id)}}} key={index}>
                            <img className={style.itemImg} src={item.imgUrl}/>
                            <div className={style.itemTitle}>{item.title}</div>
                            <div className={style.itemCreatTime}>{item.createTime}</div>
                            {/* 取出文本编辑器中的全部文字,如果没有显示title */}
                            <div className={style.itemContent}>{item.content? item.content.replace('\\', '').split('>').map(text => (text.substr(0, 1) === "<"? null: text.split('<')[0])) === null? item.title: item.content.replace('\\', '').split('>').map(text => (text.substr(0, 1) === "<"? null: text.split('<')[0])): item.title}</div>
                        </div>
                    )
                })}
                </div>
            </div>
          )
      }
}

export default ContentList;