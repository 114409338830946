import { useEffect, useState } from "react";

const useMounted = (callBack: Function) => {
    const [renderTime, setRenderTime] = useState(0);
    useEffect(() => {
        callBack();
        if(renderTime < 1) {
            setRenderTime(1);
        }
    }, [renderTime])
}
export default useMounted;