import React, { ComponentClass } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import { Dispatch } from 'redux';
import { get } from '../../axios/axios';
import { login, signout } from '../../store/actions';
import { FROM } from '../../store/enmu';
import { LoginInfo, UserInfo } from '../../store/type';

interface IProps extends RouteComponentProps, LoginInfo {
  onSignout: () => {};
  onLogin: (userinfo: UserInfo, token: string) => {};
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onSignout: () => dispatch(signout()),
  onLogin: (userinfo: UserInfo, token: string) => dispatch(login(userinfo, token)),
});
const mapStateToProps = (
  state: LoginInfo
): {
  userInfo?: UserInfo;
  from: FROM | null;
  padding?: string;
  userToken: string;
} => ({
  userInfo: state.userInfo,
  from: state.from,
  padding: state.padding,
  userToken: state.userToken as string,
});
class WxminiOpen extends React.Component<IProps> {
    constructor(props: IProps) {
        super(props);
        this.getUserInfo();
    }
    async getUserInfo() {
        let param = this.props.match.params as { token: string, openUrl: string };
        localStorage.setItem("userToken", param.token ? param.token : "");
        let userInfo = await get("/api/user/getUserInfoByToken");
        if (userInfo) {
          this.props.onLogin(userInfo.data, param.token ? param.token : "");
          this.props.history.replace(decodeURIComponent(param.openUrl));
        }
    }
    render() {
        return (<div></div>);
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(withRouter<any, ComponentClass<IProps>>(WxminiOpen));