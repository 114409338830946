import Carousel from 'antd-mobile/lib/carousel';
import style from './index.module.css';
import './index.css';
import React, { ComponentClass, FunctionComponent, SyntheticEvent, useCallback, useState } from 'react';
import { RouterProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import SvgLog from '../SvgLog';
import { FROM } from '../../store/enmu';
import { LoginInfo, UserInfo } from '../../store/type';
import { connect } from 'react-redux';
import title from '../../asset/TopCarouse/text.png';
import searchLogo from '../../asset/TopCarouse/searchLogo.png';
import scanLogo from '../../asset/TopCarouse/scanLogo.png';
import { IOpenInfoProps } from '../OpenInfo';

declare var window: {
    [key: string]: any; // missing index defintion
    prototype: Window;
    new(): Window;
};
export interface CarouselImg {
    carouselId?: number,
    createtime?: Date,
    delFlag?: number | null,
    introduction?: string | null,
    isDisplay?: number | null,
    link?: string | null,
    pictureurl?: string | null,
    sort?: number | null,
    title?: string | null
    updatetime?: Date | null
}
export interface ITopCarouselProps {
    imgList: CarouselImg[]
}
interface IProps extends RouterProps, IOpenInfoProps, LoginInfo {
    imgList: CarouselImg[]
}

function TopCarousel(props: IProps) {
    const [imgType, setImgType] = useState<Array<{
        width: number,
        height: number,
        h_w: boolean
    }>>([]);
    const jump = useCallback((url?: string | null) => {
        if (url && url !== '#' && url !== null) {
            props.history.push(url as string)
        }
    }, [])
    const scan = useCallback(() => {
        window['startScan'].postMessage("");
    }, [])
    const { imgList, temperature, weather, no, people } = props;
    return (
        <div className={style.TopCarousel}>
            <div className={style.CarouselBar} style={{ top: props.padding ? JSON.parse(props.padding).top + "px" : '0' }}>
                <img src={title} alt="" />
                
                    <div className={style.scanBar} onClick={() => scan()}>
                        <img src={scanLogo} alt="" />
                        {/* <SvgLog name='scan' width="100%" height="100%" color='#fff' viewBox='0 0 30 30' /> */}
                    </div>
                   
                <div className={style.selectBar} onClick={() => { props.history.push('/searchPage') }}>
                    {/* <SvgLog name='select' width="100%" height="100%" color='#fff' viewBox='0 0 30 30' /> */}
                    <img src={searchLogo} alt="" />
                    </div>
            </div>
            <div className={style.blur}>
            </div>
            <div className={style.weather}>
                <div className={style.weatherBox}>
                    <div className={style.weatherTop}>
                        <span className={style.weatherNum}>{temperature}</span>
                        <span className={style.weatherEnum}>℃</span>
                    </div>
                    <div className={style.weatherBottom}>
                        {weather}
                    </div>
                </div>
                {/* <div className={style.weatherBox}>
                    <div className={style.weatherTop}>
                        <span className={style.weatherNum}>{people}</span>
                        <span className={style.weatherEnum}>人</span>
                    </div>
                    <div className={style.weatherBottom}>
                        实时在园人数
                    </div>
                </div> */}
                <div className={style.weatherBox}>
                    <div className={style.weatherTop}>
                        <span className={style.weatherNumLast}>{no}</span>
                    </div>
                    <div className={style.weatherBottom}>
                        负氧离子
                    </div>
                </div>
            </div>
            {imgList.length > 0 && <Carousel autoplay infinite dotStyle={{ display: 'none' }} dotActiveStyle={{ display: 'none' }}>
                {imgList.map((val: CarouselImg, index: number) => {
                    return (
                        <div key={index} className={style.imgBox}>
                            <img src={val.pictureurl ? val.pictureurl : ''}
                                onClick={() => { jump(val.link) }}
                                style={imgType[index] !== undefined ?
                                    (imgType[index] && imgType[index].h_w ?
                                        { marginTop: `-${imgType[index].height / imgType[index].width * 50 - 38.5}vw` } :
                                        { marginLeft: `-${imgType[index].width / imgType[index].height * 38.5 - 50}vw` })
                                    : {}}
                                width={imgType[index] && imgType[index].h_w ? '100%' : 'auto'}
                                height={imgType[index] && imgType[index].h_w ? 'auto' : '100%'}
                                onLoad={(e: SyntheticEvent<HTMLImageElement>) => {
                                    let IMGwh = imgType.concat();
                                    if (e.currentTarget.clientHeight / e.currentTarget.clientWidth > (77 / 100)) {
                                        IMGwh[index] = {
                                            h_w: true,
                                            width: e.currentTarget.clientWidth,
                                            height: e.currentTarget.clientHeight
                                        };
                                    } else {
                                        IMGwh[index] = {
                                            h_w: false,
                                            width: e.currentTarget.clientWidth,
                                            height: e.currentTarget.clientHeight
                                        };
                                    }
                                    setImgType(IMGwh);
                                }}
                                className={style.img} />
                        </div>
                    );
                })}
            </Carousel>}
        </div>
    );
}

const mapStateToProps = (
    state: LoginInfo
): {
    from: FROM | null;
    padding?: string;
} => ({
    from: state.from,
    padding: state.padding
});
export default connect(mapStateToProps)(withRouter<any, FunctionComponent<IProps>>(TopCarousel));