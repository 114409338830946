import React from 'react';
import CardList, { ICardListProps } from '../../components/CardList';
import style from './index.module.css'

interface IProps {}
interface IState {
    cardListConfig: ICardListProps
}
class News extends React.Component<IProps, IState>{
    constructor(props: IProps) {
        super(props);
        this.state = {
            cardListConfig: {
                cardList: [{
                    imgUrl: 'https://zos.alipayobjects.com/rmsportal/TekJlZRVCjLFexlOCuWn.png',
                    title: 'prprprprprprprprprprprprprprprprprprprprpr',
                    time: '2015-15-10',
                    content: '\<img src=\"https://zos.alipayobjects.com/rmsportal/TekJlZRVCjLFexlOCuWn.png\"\/\>\<p\>\<p\>prprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprpr\<\/p\>\</p\>',
                }, {
                    imgUrl: 'https://zos.alipayobjects.com/rmsportal/TekJlZRVCjLFexlOCuWn.png',
                    title: 'prprprprprprprprprprprprprprprprprprprprpr',
                    time: '2015-15-10',
                    content: '\<img src=\"https://zos.alipayobjects.com/rmsportal/TekJlZRVCjLFexlOCuWn.png\"\/\>\<p\>\<p\>prprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprpr\<\/p\>\</p\>',
                }, {
                    imgUrl: 'https://zos.alipayobjects.com/rmsportal/TekJlZRVCjLFexlOCuWn.png',
                    title: 'prprprprprprprprprprprprprprprprprprprprpr',
                    time: '2015-15-10',
                    content: '\<img src=\"https://zos.alipayobjects.com/rmsportal/TekJlZRVCjLFexlOCuWn.png\"\/\>\<p\>\<p\>prprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprpr\<\/p\>\</p\>',
                }, {
                    imgUrl: 'https://zos.alipayobjects.com/rmsportal/TekJlZRVCjLFexlOCuWn.png',
                    title: 'prprprprprprprprprprprprprprprprprprprprpr',
                    time: '2015-15-10',
                    content: '\<img src=\"https://zos.alipayobjects.com/rmsportal/TekJlZRVCjLFexlOCuWn.png\"\/\>\<p\>\<p\>prprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprpr\<\/p\>\</p\>',
                }, {
                    imgUrl: 'https://zos.alipayobjects.com/rmsportal/TekJlZRVCjLFexlOCuWn.png',
                    title: 'prprprprprprprprprprprprprprprprprprprprpr',
                    time: '2015-15-10',
                    content: '\<img src=\"https://zos.alipayobjects.com/rmsportal/TekJlZRVCjLFexlOCuWn.png\"\/\>\<p\>\<p\>prprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprprpr\<\/p\>\</p\>',
                }]
            }
        }
        
    }
    render() {
        const { cardListConfig } = this.state;
        return (
            <div className={style.News}>
                <CardList  {...cardListConfig}/>
            </div>
        )
    }
}

export default News;