import React from "react";
import style from "./index.module.css";

interface IProps {
    position?: [string, string],
    left?: string,
    className?:string,
    text?: string
}

interface IState {
    isShow: Boolean,
    position: [string, string],
    left: string,
    className: string[],
    text: string
}

class OptionToast extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            position: props.position? props.position: ['0', '0'],
            left: props.left? props.left: '0',
            className: props.className? [props.className, style.text]: [style.text],
            text: props.text? props.text: '',
            isShow: false
        }
    }
    show(text: string, time: number, position?: [string, string], className?: string, left?: string) {
        this.setState({
            text: text,
            isShow: true,
            position: position? position: ['0', '0'],
            className: className? [className, style.text]: [style.text],
            left: left? left: '0'
        })
        const that = this;
        setTimeout(() => {
            this.setState({
                isShow: false
            });
        }, time);
    }
    render() {
        const { isShow, position, left, className, text } = this.state;
        if(isShow) {
            return (
                <div className={style.optionToaseBox}>
                    <div className={style.OptionToast} style={{left: position[0], top: position[1]}}>
                        <div className={className.join(' ')}>{text}</div>
                        <div className={style.option} style={{marginLeft: left}}><svg style={{float: 'left'}} xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 7.794 5.087"><path d="M21.782,37.124a1.964,1.964,0,0,1-1.732-1.053l-2.211-4.034h7.794l-2.1,4A1.962,1.962,0,0,1,21.782,37.124Z" transform="translate(-17.839 -32.037)" fill="#3a3a3a"/></svg></div>
                    </div>
                </div>
            )
        } else {
            return null;
        }
    }
}

export default OptionToast;