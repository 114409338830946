import React from "react";
import { get } from "../../axios/axios";

interface IProps {

}

function Download(props: IProps) {
    let ua = navigator.userAgent.toLowerCase();
    if(ua.indexOf('micromessenger') !== -1) {
        //wx
        alert("请点击右上角按钮, 选择使用浏览器打开")
        return <div>请点击右上角按钮, 选择使用浏览器打开</div>;
    } else if(ua.indexOf('iphone') !== -1 || ua.indexOf('mac os') !== -1) {
        window.location.href = 'https://apps.apple.com/cn/app/id1582916991'
    } else {
        getNewAppUrl().then((result) => {
            window.location.href = result.data[0].androidPath
        }).catch((e) => {
            alert(e)
        });
    }
    return <></>;
}

async function getNewAppUrl (){
    let url = await get('/api/version/getAllByType', {
        type: 1
    });
    return url
}

export default Download;