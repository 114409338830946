import { Button, Icon, NavBar, Toast } from "antd-mobile";
import React, { ChangeEvent, ChangeEventHandler, ComponentClass } from "react";
import { connect } from "react-redux";
import { RouterProps, withRouter } from "react-router";
import { get,post } from "../../axios/axios";
import { FROM } from "../../store/enmu";
import { LoginInfo, UserInfo } from "../../store/type";
import style from "./index.module.css";
import list from "../../asset/amountsExchange/list.png"
import cantExchange from "../../asset/amountsExchange/cantExchange.png"
import { Input } from "antd";
import { MinusSquareOutlined, PlusOutlined, PlusSquareOutlined } from "@ant-design/icons";

interface IProps extends RouterProps, LoginInfo{}

interface amountsRule {
    aprc: string,
    base: string,
    bdate: string,
    bndId: string,
    branId: number,
    cateId: number,
    couamt: string,
    coupid: number,
    depotid: number,
    edate: string,
    imgurl: string,
    isValid: string,
    lastdate: string,
    limitnum: number,
    pluCode: string,
    pluDsc: string,
    pluId: number,
    pluName: string,
    plutype: string,
    qty: string,
    rdid: string,
    rdname: string,
    remain: number,
    rmk: string,
    sno: number,
    sourse: string,
    spec: string,
    tamt: string,
    total: string,
    unit: string,
    checkqty?: number
}

interface IState {
    amountsList: amountsRule[], // 可兑换列表
    noAmountsList: amountsRule[] // 不可兑换列表
}

class ExchangeAmounts extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            amountsList: [],
            noAmountsList: []
        }
        this.getData();
    }
    async getData() {
        // 初始化数据
        let res = await post('/api/user/exGiftRule', {
            userId: this.props.userInfo?.userId
        });
        if(res.code === 200) {
            var amountsList: amountsRule[] = [];
            var noAmountsList: amountsRule[] = [];
            // 数据判空 ruleList 可能为null
            if(res.data.ruleList !== null) {
                res.data.ruleList.forEach((e: amountsRule) => {
                    e.checkqty = 0;
                    if(e.remain > 0) {
                        amountsList.push(e);
                    } else {
                        noAmountsList.push(e);   
                    }
                })
            }
            this.setState({
                amountsList: amountsList,
                noAmountsList: noAmountsList
            })
        } else {
        }
    }
    async exchange(rule: amountsRule) {
        // 立即兑换按钮
        Toast.loading('兑换中',0)
        let res = await post('/api/user/giftExchangeApi', {
            rdid: rule.rdid,
            pluId: rule.pluId,
            mobileNo: this.props.userInfo?.phone,
            qty: rule.checkqty
        })
        Toast.hide();
        if(res.code === 200) {
            Toast.show('兑换成功');
        }
    }
    render() {
        const { amountsList, noAmountsList } = this.state;
        return (
            <div className={style.ExchangeAmounts}>
                <div
                className={style.ExchangeAmounts}
                style={
                    this.props.padding
                    ? { marginTop: JSON.parse(this.props.padding).top + "px" }
                    : {}
                }
                >
                    {this.props.from != FROM.WXMINI?<NavBar
                        className={style.navBar}
                        icon={
                        <Icon type="left" onClick={() => this.props.history.goBack()} />
                        }
                    >
                        兑换礼金券
                    </NavBar>: null}
                    {amountsList.map((rule: amountsRule, index) => (
                        <div className={style.ExchangeAmountsCard} key={index} style={{background: 'url(' + list + ') no-repeat', backgroundSize: '100%'}}>
                            <div className={style.cardLeft}>
                                <div className={style.cardTitle}>{rule.pluName}</div>
                                <div className={style.point}>
                                    <div className={style.base}>{rule.base}积分</div>
                                    <div className={style.checkNumber}>
                                        <div className={style.icon}>
                                            <MinusSquareOutlined onClick={() => {
                                                var amountsRules: amountsRule[]=  this.state.amountsList;
                                                amountsRules[index].checkqty = (amountsRules[index].checkqty as number) - 1;
                                                if((amountsRules[index].checkqty as number) - 1 >= 0) {
                                                    this.setState({amountsList: amountsRules})
                                                }
                                            }}/>
                                        </div>
                                        <Input className={style.exchangeNumber} value={rule.checkqty} onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                            var amountsRules: amountsRule[]=  this.state.amountsList;
                                            amountsRules[index].checkqty = e.target? Number(e.target.value): undefined;
                                            if(Number(e.target.value) > rule.remain) {
                                                amountsRules[index].checkqty = rule.remain;
                                                Toast.info(`此优惠券最多兑换${rule.remain}张`);
                                            }
                                            this.setState({amountsList: amountsRules})
                                        }} type='number'></Input>  
                                        <div className={style.icon}>
                                        <PlusSquareOutlined onClick={() => {
                                            var amountsRules: amountsRule[]=  this.state.amountsList;
                                            amountsRules[index].checkqty = (amountsRules[index].checkqty as number) + 1;
                                            if((amountsRules[index].checkqty as number) + 1 > rule.remain) {
                                                amountsRules[index].checkqty = rule.remain
                                                Toast.info(`此优惠券最多兑换${rule.remain}张`)
                                            }
                                            this.setState({amountsList: amountsRules})
                                        }}/></div>
                                    </div>
                                </div>
                            </div>
                            <div className={style.cardRight}>
                                <div className={style.couamt}>
                                    <div className={style.couamtTitle}>￥</div>
                                    <div className={style.couamtNumber}>{rule.couamt}</div>
                                </div>
                                <div className={style.exchange} onClick={() => this.exchange(rule)}>立即兑换</div>
                            </div>
                        </div>
                    ))}
                    {noAmountsList.map((rule: amountsRule) => (
                        <div className={style.ExchangeAmountsCard} style={{background: 'url(' + cantExchange + ') no-repeat', backgroundSize: '100%'}}>
                            <div className={style.cardLeft}>
                                <div className={style.cardTitle}>{rule.pluName}</div>
                                <div className={style.point}>{rule.base}积分</div>
                            </div>
                            <div className={style.cardRight}>
                                <div className={style.couamt}>
                                    <div className={style.displayCouamtTitle}>￥</div>
                                    <div className={style.displayCouamtNumber}>{rule.couamt}</div>
                                </div>
                                <div className={style.displayExchange}>已抢光</div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (
    state: LoginInfo
  ): {
    from: FROM | null;
    padding?: string;
    userInfo?: UserInfo ;
  } => ({
    from: state.from,
    padding: state.padding,
    userInfo: state.userInfo
  });

export default connect(mapStateToProps)(withRouter<any, ComponentClass<IProps>>(ExchangeAmounts));