
import React, { ComponentClass } from 'react';
import { RouterProps, withRouter } from 'react-router';

interface IProps extends RouterProps { }

class tempTest extends React.Component<IProps>{
    constructor(props: IProps) {
        super(props);
    }

    render() {
        return (
            <div style={{marginTop: '50%'}}>这里是测试页面</div>
        );
    }
}

export default tempTest;