import { get,post } from "../../axios/axios";
import { ymd } from "../../components/ymd";

interface KF {
    [key: string]: Function
}

async function guide(id: number) {
    const requestData = await post('/api/power/details', {raidersId: id});
    if(requestData) {
        return {
            id: requestData.data.raiders.raidersId,
            title: requestData.data.raiders.raidersTitle,
            content: requestData.data.raiders.raidersContent,
            imgUrl: requestData.data.raiders.raidersThumbnail,
            createTime: ymd(new Date(requestData.data.raiders.createtime)),
            fileUrl: requestData.data.upload,
            up: requestData.data.up === ""? {}: {
            id: requestData.data.up.raidersId,
            title: requestData.data.up.raidersTitle,
            content: requestData.data.up.raidersContent,
            imgUrl: requestData.data.up.raidersThumbnail,
            createTime: ymd(new Date(requestData.data.up.createtime)),
            },
            down: requestData.data.down === ""? {}: {
            id: requestData.data.down.raidersId,
            title: requestData.data.down.raidersTitle,
            content: requestData.data.down.raidersContent,
            imgUrl: requestData.data.down.raidersThumbnail,
            createTime: ymd(new Date(requestData.data.down.createtime)),
            },
            more: requestData.data.more === ""? []: requestData.data.more.map((more: any) => {
                return {
                    id: more.raidersId,
                    title: more.raidersTitle,
                    content: more.raidersContent,
                    imgUrl: more.raidersThumbnail,
                    createTime: ymd(new Date(more.createtime)),
                }
            })
        }
    }
}
const guideMap: KF = {
    guide: guide
}
export {guideMap};