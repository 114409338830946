import {LOGINSTATE,FUNCTOINNAME,USERIDENTITY} from "../enmu";
export const LOGIN = FUNCTOINNAME.LOGIN;
export type LOGIN = typeof LOGIN;

export const SIGNOUT = FUNCTOINNAME.SIGNOUT;
export type SIGNOUT = typeof SIGNOUT;

export const GETPADDING = FUNCTOINNAME.GETPADDING;
export type GETPADDING = typeof GETPADDING;

export const SETFROM = FUNCTOINNAME.SETFROM;
export type SETFROM = typeof SETFROM;

export const SETTOP = FUNCTOINNAME.SETTOP;
export type SETTOP = typeof SETTOP;