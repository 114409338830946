import { Icon, NavBar, Toast } from "antd-mobile";
import React, { ComponentClass } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import style from "./index.module.css";
import { get, postData,post } from "../../axios/axios";
import { LoginInfo, UserInfo as user } from "../../store/type";
import { Dispatch } from "redux";
import { login } from "../../store/actions";
import { connect } from "react-redux";
import { FROM } from "../../store/enmu";
import { debug } from "console";
import { message } from "antd";
import Password from "antd/lib/input/Password";
interface IState {
  oldPwd: string;
  newPwd: string;
  rePwd: string;
  changeContent: boolean;
  codeBtnText: string;
  codeBtn: boolean;
  phone: string;
  code?: number;
  password: string;
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onLogin: (userinfo: user, token: string) => dispatch(login(userinfo, token)),
});
const mapStateToProps = (
  state: LoginInfo
): {
  userInfo?: user;
  from: FROM | null;
  padding?: string;
} => ({
  userInfo: state.userInfo,
  from: state.from,
  padding: state.padding,
});
interface IProps extends RouteComponentProps, LoginInfo {
  changeContent?: boolean,
  onSignout: () => {};
  onLogin: (userinfo: user, token: string) => {};
}
class EditPassword extends React.PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      oldPwd: "",
      newPwd: "",
      rePwd: "",
      changeContent: props.changeContent !== undefined? props.changeContent: true,
      codeBtnText: "验证码",
      codeBtn: false,
      phone: props.userInfo?.phone || '',
      password: "",
    };
  }
  render() {
    const { changeContent, codeBtn, codeBtnText,newPwd,rePwd,phone } = this.state;
    const { userInfo } = this.props;
    return (
      <div className={style.EditPassword}>
        <div
          className={style.orderHeader}
          style={
            this.props.padding
              ? { marginTop: JSON.parse(this.props.padding).top + "px" }
              : {}
          }
        >
          {this.props.from != FROM.WXMINI ? (
            <NavBar
              className={style.navBar}
              icon={
                <Icon type="left" onClick={() => this.props.history.goBack()} />
              }
              onLeftClick={() => console.log("onLeftClick")}
            >
              修改密码
            </NavBar>
          ) : null}
        </div>

        <div className={style.main}>
          {changeContent ? (
            <div>
              <div className={style.input}>
                <span>原密码</span>
                <input
                  placeholder="请输入原密码"
                  type="password"
                  onChange={(e) => {
                    this.setState({
                      oldPwd: e.target.value,
                    });
                  }}
                />
              </div>
              <div className={style.text}>
                <span>忘记原密码？</span>
                <span onClick={this.changePhoneBox}>电话验证</span>
              </div>
              <div className={style.input}>
                <span>新密码</span>
                <input
                  placeholder="请输入新密码"
                  type="password"
                  value={newPwd}
                  onChange={(e) => {
                    this.setState({
                      newPwd: e.target.value,
                    });
                  }}
                />
              </div>
              <div className={style.input}>
                <span>确认密码</span>
                <input
                  placeholder="再次填写确认"
                  value={rePwd}
                  type="password"
                  onChange={(e) => {
                    this.setState({
                      rePwd: e.target.value,
                    });
                  }}
                />
              </div>
              <div className={style.submitBtn} onClick={this.submit}>
                提交
              </div>
            </div>
          ) : (
            <div>
              <div className={style.input}>
                <span className={style.inputTitle}>手机号</span>
                {userInfo ? (
                  <input
                    type="text"
                    value={phone}
                    onChange={(e) => {
                      this.setState({
                        phone: e.target.value,
                      });
                    }}
                    className={style.firstInput}
                  />
                ) : null}
                <div className={style.codeBox}>
                  <button
                    className={codeBtn ? style.disableBtn : style.codeText}
                    onClick={this.getSMS}
                    disabled={codeBtn}
                  >
                    {codeBtnText}
                  </button>
                </div>
              </div>
              <div className={style.input}>
                <span>验证码</span>
                <input
                  placeholder="请输入短信验证码"
                  type="text"
                  onChange={(e) => {
                    this.setState({
                      code: parseInt(e.target.value),
                    });
                  }}
                />
              </div>
              <div className={style.input}>
                <span>新密码</span>
                <input
                  placeholder="请输入新密码"
                  type="password"
                  value={newPwd}
                  onChange={(e) => {
                    this.setState({
                      newPwd: e.target.value,
                    });
                  }}
                />
              </div>
              <div className={style.input}>
                <span>确认密码</span>
                <input
                  placeholder="再次填写确认"
                  type="password"
                  value={rePwd}
                  onChange={(e) => {
                    this.setState({
                      rePwd: e.target.value,
                    });
                  }}
                />
              </div>
              <div className={style.submitBtn} onClick={this.submitPhoneBtn}>
                提交
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
  submit = async () => {
    let arr = localStorage.getItem("userInfo");
    let userInfo;
    const _this = this;
    if (arr) {
      userInfo = JSON.parse(arr);
    }
    let { oldPwd, newPwd, rePwd } = this.state;
    if(!/^[a-zA-Z]\w{5,17}$/.test(newPwd)) {
      Toast.info("密码应以字母开头，长度在6~18之间，只能包含字母、数字和下划线。");
      return false;
    }
    if (newPwd === rePwd) {
      post("/api/user/resetByPwd", {
        user_id: userInfo.userId,
        oldPwd: oldPwd,
        newPwd: newPwd,
      }).then(res => {
        if(res.code === 200) {
          _this.props.history.go(-1)
          Toast.success("修改成功", 1);
        }
      });
    } else {
      Toast.fail("两次输入的密码不一致", 1);
      this.setState({
        oldPwd: '',
        newPwd: '',
        rePwd: ''
      })
    }
  };
  submitPhoneBtn = async () => {
    let _this = this;
    let { phone, newPwd, code, rePwd } = this.state;
    if(!phone) {
      Toast.info("请输入手机号");
      return false;
    }
    if(!/^[1][3,4,5,6,7,8,9][0-9]{9}$/.test(phone)) {
      Toast.info('手机号格式错误')
      return
    }
    if(!code) {
      Toast.info("请输入验证码");
      return false;
    }
    if(!/^[a-zA-Z]\w{5,17}$/.test(newPwd)) {
      Toast.info("密码应以字母开头，长度在6~18之间，只能包含字母、数字和下划线。");
      return false;
    }
    if (newPwd === rePwd) {
      post("/api/user/resetByPhone", {
        phone: phone,
        code: code,
        password: newPwd,
      }).then(res => {
        if(res.code === 200) {
          _this.props.history.go(-1)
          Toast.success("修改成功", 1);
        }
      });
    } else {
      Toast.fail("两次输入的密码不一致", 1);
      this.setState({
        oldPwd: '',
        newPwd: '',
        rePwd: ''
      })
    }
  };
  changePhoneBox = () => {
    this.setState({
      changeContent: false,
    });
  };
  getSMS = () => {
    if(!/^[1][3,4,5,6,7,8,9][0-9]{9}$/.test(this.state.phone)) {
      Toast.info("手机号格式不正确");
      return false;
    }
    let maxTime = 60;
    let interval = setInterval(() => {
      if (maxTime > 0) {
        --maxTime;
        this.setState({
          codeBtnText: "重新获取" + maxTime + 's',
          codeBtn: true,
        });
      } else {
        clearInterval(interval);
        this.setState({
          codeBtnText: "验证码",
          codeBtn: false,
        });
      }
    }, 1000);
    get("/api/v1/sendSMS", { phone: this.state.phone });
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter<any, ComponentClass<IProps, IState>>(EditPassword));
