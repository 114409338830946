import React from "react";
import { PhoneLogin, AccountLogin, Register, Main, Home, Gis, Mine, Order, About, News, 
  Information, ContentList, DetailPage, UserInfo, MineRedirect,MyOrder,Find,
  SuggestionList,Suggestion,EditUsername ,EditEmail,EditPassword,Complaint,ComplaintList, ContentPage, 
  Organization, TouristNotice, AboutHome, ComplaintDetail, 
  OutURL,Setting,Reservation,ReservationList,ReservationDetail,SearchPage} from "../pages";
import Amounts from "../pages/Amounts";
import ExchangeAmounts from "../pages/ExchangeAmounts";
import AmountsHistory from "../pages/AmountsHistory"
import ResaleOrder from "../pages/ResaleOrder";
import TicketReplace from "../pages/TicketReplace";
import Invoice from "../pages/Invoice";
import WxminiOpen from "../pages/WxminiOpen";
import ResaleDetail from "../pages/ResaleDetail";
import InvoiceMore from "../pages/InvoiceMore";
import InvoiceAmt from "../pages/InvoiceAmt";
import GZHRedirect from "../pages/GZHRedirect";
import Private from "../pages/Private";
import Download from "../pages/Download";
import PayCode from "../pages/PayCode";
import GiftCode from "../pages/GiftCode";
import TempTest from "../pages/tempTest";

export type RouterType = {
  path: string,
  key: string,
  component: React.ComponentType<any>,
  root: string[],
  notExect? :boolean,
  children: RouterType[],
  withProps?: boolean,
  settings?: object 
}

const PhoneLoginRouter: RouterType = {
  path: '/phoneLogin',
  key: '/phoneLogin',
  component: PhoneLogin,
  root: [],
  children: [],
  notExect: true,
}

const AccountLoginRouter: RouterType = {
  path: '/accountLogin',
  key: '/accountLogin',
  component: AccountLogin,
  root: [],
  children: [],
  notExect: true,
}

const RegisterRouter: RouterType = {
  path: '/register',
  key: '/register',
  component: Register,
  root: [],
  children: [],
  notExect: true,
}

const HomeRouter: RouterType = {
  path: '/main/home',
  key: '/home',
  component: Home,
  root: [],
  children: [],
  notExect: true
}
const GisRouter: RouterType = {
  path: '/main/gis',
  key: '/gis',
  component: Gis,
  root: [],
  children: [],
  notExect: true
}

const OrderRouter: RouterType = {
  path: '/main/order',
  key: '/order',
  component: Order,
  root: [],
  children: [],
  notExect: true
}

const MineRouter: RouterType = {
  path: '/main/mine',
  key: '/mine',
  component: Mine,
  root: [],
  children: [],
  notExect: false
}
const UserInfoRouter: RouterType = {
  path: '/userInfo',
  key: '/userInfo',
  component: UserInfo,
  root: [],
  children: [],
  notExect: true
}
const toAIRouter: RouterType = {
  path: '/toAI/:from',
  key: '/toAI',
  component: UserInfo,
  root: [],
  children: [],
  notExect: true
}
const MineRedirectRouter: RouterType = {
  path: '/main/mine/:token',
  key: '/mine',
  component: MineRedirect,
  root: [],
  children: [],
  notExect: true
}
const MainRouter: RouterType = {
  path: '/main',
  key: '/main',
  component: Main,
  root: [],
  children: [HomeRouter, GisRouter, OrderRouter, MineRouter, MineRedirectRouter],
  notExect: true
}

const ContentListRouter: RouterType = {
  path: '/:module/contentList/:name',
  key: '/contentList',
  component: ContentList,
  root: [],
  children: [],
  notExect: true
}
const DetailRouter: RouterType = {
    path: '/detail/:module/:component/:id',
    key: '/contentPage',
    component: DetailPage,
    root: [],
    children: [],
    notExect: true
}

const MyOrderRouter: RouterType = {
  path: '/myOrder',
  key: '/myOrder',
  component: MyOrder,
  root: [],
  children: [],
  notExect: true
}
const FindRouter: RouterType = {
  path: '/find',
  key: '/find',
  component: Find,
  root: [],
  children: [],
  notExect: true
}
const SuggestionListRouter: RouterType = {
  path: '/suggestionList',
  key: '/suggestionList',
  component: SuggestionList,
  root: [],
  children: [],
  notExect: true
}
const SuggestionRouter: RouterType = {
  path: '/suggestion',
  key: '/suggestion',
  component: Suggestion,
  root: [],
  children: [],
  notExect: true
}
const ComplaintListRouter: RouterType = {
  path: '/complaintList',
  key: '/complaintList',
  component: ComplaintList,
  root: [],
  children: [],
  notExect: true
}
const ComplaintRouter: RouterType = {
  path: '/complaint',
  key: '/complaint',
  component: Complaint,
  root: [],
  children: [],
  notExect: true
}
const EditUsernameRouter: RouterType = {
  path: '/editUsername',
  key: '/editUsername',
  component: EditUsername,
  root: [],
  children: [],
  notExect: true
}

const ContentPageRouter: RouterType = {
  path: '/:module/contentPage/:name',
  key: '/contentPage',
  component: ContentPage,
  root: [],
  children: [],
  notExect: true
}
const EditEmailRouter: RouterType = {
  path: '/editEmail',
  key: '/editEmail',
  component: EditEmail,
  root: [],
  children: [],
  notExect: true
}

const OrganizationRouter: RouterType = {
  path: '/:module/organization/:name',
  key: '/organization',
  component: Organization,
  root: [],
  children: [],
  notExect: true
}

const InformationRouter: RouterType = {
  path: '/information',
  key: '/information',
  component: Information,
  root: [],
  children: [ ContentListRouter ],
  notExect: true
}

const TouristNoticeRouter: RouterType = {
  path: '/touristNotice',
  key: '/touristNotice',
  component: TouristNotice,
  root: [],
  children: [ ContentPageRouter, ContentListRouter ],
  notExect: true
}

const AboutRouter: RouterType = {
  path: '/about',
  key: '/about',
  component: About,
  root: [],
  children: [ ContentPageRouter, ContentListRouter, OrganizationRouter ],
  notExect: true
}

const AboutHomeRouter: RouterType = {
  path: '/aboutHome',
  key: '/aboutHome',
  component: AboutHome,
  root: [],
  children: [],
  notExect: true
}
const EditPasswordRouter: RouterType = {
  path: '/editPassword',
  key: '/editPassword',
  component: EditPassword,
  root: [],
  children: [],
  notExect: true
}
const phoneEditPasswordRouter: RouterType = {
  path: '/phoneEditPassword',
  key: '/phoneEditPassword',
  component: EditPassword,
  root: [],
  children: [],
  notExect: true,
  withProps: true,
  settings: {
    changeContent: false
  }
}
const ComplaintDetailRouter: RouterType = {
  path: '/detail/:component/:id',
  key: '/contentPage',
  component: ComplaintDetail,
  root: [],
  children: [],
  notExect: true
}
const SettingRouter: RouterType = {
  path: '/Setting',
  key: '/Setting',
  component: Setting,
  root: [],
  children: [],
  notExect: true
}

const outURLRouter: RouterType = {
  path: '/outURL/:url/:name',
  key: '/outURL',
  component: OutURL,
  root: [],
  children: [],
  notExect: true
}

const ReservationRouter: RouterType = {
  path: '/reservation',
  key: '/reservation',
  component: Reservation,
  root: [],
  children: [],
  notExect: true
}

const ReservationListRouter: RouterType = {
  path: '/reservationList',
  key: '/reservationList',
  component: ReservationList,
  root: [],
  children: [],
  notExect: true
}
const ReservationDetailRouter: RouterType = {
  path: '/reservationDetail/:id',
  key: '/reservationDetail',
  component: ReservationDetail,
  root: [],
  children: [],
  notExect: true
}

const SearchPageRouter: RouterType = {
  path: '/searchPage',
  key: '/searchPage',
  component: SearchPage,
  root: [],
  children: [],
  notExect: true
}
const AmountsRouter: RouterType = {
  path: '/amounts',
  key: '/amounts',
  component: Amounts,
  root: [],
  children: [],
  notExect: true
}
const ExchangeAmountsRouter: RouterType = {
  path: '/exchangeamounts',
  key: '/exchangeamounts',
  component: ExchangeAmounts,
  root: [],
  children: [],
  notExect: true
}
const AmountsHistoryRouter: RouterType = {
  path: '/amountshistory',
  key: '/amountshistory',
  component: AmountsHistory,
  root: [],
  children: [],
  notExect: true
}
const ResaleOrderRouter: RouterType = {
  path: '/resaleOrder',
  key: '/resaleOrder',
  component: ResaleOrder,
  root: [],
  children: [],
  notExect: true
}
const TicketReplaceRouter: RouterType = {
  path: '/ticketreplace/:orderId/:callback',
  key: '/ticketreplace',
  component: TicketReplace,
  root: [],
  children: [],
  notExect: true
}
const TicketTokenReplaceRouter: RouterType = {
  path: '/ticketreplace/:redirect/',
  key: '/ticketreplace',
  component: TicketReplace,
  root: [],
  children: [],
  notExect: true
}
const InvoiceRouter: RouterType = {
  path: '/invoice/:orderId/:from',
  key: '/invoice',
  component: Invoice,
  root: [],
  children: [],
  notExect: true
}
const WxminiOpenRouter: RouterType = {
  path: '/wxminiOpen/:token/:openUrl',
  key: '/wxminiOpen',
  component: WxminiOpen,
  root: [],
  children: [],
  notExect: true
}
const ResaleDetailRouter: RouterType = {
  path: '/resaleDetail/:orderId',
  key: '/resaleDetail',
  component: ResaleDetail,
  root: [],
  children: [],
  notExect: true
}
const InvoiceMoreRouter: RouterType = {
  path: '/invoiceMore',
  key: '/invoiceMore',
  component: InvoiceMore,
  root: [],
  children: [],
  notExect: true
}
const InvoiceAmtRouter: RouterType = {
  path: '/invoiceAmt',
  key: '/invoiceAmt',
  component: InvoiceAmt,
  root: [],
  children: [],
  notExect: true
}
const GZHRedirectRouter: RouterType = {
  path: '/GZHRedirect/:opetion',
  key: '/GZHRedirect',
  component: GZHRedirect,
  root: [],
  children: [],
  notExect: true
}
const DownloadRouter: RouterType = {
  path: '/download',
  key: '/download',
  component: Download,
  root: [],
  children: [],
  notExect: true
}

const PrivateRouter: RouterType = {
  path: '/Private',
  key: '/Private',
  component: Private,
  root: [],
  children: [],
  notExect: true
}
const PayCodeRouter: RouterType = {
  path: '/paycode',
  key: 'paycode',
  component: PayCode,
  root: [],
  children: [],
  notExect: true
}
const GiftCodeRouter: RouterType = {
  path: '/giftCode/:ticketCode',
  key: 'giftCode',
  component: GiftCode,
  root: [],
  children: [],
  notExect: true
}
const tempTestRouter: RouterType = {
  path: '/tempTest',
  key: 'tempTest',
  component: TempTest,
  root: [],
  children: [],
  notExect: true
}
  // 总路由
const Routers: RouterType[] = ([
  DownloadRouter,
  MainRouter,
  PhoneLoginRouter,
  AccountLoginRouter,
  RegisterRouter,
  AboutRouter,
  InformationRouter,
  DetailRouter,
  UserInfoRouter,
  MyOrderRouter,
  FindRouter,
  SuggestionListRouter,
  SuggestionRouter,
  EditUsernameRouter,
  TouristNoticeRouter,
  AboutHomeRouter,
  EditEmailRouter,
  EditPasswordRouter,
  ComplaintListRouter,
  ComplaintRouter,
  ComplaintDetailRouter,
  phoneEditPasswordRouter,
  outURLRouter,
  SettingRouter,
  toAIRouter,
  ReservationRouter,
  ReservationListRouter,
  ReservationDetailRouter,
  SearchPageRouter,
  AmountsRouter,
  ExchangeAmountsRouter,
  AmountsHistoryRouter,
  ResaleOrderRouter,
  TicketReplaceRouter,
  TicketTokenReplaceRouter,
  InvoiceRouter,
  WxminiOpenRouter,
  ResaleDetailRouter,
  InvoiceMoreRouter,
  InvoiceAmtRouter,
  GZHRedirectRouter,
  PrivateRouter,
  PayCodeRouter,
  GiftCodeRouter,
  tempTestRouter
])

export {
  Routers,
  MainRouter,
  AboutRouter,  
  InformationRouter,
  TouristNoticeRouter
}
