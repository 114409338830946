import React, { ComponentClass, FunctionComponentFactory } from 'react';
import { RouterProps, withRouter } from 'react-router-dom';
import SvgLog from '../SvgLog';
import style from './index.module.css';

export interface NoticeInfo {
    announcementId?: 28
    announcementTitle?: string | null
}
export interface INoticeProps {
    noticeList: NoticeInfo[]
}
interface IProps extends RouterProps{
    noticeList: NoticeInfo[]
}
class Notice extends React.Component<IProps>{
    render() {
        const { noticeList } = this.props;
        var titleLength = 0;
        noticeList.forEach(notice => {
          titleLength += (notice.announcementTitle && notice.announcementTitle !== null? (notice.announcementTitle.length * 12 + 42): 0) / 40
        })
        return (
            <div className={style.Notice}>
                <span className={style.title}><SvgLog name="noticeTitle" width="100%" height="100%" color=''></SvgLog></span>
                <div className={style.noticeBox}>
                    <div className={style.noticContent} style={{animationDuration: noticeList.length > 1? titleLength + 's': '0s'}}>
                        {noticeList.length > 1? noticeList.map((notice: NoticeInfo, index: number)  => {
                            return <div className={style.noticeItem} key={index} onClick={() => {
                                this.props.history.push(`/detail/information/announcement/${notice.announcementId}`);
                              }}>{notice.announcementTitle}</div>;
                        }): null}
                        {noticeList.length > 1? noticeList.map((notice: NoticeInfo, index: number)  => {
                            return <div className={style.noticeItem} key={index} onClick={() => {
                                this.props.history.push(`/detail/information/announcement/${notice.announcementId}`);
                              }}>{notice.announcementTitle}</div>;
                        }): null}                        
                        {noticeList.length > 1? noticeList.map((notice: NoticeInfo, index: number)  => {
                            return <div className={style.noticeItem} key={index} onClick={() => {
                                this.props.history.push(`/detail/information/announcement/${notice.announcementId}`);
                              }}>{notice.announcementTitle}</div>;
                        }): null}
                        {noticeList.map((notice: NoticeInfo, index: number)  => {
                            return <div className={style.noticeItem} key={index*4} onClick={() => {
                                this.props.history.push(`/detail/information/announcement/${notice.announcementId}`);
                              }}>{notice.announcementTitle}</div>;
                        })}
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter<any, ComponentClass<IProps>>(Notice);