import { RightOutlined } from '@ant-design/icons';
import { Input, Radio, RadioChangeEvent } from 'antd';
import { Icon, NavBar, Toast } from 'antd-mobile';
import TextArea from 'antd/lib/input/TextArea';
import React, { ChangeEvent, ComponentClass } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';
import { FROM, LOGINSTATE } from '../../store/enmu';
import { LoginInfo, UserInfo } from '../../store/type';
import { InvoiceForm } from '../Invoice';
import { InvoiceData } from '../ResaleDetail';
import style from './index.module.css';


const mapStateToProps = (
    state: LoginInfo
  ): {
    userInfo?: UserInfo;
    state?: LOGINSTATE;
    from: FROM;
    padding?: string;
  } => ({
    userInfo: state.userInfo,
    state: state.state,
    from: state.from as FROM,
    padding: state.padding
  });
interface IProps extends RouteComponentProps, LoginInfo {}
interface IState {
    form: InvoiceForm
}
class InvoiceMore extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            form: {
                ...localStorage.getItem('orderDetail') !== null? JSON.parse(localStorage.getItem('orderDetail') as string): {},
            }
        }
    }
    clickOk() {
        this.state.form.buyerAddress = this.state.form.buyerAddress.replace(/\s*/g, "")
        this.state.form.buyerTelephone = this.state.form.buyerTelephone.replace(/\s*/g, "")
        this.state.form.buyerBank = this.state.form.buyerBank.replace(/\s*/g, "")
        this.state.form.buyerAccount = this.state.form.buyerAccount.replace(/\s*/g, "")
        this.state.form.remark = this.state.form.remark.replace(/\s*/g, "")

        var phonePattern = /((\d{11})|^((\d{7,8})|(\d{4}|\d{3})-(\d{7,8})|(\d{4}|\d{3})-(\d{7,8})-(\d{4}|\d{3}|\d{2}|\d{1})|(\d{7,8})-(\d{4}|\d{3}|\d{2}|\d{1}))$)/
        if(phonePattern.test(this.state.form.buyerTelephone) || this.state.form.buyerTelephone === "") {
            localStorage.setItem('orderDetail', JSON.stringify(this.state.form))
        } else {
            var form = this.state.form;
            form.buyerTelephone = "";
            Toast.info('手机号格式有问题无法保存');
            localStorage.setItem("orderDetail", JSON.stringify(form))
        }
        this.props.history.goBack()
    }
    render() {
        const { form } = this.state;
        return (
            <div className={style.ResaleOrder}>
                <div className={style.orderHeader}>
                    {this.props.from != FROM.WXMINI?<NavBar
                        style={
                            this.props.padding
                            ? { marginTop: JSON.parse(this.props.padding).top + "px" }
                            : {}
                        }
                        className={style.navBar}
                        icon={
                        <Icon type="left" onClick={() => this.props.history.goBack()} />
                        }
                    >
                        更多内容
                    </NavBar>: null}
                </div>
                <div className={style.pageDetail}
                style={
                    this.props.padding
                    ? { marginTop: JSON.parse(this.props.padding).top + "px" }
                    : {}
                }>
                    <div className={style.title}></div>
                    <div className={style.formGroup}>
                        <div className={style.textHead}>
                            <div className={style.textAreaTitle}>公司地址</div>
                            <div className={style.textContent}></div>
                            <div className={style.textCount}>{form.buyerAddress.length}/49</div>
                        </div>
                        <TextArea placeholder={'填写公司地址'} value={form.buyerAddress} className={style.textArea} autoSize={{ minRows: 3, maxRows: 3 }} maxLength={49} size={'large'} onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
                            this.setState({form: {...form, buyerAddress: e.target.value}})
                            }}/>
                        <div className={style.line}></div>
                        <div className={style.form}>
                            <div className={style.formName}>公司电话</div>
                            <Input className={style.input} placeholder={'填写公司电话'} value={form.buyerTelephone} onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                this.setState({form: {...form, buyerTelephone: e.target.value}})
                                }}/>
                        </div>
                    </div>   
                    <div className={style.title}></div>                  
                    <div className={style.formGroup}>
                        <div className={style.textHead}>
                            <div className={style.textAreaTitle}>公司开户行</div>
                            <div className={style.textContent}></div>
                            <div className={style.textCount}>{form.buyerBank.length}/49</div>
                        </div>
                        <TextArea placeholder={'填写公司开户行'} value={form.buyerBank} className={style.textArea} autoSize={{ minRows: 3, maxRows: 3 }} maxLength={49} size={'large'} onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
                            this.setState({form: {...form, buyerBank: e.target.value}})
                            }}/>
                        <div className={style.line}></div>
                        <div className={style.form}>
                            <div className={style.formName}>开户行账号</div>
                            <Input className={style.input} placeholder={'填写开户行账号'} value={form.buyerAccount} onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                this.setState({form: {...form, buyerAccount: e.target.value}})
                                }}/>
                        </div>
                    </div>
                    <div className={style.title}></div>             
                    <div className={style.formGroup}>
                        <div className={style.textHead}>
                            <div className={style.textAreaTitle}>备注</div>
                            <div className={style.textContent}>该内容将会打印在发票上</div>
                            <div className={style.textCount}>{form.remark.length}/49</div>
                        </div>
                        <TextArea placeholder={'按企业报销要求填写'} className={style.textArea} value={form.remark} autoSize={{ minRows: 3, maxRows: 3 }} maxLength={49} size={'large'} onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
                            this.setState({form: {...form, remark: e.target.value}})
                            }}/>
                    </div>
                </div>
                <div className={style.invoiceButton} onClick={() => this.clickOk()}>确定</div>
                {/* <div className={style.title}>接收方式</div>
                <div className={style.formGroup}>
                    <div className={style.form}>
                        <div className={style.formName}>电子邮箱</div>
                        <Input className={style.input} value={form.buyerName} onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            this.setState({form: {...form, buyerName: e.target.value}})
                            }}/>
                    </div>
                </div> */}
            </div>
        )
    }
}

export default connect(mapStateToProps)(withRouter<any, ComponentClass<IProps>>(InvoiceMore));