import React from 'react';

interface IProps{}
interface IState{}
class Order extends React.PureComponent<IProps, IState> {
    render() {
        return (
            <div></div>
        );
    }
}

export default Order;