import React from 'react';
import { connect } from 'react-redux';
import { RouterProps } from 'react-router';
import { Redirect, Route, Switch } from 'react-router-dom';
import Navbar from '../../components/Navbar';
import { Routers, MainRouter } from '../../routers';
import { FROM } from '../../store/enmu';
import { LoginInfo } from '../../store/type';

interface IProps extends RouterProps, LoginInfo {

}
class Main extends React.Component<IProps> {
    render() {
        return (
        <div>
            <Navbar/>
            <Switch>
            {
            MainRouter.children.map(router => (
                <Route
                exact={!router.notExect}
                key={router.key}
                path={router.path}
                component={router.component}
                >
                </Route>
            ))
            }
            <Redirect path="/" to="/main/home" />
            {/* 设置默认路由 推荐方法一*/}
            {/* 方法一 */}
            {/* <Route path="/" component={Home} exact></Route> */}
            {/* 方法二 重定向*/}
            {/* <Redirect path="/" to="/home" /> */}
            </Switch> 
        </div>
        );
    }
}

  export default Main;