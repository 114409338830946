// 登陆状态
enum LOGINSTATE {
    SIGNIN = "LOGINSTATE_SIGNIN",
    SIGNOUT = "LOGINSTATE_SIGNOUT"
}
enum FROM {
    APP = "app",
    WXMINI = "wxmini",
    H5 = "h5"
}
// dispatch 方法
enum FUNCTOINNAME {
    LOGIN = "FUNCTOINNAME_LOGIN",
    SIGNOUT = "FUNCTOINNAME_SIGNOUT",
    GETPADDING = "FUNCTIONNAME_GETPADDING",
    SETFROM = "FUNCTIONNAME_SETFROM",
    SETTOP = "FUNCTIONNAME_SETTOP"
}
// 用户权限
enum USERIDENTITY {
    ADMIN = "USERIDENTITY_ADMIN"
}
export {LOGINSTATE,FUNCTOINNAME,USERIDENTITY,FROM};