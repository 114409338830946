import { Icon, NavBar, Tabs } from "antd-mobile";
import React, { ComponentClass } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import "./index.css";
import { FROM } from "../../store/enmu";
import { connect } from "react-redux";
import { get, post } from "../../axios/axios";
import { LoginInfo, UserInfo } from "../../store/type";
interface IProps extends LoginInfo, RouteComponentProps { }
interface IState {
  list: [];
}

const mapStateToProps = (
  state: LoginInfo
): {
  userInfo?: UserInfo;
  from: FROM | null;
  padding?: string;
} => ({
  userInfo: state.userInfo,
  from: state.from,
  padding: state.padding,
});
class Find extends React.PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      list: [],
    };
    this.getData(5);
  }
  getData = async (type: number) => {
    let res = await post("/api/complaints/historylist", {
      complaintTypeId: type,
      status: 1,
    });

    this.setState({
      list: res.data,
    });
  };
  formatDate(time: string) {
    const date = new Date(parseInt(time));
    const year = date.getFullYear();
    const mon = date.getMonth() + 1;
    const day = date.getDate();
    const H = date.getHours();
    const Min = date.getMinutes();
    const S = date.getSeconds();
    return year + "-" + mon + "-" + day + " " + H + ":" + Min + ":" + S;
  }
  jump(url?: number) {
    url && this.props.history.push(`/detail/find/${url}`);
  }
  render() {
    const tabs = [{ title: "全部" }, { title: "寻人" }, { title: "寻物" }];
    const { list } = this.state;
    return (
      <div className="Find">
        <div
          className="orderHeader"
          style={
            this.props.padding
              ? { marginTop: JSON.parse(this.props.padding).top + "px" }
              : {}
          }
        >
          {this.props.from != FROM.WXMINI ? <NavBar
            className="navBar"
            icon={
              <Icon type="left" onClick={() => this.props.history.goBack()} />
            }
          >
            寻人寻物
          </NavBar> : null}
        </div>
        <div className="main">
          <Tabs
            tabs={tabs}
            initialPage={0}
            animated={false}
            useOnPan={false}
            onChange={this.changeTabs}
          >
            <div className="findBox">
              {list.map((item: any) => {
                return (
                  <div onClick={() => this.jump(item.complaintId)}>
                    <div className="imgDiv">
                      <img src={item.screenshotImages} alt="" />
                    </div>
                    <div className="textDiv">
                      {item.complaintTypeId === 3 ? (
                        <span>寻人</span>
                      ) : (
                        <span>寻物</span>
                      )}
                      {item.title}
                    </div>
                    <div className="textDiv" style={{ marginLeft: '2vw' }}>
                      {item.xType == 0 ? (
                        <span>未完成</span>
                      ) : ''}
                      {item.xType == 1 ? (
                        <span>已完成</span>
                      ) : ''}
                    </div>
                    <span className="footer">
                      {this.formatDate(item.createTime)}
                    </span>
                  </div>
                );
              })}
            </div>
            <div>
              <div className="findBox">
                {list.map((item: any) => {
                  return (
                    <div onClick={() => this.jump(item.complaintId)}>
                      <div className="imgDiv">
                        <img src={item.screenshotImages} alt="" />
                      </div>
                      <div className="textDiv">
                        {item.complaintTypeId === 3 ? (
                          <span>寻人</span>
                        ) : (
                          <span>寻物</span>
                        )}
                        {item.title}
                      </div>
                      <div className="textDiv" style={{ marginLeft: '2vw' }}>
                        {item.xType == 0 ? (
                          <span>未完成</span>
                        ) : ''}
                        {item.xType == 1 ? (
                          <span>已完成</span>
                        ) : ''}
                      </div>
                      <span className="footer">
                        {this.formatDate(item.createTime)}
                      </span>
                    </div>
                  );
                })}
              </div>
            </div>
            <div>
              <div className="findBox">
                {list.map((item: any) => {
                  return (
                    <div onClick={() => this.jump(item.complaintId)}>
                      <div className="imgDiv">
                        <img src={item.screenshotImages} alt="" />
                      </div>
                      <div className="textDiv">
                        {item.complaintTypeId === 3 ? (
                          <span>寻人</span>
                        ) : (
                          <span>寻物</span>
                        )}
                        {item.title}
                      </div>
                      <div className="textDiv" style={{ marginLeft: '2vw' }}>
                        {item.xType == 0 ? (
                          <span>未完成</span>
                        ) : ''}
                        {item.xType == 1 ? (
                          <span>已完成</span>
                        ) : ''}
                      </div>
                      <span className="footer">
                        {this.formatDate(item.createTime)}
                      </span>
                    </div>
                  );
                })}
              </div>
            </div>
          </Tabs>
        </div>
      </div>
    );
  }
  changeTabs = (e: any) => {
    if (e.title === "全部") {
      this.getData(5);
    } else if (e.title === "寻人") {
      this.getData(3);
    } else {
      this.getData(4);
    }
  };
}

export default connect(mapStateToProps)(
  withRouter<any, ComponentClass<IProps, IState>>(Find)
);
