import { Icon, NavBar, Tabs, Toast } from "antd-mobile";
import React, { ComponentClass } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { LoginInfo, UserInfo } from "../../store/type";
import style from "./index.module.css";
import { get } from "../../axios/axios";
import { Dispatch } from "redux";
import { FROM, LOGINSTATE } from "../../store/enmu";
import { login } from "../../store/actions";
interface IProps extends LoginInfo, RouteComponentProps {
  login: (useInfo: UserInfo, token: string) => {}
}
interface IState {
  cleaning: boolean;
  version: string;
}
const mapDispatchToProps = (dispatch: Dispatch) => ({
  login: (useInfo: UserInfo, token: string) => dispatch(login(useInfo, token))
});
const mapStateToProps = (
  state: LoginInfo
): {
  userInfo?: UserInfo;
  from: FROM | null;
  padding?: string;
  state: LOGINSTATE
} => ({
  userInfo: state.userInfo,
  from: state.from,
  padding: state.padding,
  state: state.state
});
declare var window: {
  [key: string]: any; // missing index defintion
  prototype: Window;
  new (): Window;
};
class SuggestionList extends React.PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      version: "",
      cleaning: true
    };
    this.getData();
  }
  componentDidMount() {
    this.setState({cleaning: false});
  }
  getData = async () => {
    let res = await get("/api/version/getAllByType", {
      type: 1,
    });
    let data = res.data[0].version;
    this.setState({
      version: data,
    });
  };
  cleanCache() {
    let local = {
      loginState: localStorage.getItem('loginState') ? localStorage.getItem('loginState') as LOGINSTATE : LOGINSTATE.SIGNOUT,
      userInfo: localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo') as string) : {},
      userToken: localStorage.getItem('userToken') ? (localStorage.getItem('userToken') as string) : '',
    }
    if(!this.state.cleaning && this.props.state === LOGINSTATE.SIGNIN) {
      this.setState({cleaning: true},() => {
        try{
          window["jsClearData"].postMessage(JSON.stringify(local));
        } catch(e){}
      });
    }
  }
  updateVersion() {
    Toast.success("目前已是最新版本", 1);
  }
  render() {
    const { version } = this.state;
    const that = this;
    window["setUserInfo"] = function(e: string) {
      try{
        let data = JSON.parse(e.replace("\\", ""))
        for(let key in data) {
          localStorage.setItem(key, data[key]);
        }
        that.props.login(data.userInfo, data.token);
        Toast.success("清理缓存成功", 1);
        that.setState({cleaning: false});
        return e
      }catch(e: any){
        return e.toString()
      }
    };
    try{
      window['jsGetUserInfo'].postMessage('');
    } catch(e) {}
    return (
      <div className={style.setting}>
        <div
          className={style.orderHeader}
          style={
            this.props.padding
              ? { marginTop: JSON.parse(this.props.padding).top + "px" }
              : {}
          }
        >
          <NavBar
            className={style.navBar}
            icon={
              <Icon type="left" onClick={() => this.props.history.goBack()} />
            }
          >
            设置
          </NavBar>
        </div>
        <div className={style.main}>
          <div className={style.adviceBox} onClick={() => this.updateVersion()}>
            <span>检查最新版本</span>
            <div className={style.rightText}>
              <span>{version}</span>
              {/* <Icon type="right" /> */}
            </div>
          </div>
          <div className={style.adviceBox} onClick={() => this.cleanCache()}>
            <span>清理缓存</span>
            <div className={style.rightText}>
              {/* <Icon type="right" /> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(
  withRouter<any, ComponentClass<IProps, IState>>(SuggestionList)
);
