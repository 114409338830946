import React, { ComponentClass } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { PageHeader, Button, Avatar, message, Alert } from "antd";
import { Picker, Icon, ImagePicker, Modal, NavBar, Toast } from "antd-mobile";
import { LeftOutlined, RightOutlined, UserOutlined } from "@ant-design/icons";
import { get, post, postFile } from "../../axios/axios";
import "./index.css";
import { FROM, LOGINSTATE } from "../../store/enmu";
import { Dispatch } from "redux";
import { login, signout } from "../../store/actions";

import defaultFace from "../../asset/DefaultFace/face0.png";
import { LoginInfo, UserInfo as user } from "../../store/type";
import wx from "weixin-js-sdk";

interface IProps extends RouteComponentProps, LoginInfo {
  onSignout: () => {};
  onLogin: (userinfo: user, token: string) => {};
}
interface IState {
  headImg: string;
  cameraPhoto?: any;
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onSignout: () => dispatch(signout()),
  onLogin: (userinfo: user, token: string) => dispatch(login(userinfo, token)),
});
const mapStateToProps = (
  state: LoginInfo
): {
  userInfo?: user;
  from: FROM | null;
  padding?: string;
  userToken: string;
  appid: string;
} => ({
  userInfo: state.userInfo,
  from: state.from,
  padding: state.padding,
  userToken: state.userToken as string,
  appid: state.appid
});

const alert = Modal.alert;
const imgRef = React.createRef<HTMLInputElement>();
class UserInfo extends React.PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      headImg: "",
      cameraPhoto: '',
    };
    const that = this;
    window["uploadImg"] = function (img: Blob) {
      that.uploadimg(img);
    };
    this.wxWapLogin();
  }
  async wxWapLogin() {
    let res = await get('/api/v1/getTicketSign', { url: encodeURIComponent(window.location.href.split('#')[0]) })
    if (res.code === 200) {
      wx.config({
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: this.props.appid, // 必填，公众号的唯一标识
        timestamp: res.data.timestamp, // 必填，生成签名的时间戳
        nonceStr: res.data.noncestr, // 必填，生成签名的随机串
        signature: res.data.signature,// 必填，签名
        jsApiList: [
          'chooseImage'
        ] // 必填，需要使用的JS接口列表
      })
    }
  }
  async uploadimg(file: Blob) {
    let subImg = new FormData();
    subImg.append("file", file);
    let res = await postFile("/api/basis/uploadPhoto", subImg);
    this.props.onLogin(
      { ...this.props.userInfo, avatar: res },
      localStorage.getItem("userToken") as string
    );
    post("/api/basis/editUserInfo", { ...this.props.userInfo, avatar: res });
    Toast.info("修改成功");
  }
  render() {
    const { userInfo } = this.props;
    return (
      <div className="MyUserInfo">
        <div
          className="orderHeader"
          style={
            this.props.padding
              ? { marginTop: JSON.parse(this.props.padding).top + "px" }
              : {}
          }
        >
          {this.props.from != FROM.WXMINI ? <NavBar
            className="navBar"
            icon={
              <Icon type="left" onClick={() => this.props.history.goBack()} />
            }
          >
            个人信息
          </NavBar> : null}
        </div>
        <div className="usermineInfo">
          {this.props.from == FROM.APP ?
            <Picker
              data={[[
                { label: "相册", value: "0" },
                { label: "相机", value: "1" },]]}
              title="选择"
              cascade={false}
              value={this.state.cameraPhoto}
              onChange={(e) => this.setState({ cameraPhoto: e })}
              onOk={(v) => this.setCameraOrPhotoHead(v)}
            >
              <div className="mineAvatar">
                <div className="mineAvatarTitle">头像</div>
                <RightOutlined className="rightIcon" />
                <div className="mineAvatarContent">
                  {userInfo && userInfo.avatar ? (
                    <div style={{ background: `url(${userInfo.avatar}) center center / 100% no-repeat` }}></div>
                  ) : (
                    <div style={{ background: `url(${defaultFace}) center center / 100% no-repeat` }}></div>
                  )}
                  {/* <ImagePicker
                    onChange={this.imgSelected}
                    accept="image/gif,image/jpeg,image/jpg,image/png"
                  /> */}
                </div>
              </div>
            </Picker> :
            <div className="mineAvatar" onClick={() => this.getImage()}>
              <div className="mineAvatarTitle">头像</div>
              <RightOutlined className="rightIcon" />
              <div className="mineAvatarContent" style={this.props.from !== FROM.H5 || window.navigator.userAgent.toLowerCase().match(/MicroMessenger/i) == 'micromessenger' ? { pointerEvents: 'none' } : {}}>
                {userInfo && userInfo.avatar ? (
                  <div style={{ background: `url(${userInfo.avatar}) center center / 100% no-repeat` }}></div>
                ) : (
                  <div style={{ background: `url(${defaultFace}) center center / 100% no-repeat` }}></div>
                )}
                <ImagePicker
                  onChange={this.imgSelected}
                  accept="image/gif,image/jpeg,image/jpg,image/png"
                />
              </div>
            </div>}
          <div className="mineInfoItem" onClick={() => this.props.history.push("/editUsername")}>
            <div className="mineInfoItemTitle">昵称</div>
            <div className="mineInfoItemContent">
              {userInfo && userInfo.username}
              <RightOutlined className="right" />
            </div>
          </div>
          <div className="mineInfoItem" onClick={() => this.props.history.push("/editEmail")}>
            <div className="mineInfoItemTitle">邮箱</div>
            <div
              className="mineInfoItemContent">
              {userInfo && userInfo.mailbox}
              <RightOutlined className="right" />
            </div>
          </div>
          <div className="mineInfoItem" onClick={() => this.props.history.push("/editPassword")}>
            <div className="mineInfoItemTitle">修改密码</div>
            <div
              className="mineInfoItemContent">
              <RightOutlined className="right" />
            </div>
          </div>
          {this.props.from == FROM.APP ?
            <Picker
              data={[[
                { label: "相册", value: "0" },
                { label: "相机", value: "1" },]]}
              title="选择"
              cascade={false}
              value={this.state.cameraPhoto}
              onChange={(e) => this.setState({ cameraPhoto: e })}
              onOk={(v) => this.setCameraOrPhotoFace(v)}
            >
              <div className="mineAvatar">
                <div className="mineAvatarTitle">上传人脸照片</div>
                <RightOutlined className="rightIcon" />
                <div className="mineAvatarContent" style={window.navigator.userAgent.toLowerCase().match(/MicroMessenger/i) == 'micromessenger' ? { pointerEvents: 'none' } : {}}>
                  {userInfo && userInfo.faceUrl ? (
                    <div style={{ background: `url(${userInfo.faceUrl}) center center / 100% no-repeat` }}></div>
                  ) : (
                    <div style={{ background: `url(${defaultFace}) center center / 100% no-repeat` }}></div>
                  )}
                  {/* <ImagePicker
                    onChange={this.faceSelected}
                    accept="image/gif,image/jpeg,image/jpg,image/png"
                  /> */}
                </div>
              </div>
            </Picker> :
            <div className="mineAvatar" onClick={() => this.getFaceUrl()}>
              <div className="mineAvatarTitle">上传人脸照片</div>
              <RightOutlined className="rightIcon" />
              <div className="mineAvatarContent" style={this.props.from !== FROM.H5 || window.navigator.userAgent.toLowerCase().match(/MicroMessenger/i) == 'micromessenger' ? { pointerEvents: 'none' } : {}}>
                {userInfo && userInfo.faceUrl ? (
                  <div style={{ background: `url(${userInfo.faceUrl}) center center / 100% no-repeat` }}></div>
                ) : (
                  <div style={{ background: `url(${defaultFace}) center center / 100% no-repeat` }}></div>
                )}
                <ImagePicker
                  onChange={this.faceSelected}
                  accept="image/gif,image/jpeg,image/jpg,image/png"
                />
              </div>
            </div>
          }
          {this.props.from != FROM.WXMINI ? (
            <div className="mineLogoutBtn" onTouchStart={this.logoutOnclick}>
              <div className="mineLogoutText">退出登录</div>
            </div>
          ) : null}
        </div>
      </div >
    );
  }

  logoutOnclick = () => {
    alert("退出提示", "您确定要退出该账号吗？", [
      { text: "确定", onPress: this.logoutConfirm },
      { text: "点错了", onPress: () => console.log("cancel") },
    ]);
  };

  logoutConfirm = () => {
    this.props.onSignout();
    this.props.history.replace("/accountLogin");
  };
  formatPhone(phone: string) {
    let phoneStr: string = "";
    for (let i = 0; i < phone.length; i++) {
      if (i > 2 && i < 7) {
        phoneStr = phoneStr + "*";
      } else {
        phoneStr = phoneStr + phone[i].toString();
      }
    }
    return phoneStr;
  }

  formatEmail(email: string) {
    let emailStr: string = "";
    let emailObj: string[] = email.split("@");
    if (emailObj[0].length > 7) {
      for (let i = 0; i < emailObj[0].length; i++) {
        if (i > 2 && i < emailObj[0].length - 4) {
          emailStr = emailStr + "*";
        } else {
          emailStr = emailStr + emailObj[0][i].toString();
        }
      }
      emailStr = emailStr + "@" + emailObj[1];
    } else {
      emailStr = email;
    }
    return emailStr;
  }

  imgSelected = async (files: any) => {
    let subImg = new FormData();
    subImg.append("file", files[0].file);
    let res = await postFile("/api/basis/uploadPhoto", subImg);
    this.changHeadImg(res);
  };
  faceSelected = async (files: any) => {
    let subImg = new FormData();
    subImg.append("file", files[0].file);
    let res = await postFile("/api/basis/uploadFacePhoto", subImg);
    this.changFaceImg(res);
  };
  async changHeadImg(res: string) {
    this.props.onLogin(
      { ...this.props.userInfo, avatar: res },
      localStorage.getItem("userToken") as string
    );
    post("/api/basis/editUserInfo", { ...this.props.userInfo, avatar: res });
    Toast.info("修改成功");
  }
  async changFaceImg(res: string) {
    this.props.onLogin(
      { ...this.props.userInfo, faceUrl: res },
      localStorage.getItem("userToken") as string
    );
    post("/api/basis/editUserInfo", { ...this.props.userInfo, faceUrl: res });
    Toast.info("修改成功");
    if ((this.props.match.params as { from?: string }).from === "toAI") {
      this.toAI();
    }
  }
  async toAI() {
    const that = this;
    let result = await get("/api/v1/faceRegister", {
      faceUrl: this.props.userInfo?.faceUrl,
      phone: this.props.userInfo?.phone,
    });
    if (result.code === 0) {
      await post("/api/basis/editUserInfo", {
        ...this.props.userInfo,
        trail: result.code,
      });
      this.props.onLogin(
        {
          ...this.props.userInfo,
          trail: result.code,
        },
        localStorage.getItem("userToken") as string
      );
      // this.props.history.push('/OutURL/' + encodeURIComponent('https://aiot.ucanuup.cn/mapp/aioth5/?parkCode=XZ_yby&phone=' + this.props.userInfo?.phone) + '/AI步道')
      this.props.history.push('/OutURL/' + encodeURIComponent('https://aiot.ucanuup.cn/mapp/aiotXzh5/?parkCode=XZ_yby&phone=' + this.props.userInfo?.phone) + '/AI步道' + '#/pages/ranking/index')

    } else {
      // setTimeout(function() {
      //     that.props.history.push('/userInfo');
      // },1000)
    }
  }
  setCameraOrPhotoHead(v: any) {
    this.setHead(v[0])
  }
  setCameraOrPhotoFace(v: any) {
    this.setFace(v[0])
  }
  getImage() {
    const that = this;
    window["appSetImagePath"] = function (url: string) {
      that.changHeadImg(url);
    };
    if (this.props.from === FROM.APP) {
      if (window["jsGetImage"]) {
        // window["jsGetImage"].postMessage(this.props.userToken);
        window['jsGetImage'].postMessage(JSON.stringify({
          type: 0,
          token: this.props.userToken,
        }));
      }
    } else {
      wx.chooseImage({
        count: 1, // 默认9
        sizeType: ['original', 'compressed'], // 可以指定是原图还是压缩图，默认二者都有
        sourceType: ['album', 'camera'], // 可以指定来源是相册还是相机，默认二者都有
        success: function (res: any) {
          wx.getLocalImgData({
            localId: res.localIds[0], // 图片的localID
            success: function (base64: any) {
              let ua = navigator.userAgent.toLowerCase();
              let u = navigator.userAgent;
              if (ua.indexOf('iphone') !== -1 || ua.indexOf('mac os') !== -1) {
                //苹果机
                that.imgSelected([{
                  file: that.dataURLtoBlob(base64.localData.replace('data:image/jgp;base64', 'data:image/jpeg;base64'))
                }])
              } else if (u.indexOf('Android') > -1 || u.indexOf('Linux') > -1) {
                //安卓机
                that.imgSelected([{
                  file: that.dataURLtoBlob('data:image/jpeg;base64,' + base64.localData)
                }])
              } else {
                //pc
                that.imgSelected([{
                  file: that.dataURLtoBlob(base64.localData)
                }]) // localData是图片的base64数据，可以用img标签显示
              }
            }
          });
        }
      });
    }
  }
  setHead(v: any) {
    const that = this;
    window["appSetImagePath"] = function (url: string) {
      that.changHeadImg(url);
    };
    if (this.props.from === FROM.APP) {
      if (window["jsGetImage"]) {
        // window["jsGetImage"].postMessage(this.props.userToken);
        window['jsGetImage'].postMessage(JSON.stringify({
          type: v,
          token: this.props.userToken,
        }));
      }
    }
  }
  dataURLtoBlob(dataurl: string) {
    var arr = dataurl.split(','),
      mime = (arr[0].match(/:(.*?);/) as RegExpMatchArray)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], `${Number(new Date())}.${mime.split('/')[1] === 'jpeg' ? 'jpg' : mime.split('/')[1]}`, { type: mime });
  }
  getFaceUrl() {
    const that = this;
    window["appSetImagePath"] = function (url: string) {
      console.log(url);
      that.changFaceImg(url);
    };
    if (this.props.from === FROM.APP) {
      if (window["jsGetFace"]) {
        window["jsGetFace"].postMessage(JSON.stringify({
          type: 1,
          token: this.props.userToken,
        }));
      }
    } else {
      wx.chooseImage({
        count: 1, // 默认9
        sizeType: ['original', 'compressed'], // 可以指定是原图还是压缩图，默认二者都有
        sourceType: ['album', 'camera'], // 可以指定来源是相册还是相机，默认二者都有
        success: function (res: any) {
          wx.getLocalImgData({
            localId: res.localIds[0], // 图片的localID
            success: function (base64: any) {
              let ua = navigator.userAgent.toLowerCase();
              let u = navigator.userAgent;
              if (ua.indexOf('iphone') !== -1 || ua.indexOf('mac os') !== -1) {
                //苹果机
                that.faceSelected([{
                  file: that.dataURLtoBlob(base64.localData.replace('data:image/jgp;base64', 'data:image/jpeg;base64'))
                }])
              } else if (u.indexOf('Android') > -1 || u.indexOf('Linux') > -1) {
                //安卓机
                that.faceSelected([{
                  file: that.dataURLtoBlob('data:image/jpeg;base64,' + base64.localData)
                }])
              } else {
                //pc
                that.faceSelected([{
                  file: that.dataURLtoBlob(base64.localData)
                }]) // localData是图片的base64数据，可以用img标签显示
              }
            }
          });
        }
      });
    }
  }
  setFace(v: any) {
    const that = this;
    window["appSetImagePath"] = function (url: string) {
      console.log(url);
      that.changFaceImg(url);
    };
    if (this.props.from === FROM.APP) {
      if (window["jsGetFace"]) {
        // window["jsGetFace"].postMessage(this.props.userToken);
        window["jsGetFace"].postMessage(JSON.stringify({
          type: v,
          token: this.props.userToken,
        }));
      }
    } else {
      wx.chooseImage({
        count: 1, // 默认9
        sizeType: ['original', 'compressed'], // 可以指定是原图还是压缩图，默认二者都有
        sourceType: ['album', 'camera'], // 可以指定来源是相册还是相机，默认二者都有
        success: function (res: any) {
          wx.getLocalImgData({
            localId: res.localIds[0], // 图片的localID
            success: function (base64: any) {
              let ua = navigator.userAgent.toLowerCase();
              let u = navigator.userAgent;
              if (ua.indexOf('iphone') !== -1 || ua.indexOf('mac os') !== -1) {
                //苹果机
                that.faceSelected([{
                  file: that.dataURLtoBlob(base64.localData.replace('data:image/jgp;base64', 'data:image/jpeg;base64'))
                }])
              } else if (u.indexOf('Android') > -1 || u.indexOf('Linux') > -1) {
                //安卓机
                that.faceSelected([{
                  file: that.dataURLtoBlob('data:image/jpeg;base64,' + base64.localData)
                }])
              } else {
                //pc
                that.faceSelected([{
                  file: that.dataURLtoBlob(base64.localData)
                }]) // localData是图片的base64数据，可以用img标签显示
              }
            }
          });
        }
      });
    }
  }
}

declare var window: {
  [key: string]: any; // missing index defintion
  prototype: Window;
  new(): Window;
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter<any, ComponentClass<IProps>>(UserInfo));
