import React, { FunctionComponent } from "react";
import { Component, ComponentClass } from "react";
import { RouterProps, withRouter } from "react-router-dom";
import ContentPage from "../../components/ContentPage";
import style from './index.module.css';
import background from '../../asset/web.png'

interface IProps extends RouterProps{
    goItem?: Function
    dataList: content[],
}
export interface IContentListProps {
  goItem?: Function
  dataList: content[],
}
export interface content {
    id: number,
    title: string,
    content: string,
    imgUrl: string,
    createTime: string,
    pathUrl?: string
}
const ContentList:React.FC<IProps> = (props: IProps) => {
  if(props.dataList && props.dataList.length > 1) {
    return (
      <div className={style.contentList}>
          <div className={style.newsWrap} >
          {props.dataList.map((item, index) =>{
              return (
                  <div className={style.itemContainer} onClick={()=> {
                      if(props.goItem) {
                        props.goItem(item.id)
                      }
                    }} key={index}>
                      {
                        item.imgUrl !== null ?<div className={style.itemImg} style={{background: "url('" + item.imgUrl + "') no-repeat", backgroundPosition: "center",backgroundSize: "auto 100%"}}></div>: null
                      }
                      <div className={style.itemTitle} style={item.imgUrl !== null?{}:{width: '100%'}}>{item.title}</div>
                      <div className={style.itemCreatTime} style={item.imgUrl !== null?{}:{width: '100%'}}>{item.createTime}</div>
                      {/* 取出文本编辑器中的全部文字,如果没有显示title */}
                      <div className={style.itemContent} style={item.imgUrl !== null?{}:{width: '100%'}}>{item.content? (item.content.replace('\\', '').split('>').map(text => (text.substr(0, 1) === "<"? null: text.split('<')[0])) === null? item.title: item.content.replace('\\', '').split('>').map(text => (text.substr(0, 1) === "<"? null: text.split('<')[0]))): null}</div>
                  </div>
              )
          })}
          </div>
      </div>
    )
  } else if(props.dataList && props.dataList.length === 1) {
    return (<ContentPage getData={()=>{return {content: props.dataList[0].content, path: props.dataList[0].pathUrl}}}/>)
  } else {
    return (<img width='100%' height='100%' src={background}/>)
  }
}

export default withRouter<any, FunctionComponent<IProps>>(ContentList);