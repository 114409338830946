import React, { ComponentClass } from "react";
import { PageHeader, Button, Avatar, message } from "antd";
import { Modal } from "antd-mobile";
import counselBackground from "../../asset/counsel/counselBackground.png";
import style from "./index.module.css";
import { LoginInfo, UserInfo } from "../../store/type";
import { Dispatch } from "redux";
import { login } from "../../store/actions";
import { connect } from "react-redux";
import face0 from "../../asset/DefaultFace/face0.png";
import face1 from "../../asset/DefaultFace/face1.png";
import face2 from "../../asset/DefaultFace/face2.png";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { get, postData } from "../../axios/axios";
interface IProps extends RouteComponentProps {
  onLogin: (userInfo: UserInfo, userToken: string) => {};
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onLogin: (userInfo: UserInfo, userToken: string) =>
    dispatch(login(userInfo, userToken)),
});

interface IState {}
const alert = Modal.alert;

const mapStateToProps = (
  state: LoginInfo
): {
  userInfo?: UserInfo;
} => ({
  userInfo: state.userInfo,
});
const faceList = [face0, face1, face2];
class MineRedirect extends React.PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.login();
  }
  async login() {
    let param = this.props.match.params as { token?: string };
    localStorage.setItem("userToken", param.token ? param.token : "");
    let userInfo = await get("/api/user/getUserInfoByToken");
    if (userInfo) {
      this.props.onLogin(userInfo.data, param.token ? param.token : "");
      this.props.history.push("/main/mine");
    }
  }
  render() {
    // const alert = Modal.alert
    return (
      <div className={style.Counsel}>
        {/* <div
          className={style.top}
          style={{
            background: "url(" + counselBackground + ") no-repeat",
            backgroundSize: "100%",
          }}
        ></div>
        <div className={style.body}>
          <div className={style.headImage}>
            <img src={face0} alt="" />
          </div>
          <div className={style.userName}>游客</div>
        </div> */}
      </div>
    );
  }
}

export default connect(
  null,
  mapDispatchToProps
)(withRouter<any, ComponentClass<IProps>>(MineRedirect));
