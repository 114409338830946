import React, { ComponentClass } from 'react'
import { RouterProps, withRouter } from 'react-router-dom';
import style from './index.module.css';

interface IContentPageProps {
    getData: Function
}
interface IProps {
    getData: Function
}
interface IState {
    content: string,
    path: string
}
var questBlock = false;
class ContentPage extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            content: '<div></div>',
            path: ''
        }
        this.getData();
    }
    async getData() {
        let a = await this.props.getData();
        questBlock = false;
        this.setState({
            content: a.content,
            path: a.path
        });
    }
    // componentWillUpdate(props:IProps) {
    //   if(props.history.location.pathname !== this.state.path && !questBlock){
    //     questBlock = true;
    //     this.setState({
    //       path: props.history.location.pathname
    //     },() => this.getData());
    //   }
    // }
    refresh(content: string) {
        this.setState({
            content: content
        })
    }
    render() {
        const {path} = this.state;
        if(path) {
            return (<div className={style.radio}><video src={path} width="100%" controls></video></div>)
        } else {
            return (<div className={style.contentPage} style={{float: 'left',textAlign: 'left'}} dangerouslySetInnerHTML={{__html: this.state.content}}></div>)
        }
    }
}

export default ContentPage;