import { Toast } from 'antd-mobile';
import React, { ComponentClass } from 'react';
import { connect } from 'react-redux';
import { RouterProps, withRouter } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';
import { get } from '../../axios/axios';
import { FROM, LOGINSTATE } from '../../store/enmu';
import { LoginInfo, UserInfo } from '../../store/type';
// import { InvoiceForm } from '../Invoice';

interface PluArray {
    FLAGS: string;
    IDENTITY_NO: string;
    MOBILE_NO: string;
    NAME: string;
    ORDER_NO: string;
    PERSON_COUNT: string;
    SEAT_INFO: string;
    STYLE_NAME: string;
    TICKET_BALANCE: string;
    TICKET_NO: string;
    TICKET_USE_TIME: string;
    TPRICE: string;
}

interface IProps extends RouteComponentProps, LoginInfo { }

interface InvoiceGood {
    index: string,
    attribute: string,
    discountIndex: string,
    name: string,
    sn: string,
    taxRate: string,
    priceIncludingTax: string,
    quantity: string,
    unit: string,
    model: string,
    unitPriceIncludingTax: string,
}

interface InvoiceData {
    buyerTaxCode: string;
    invoiceType: string,
    buyerName: string,
    buyerAddress: string,
    buyerTelephone: string,
    buyerBank: string,
    buyerAccount: string,
    remark: string,
    branName: string
    amount: string,
    merOrderId: string,
    goodsDetail: InvoiceGood[],
    merOrderDate: string,
}

declare var window: {
    [key: string]: any; // missing index defintion
    prototype: Window;
    new(): Window;
};

class TicketReplace extends React.Component<IProps>{
    constructor(props: IProps) {
        super(props);
        const order = (props.match.params as { orderId?: string, callback?: string, redirect?: string })
        // Toast.info(order, 3)
        // Toast.info(this.props.from, 3)
        if (order.orderId && order.callback) {
            // if(props.from === FROM.H5) {
            //     localStorage.setItem('ticketCallback', order.callback);
            // }
            //app中重定向
            // Toast.info(window.parent.location.hash)

            if (this.props.from === FROM.APP && window.parent['goReplace'] && window.parent.location.hash.split('/')[1] !== 'ticketreplace') {
                // Toast.info(111, 3)
                window.parent['goReplace'](order.orderId, order.callback);
            } else {
                // Toast.info(222, 3)
                this.getInvoiceDate(order.orderId);
            }
        } else if (this.props.from === FROM.APP && order.redirect) {
            // app调用的支付成功之后的跳转
            // Toast.info(333, 3)
            window.parent['ticketRedirect'](order.redirect);
        }
    }

    async getInvoiceDate(orderNo: string) {
        let res = await get('/api/user/checkInvoice', {
            merOrderId: orderNo
        })
        if (res.code === 400) {
            let orderRes = await get('/api/user/getOrderInfoByOrderNo', { orderNo: orderNo });
            if (orderRes.code === 200) {
                console.log(orderRes)
                var amount = 0
                orderRes.data.forEach((payInfo: PluArray, index: number) => {
                    if (Number(payInfo.FLAGS) != 40) {
                        amount += Number(payInfo.TICKET_BALANCE) * 100
                    }
                })
                amount /= 100
                var amountTax = 0
                orderRes.data.forEach((payInfo: PluArray, index: number) => {
                    if (Number(payInfo.FLAGS) != 40) {
                        console.log(Number(payInfo.TICKET_BALANCE))
                        amountTax += Number(payInfo.TICKET_BALANCE)
                    }
                })

                // 
                //'2022/3/1 15:21:00'
                var dateTime = new Date(orderRes.data[0].TICKET_USE_TIME);
                console.log(dateTime)
                let year = dateTime.getFullYear()
                let month = (dateTime.getMonth() + 1).toString().padStart(2, '0')
                let day = (dateTime.getDate()).toString().padStart(2, '0')
                console.log(year + month + day)
                var invoiceData: InvoiceData = {
                    buyerTaxCode: '',
                    branName: '门票',
                    amount: amount.toFixed(2),
                    merOrderId: orderRes.data[0].ORDER_NO,
                    invoiceType: '1',
                    buyerName: '',
                    buyerAddress: '',
                    buyerTelephone: '',
                    buyerBank: '',
                    buyerAccount: '',
                    remark: '',
                    merOrderDate: year + month + day,
                    goodsDetail: [{
                        index: '1',
                        attribute: "0",
                        discountIndex: "",
                        name: '景区门票',
                        // sn: "1090252010000000000",
                        sn: "3070301000000000000",
                        taxRate: "6",
                        // unitPriceIncludingTax: String(orderRes.data[0].TPRICE),
                        unitPriceIncludingTax: '',
                        priceIncludingTax: String(amountTax),
                        // priceIncludingTax: String(orderRes.data[0].TPRICE * orderRes.data.length),
                        quantity: String(orderRes.data.length),
                        unit: "",
                        model: ""
                    }]
                    // goodsDetail: orderRes.data.map((payInfo: PluArray, index: number) => ({
                    //     index: (index + 1).toFixed(0),
                    //     attribute: "0",
                    //     discountIndex: "",
                    //     name: '景区门票',
                    //     // sn: "1090252010000000000",
                    //     sn: "3070301000000000000",
                    //     taxRate: "6",
                    //     priceIncludingTax: payInfo.TPRICE,
                    //     quantity: "1",
                    //     unit: "",
                    //     model: ""
                    // }))
                }
                localStorage.setItem('orderDetail', JSON.stringify(invoiceData))
            }
        } else if (res.code === 200) {
            if (this.props.from === FROM.APP) {
                let props = this.props;
                Toast.info('即将跳转到浏览器查看发票', 3)
                setTimeout(() => {
                    props.history.go(-1);
                    window['jsKaiFaPiao'].postMessage(res.data.qrCode);
                }, 3000)
                return;
            } else {
                window.location.replace(res.data.qrCode);
            }
            // window.location.replace(res.data.pdfPreviewUrl);
            // window['jsKaiFaPiao'].postMessage(res.data.pdfPreviewUrl);
            // this.props.history.push(`/OutURL/${encodeURIComponent(res.data.pdfPreviewUrl)}/开具发票`);
        } else {
            return;
        }
        // Toast.info(this.props.from, 3)
        if (this.props.from === FROM.APP && window.parent && window.parent['getOrder']) {
            // 票务跳转入口
            // Toast.info(444, 3)
            window.parent['getOrder'](orderNo);
        } else {
            // Toast.info(555, 3)
            // 链接开发票入口
            //1是显示 2是不显示
            this.props.history.replace(`/invoice/${orderNo}/ticket?titleVisible=2`);
        }
    }

    render() {
        return (
            <div></div>
        );
    }
}

const mapStateToProps = (
    state: LoginInfo
): {
    userInfo?: UserInfo;
    state?: LOGINSTATE;
    from: FROM;
    padding?: string;
} => ({
    userInfo: state.userInfo,
    state: state.state,
    from: state.from as FROM,
    padding: state.padding
});

export default connect(mapStateToProps)(withRouter<any, ComponentClass<IProps>>(TicketReplace));