import { Icon, Modal, NavBar } from "antd-mobile";
import React, { ComponentClass } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { LoginInfo, UserInfo } from "../../store/type";
import "./index.css";
import { all, get, getQuest,postQuest } from "../../axios/axios";
import { FROM } from "../../store/enmu";
import { content } from "../ContentList";
import { ymd } from "../../components/ymd";
const alert = Modal.alert;
interface IProps extends LoginInfo, RouteComponentProps {}
interface AllContent extends content {
  type: string
}
interface IState {
  searchValue: string;
  historyArray: any;
  dataList: AllContent[];
}
const mapStateToProps = (
  state: LoginInfo
): {
  userInfo?: UserInfo;
  from: FROM | null;
  padding?: string;
} => ({
  userInfo: state.userInfo,
  from: state.from,
  padding: state.padding,
});

class SearchPage extends React.PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      searchValue: "",
      historyArray: [],
      dataList: []
    };
  }
  componentDidMount() {
    let history = localStorage.getItem("searchHistory");
    let array = history?.split(",");
    if (history) {
      this.setState({
        historyArray: array,
      });
    }
  }
  render() {
    const { historyArray, searchValue, dataList } = this.state;
    return (
      <div className="SearchPage">
        <div
          style={
            this.props.padding
              ? { marginTop: JSON.parse(this.props.padding).top + "px", float: 'left',width: '100%'}
              : {float: 'left',width: '100%'}
          }
        >
          <NavBar
            className="navBar"
            icon={
              this.props.from != FROM.WXMINI?<Icon type="left" onClick={() => this.props.history.goBack()} />:null
            }
            rightContent={<span onClick={this.searchKey}>搜索</span>}
          >
            <Icon type="search" size={"xs"} className="searchIcon" />
            <input
              type="text"
              placeholder="请输入..."
              value = {searchValue}
              onChange={(e) => {
                this.setState({
                  searchValue: e.target.value,
                });
              }}
            />
          </NavBar>
        </div>
        <div className="mainBox">
          <div className="topDiv">
            <span>搜索历史</span>
            <span onClick={this.showNotice}>清空</span>
          </div>
          <div className="historyBox">
            {historyArray
              ? historyArray.map((item: any) => {
                  return <div className="historyItem" onClick={() => this.setState({
                    searchValue: item
                  })}>{item}</div>;
                }).splice(0,5)
              : null}
          </div>
          <div className='newsWrap' >
          {dataList.map((item: AllContent, index: number) =>{
              return (
                  <div className='itemContainer' onClick={()=> this.props.history.push(item.type + item.id)} key={index}>
                      {
                        item.imgUrl !== null ?<img className='itemImg' src={item.imgUrl}/>: null
                      }
                      <div className='itemTitle' style={item.imgUrl !== null?{}:{width: '100%'}}>{item.title}</div>
                      <div className='itemCreatTime' style={item.imgUrl !== null?{}:{width: '100%'}}>{item.createTime}</div>
                      {/* 取出文本编辑器中的全部文字,如果没有显示title */}
                      <div className='itemContent' style={item.imgUrl !== null?{}:{width: '100%'}}>{item.content? (item.content.replace('\\', '').split('>').map(text => (text.substr(0, 1) === "<"? null: text.split('<')[0])) === null? item.title: item.content.replace('\\', '').split('>').map(text => (text.substr(0, 1) === "<"? null: text.split('<')[0]))): null}</div>
                  </div>
              )
          })}
          </div>
        </div>
      </div>
    );
  }
  showNotice = () => {
    alert("确认删除所有历史记录？", "", [
      {
        text: "取消",
        onPress: () => console.log("cancel"),
        style: "default",
      },
      { text: "确认", onPress: this.clearHistory, style: { color: "#048779" } },
    ]);
  };

  //数组去重
  uniq(array: any) {
    var temp = []; //一个新的临时数组
    for (var i = 0; i < array.length; i++) {
      if (temp.indexOf(array[i]) === -1) {
        temp.push(array[i]);
      }
    }
    return temp;
  }

  searchKey = async () => {
    let key = this.state.searchValue;
    if(key === '') {
      return ;
    }
    let array = [...this.state.historyArray];
    array.unshift(key);
    let result = this.uniq(array);
    this.setState({
      historyArray: result,
    });
    localStorage.setItem("searchHistory", result.toString());
    let [news, announcement, party, event, impression, honor, memorabilia, promo, plan, notice, line] = await all([
      postQuest('/api/news/list', {
        value: key,
        reviewStatus: 3,
        dispalyStatus: 1
      }),
      postQuest('/api/announcement/list', {
        value: key,
        reviewStatus: 3,
        dispalyStatus: 1
      }),
      postQuest('/api/party/list', {
        value: key,
        reviewStatus: 3,
        dispalyStatus: 1
      }),
      postQuest('/api/event/list', {
        value: key,
        reviewStatus: 3,
        dispalyStatus: 1
      }),
      postQuest('/api/impression/list', {
        value: key,
        reviewStatus: 3,
        dispalyStatus: 1
      }),
      postQuest('/api/honor/list', {
        value: key,
        reviewStatus: 3,
        dispalyStatus: 1
      }),
      postQuest('/api/memorabilia/list', {
        value: key,
        reviewStatus: 3,
        dispalyStatus: 1
      }),
      postQuest('/api/promo/list', {
        value: key,
        reviewStatus: 3,
        dispalyStatus: 1
      }),
      postQuest('/api/plan/list', {
        value: key,
        reviewStatus: 3,
        dispalyStatus: 1
      }),
      getQuest('/api/notice/list', {
        value: key,
        reviewStatus: 3,
        dispalyStatus: 1
      }),
      postQuest('/api/line/list', {
        value: key,
        reviewStatus: 3,
        dispalyStatus: 1
      })
    ])
    var dataList: AllContent[] = []
    if(news.code === 200) {
      news.data.forEach((element: any) => {
        dataList.push({
          type: '/detail/information/news/',
          id: element.newId,
          title: element.newTitle,
          content: element.newContent,
          imgUrl: element.newThumbnail,
          createTime: ymd(new Date(element.createtime)),
        });
      });
    }
    if(announcement.code === 200) {
      announcement.data.forEach((element: any) => {
        dataList.push({
          type: '/detail/information/announcement/',
          id: element.announcementId,
          title: element.announcementTitle,
          content: element.announcementContent,
          imgUrl: element.announcementThumbnail,
          createTime: ymd(new Date(element.createtime)),
        });
      });
    }
    if(party.code === 200) {
      party.data.forEach((element: any) => {
        dataList.push({
          type: '/detail/information/party/',
          id: element.partyId,
          title: element.partyTitle,
          content: element.partyContent,
          imgUrl: element.partyThumbnail,
          createTime: ymd(new Date(element.createtime)),
        });
      });
    }
    if(event.code === 200) {
      event.data.forEach((element: any) => {
        dataList.push({
          type: '/detail/information/event/',
          id: element.activityId,
          title: element.activityTitle,
          content: element.activityContent,
          imgUrl: element.activityThumbnail,
          createTime: ymd(new Date(element.createtime)),
        });
      });
    }
    if(impression.code === 200) {
      impression.data.forEach((element: any) => {
        dataList.push({
          type: '/detail/information/impression/',
          id: element.impressionId,
          title: element.impressionTitle,
          content: element.impressionContent,
          imgUrl: element.impressionThumbnail,
          createTime: ymd(new Date(element.createtime)),
        });
      });
    }
    if(honor.code === 200) {
      honor.data.forEach((element: any) => {
        dataList.push({
          type: '/detail/about/honor/',
          id: element.gloryId,
          title: element.gloryTitle,
          content: element.gloryContent,
          imgUrl: element.gloryThumbnail,
          createTime: ymd(new Date(element.createtime))
        });
      });
    }
    if(memorabilia.code === 200) {
      memorabilia.data.forEach((element: any) => {
        dataList.push({
          type: '/detail/about/memorabilia/',
          id: element.memorabiliaId,
          title: element.memorabiliaTitle,
          content: element.memorabiliaContent,
          imgUrl: element.memorabiliaThumbnail,
          createTime: ymd(new Date(element.createtime))
        });
      });
    }
    if(promo.code === 200) {
      promo.data.forEach((element: any) => {
        dataList.push({
          type: '/detail/about/promo/',
          id: element.promoId,
          title: element.promoTitle,
          content: element.promoContent,
          imgUrl: element.promoThumbnail,
          createTime: ymd(new Date(element.createtime))
        });
      });
    }
    if(plan.code === 200) {
      plan.data.forEach((element: any) => {
        dataList.push({
          type: '/detail/about/plan/',
          id: element.planningId,
          title: element.planningTitle,
          content: element.planningContent,
          imgUrl: element.planningThumbnail,
          createTime: ymd(new Date(element.createtime))
        });
      });
    }
    if(notice.code === 200) {
      notice.data.forEach((element: any) => {
        dataList.push({
          type: '/detail/touristNotice/notice/',
          id: element.noticeId,
          title: element.noticeTitle,
          content: element.noticeContent,
          imgUrl: element.noticeThumbnail,
          createTime: ymd(new Date(element.createtime))
        });
      });
    }
    if(line.code === 200) {
      line.data.forEach((element: any) => {
        dataList.push({
          type: '/detail/touristNotice/line/',
          id: element.lineId,
          title: element.lineTitle,
          content: element.lineContent,
          imgUrl: element.lineThumbnail,
          createTime: ymd(new Date(element.createtime))
        });
      });
    }
    this.setState({
      dataList: dataList
    })
  };

  clearHistory = () => {
    localStorage.removeItem("searchHistory");
    this.setState({
      historyArray: [],
    });
  };
}

export default connect(mapStateToProps)(
  withRouter<any, ComponentClass<IProps, IState>>(SearchPage)
);
