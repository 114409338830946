import { Icon, Modal, Tabs, Toast } from 'antd-mobile';
import React from 'react';
import { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { RouterProps, withRouter } from 'react-router';
import { get } from '../../axios/axios';
import useMounted from '../../components/hooks/useMounted';
import { FROM } from '../../store/enmu';
import { LoginInfo, UserInfo } from '../../store/type';
import style from './index.module.css';
import background from "../../asset/Gis/background.png";
import defaultCover from "../../asset/Gis/defaultCover.png";
import point from '../../asset/Gis/comment.png';
import './index.css'
import wx from 'weixin-js-sdk';
import { AimOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Carousel, Drawer, message } from 'antd';
import gaodeapp from '../../asset/Gis/gaodeapp.png';
import baiduapp from '../../asset/Gis/baiduapp.png';
import txapp from '../../asset/Gis/txapp.jpg';
import menRoom from "../../asset/Gis/menRoom.png";
import womenRoom from "../../asset/Gis/womenRoom.png";
import thirdRoom from "../../asset/Gis/thirdRoom.png";

const mapKey = "62b9c2478cdeb4eea95f88dd938b4691";
const mapStateToProps = (
  state: LoginInfo
): {
  userInfo?: UserInfo;
  from: FROM | null;
  padding?: string;
  userToken: string;
  appid: string;
} => ({
  userInfo: state.userInfo,
  from: state.from,
  padding: state.padding,
  userToken: state.userToken as string,
  appid: state.appid
});

const mapRef = React.createRef<HTMLDivElement>();
const audio = React.createRef<HTMLAudioElement>();
const scorllDiv = React.createRef<HTMLDivElement>();
interface GisProps extends LoginInfo, RouterProps { }
interface IResourceType {
  gisResourceTypeId: number
  icon: string
  icon1: string
  remark: string
  resourceTypeName: string
}
interface IScenicData {
  attribute: number
  coordinate: string
  gisDescrition: string
  gisId: number
  gisResourceTypeId: number
  gisTitle: string
  icon: string
  parkId: string
  pathUrl: number
  uploadIds: string
  zoom?: number
  content: string
}
interface Park {
  itemId: string;
  parkCode: string;
  parkName: string;
  remainBookSpace: number;
  remainSpace: number;
  theId: number;
  totalBookSpace: number;
  totalSpace: number;
  male: number;
  female: number;
  third: number;
}
interface Video {
  explainThumbnail: string;
  explainTitle: string;
  explainId: number;
}
declare var window: {
  [key: string]: any; // missing index defintion
  prototype: Window;
  new(): Window;
};
const alert = Modal.alert;
let showAlert = true;
let markerList: any[] = [];
let interval: NodeJS.Timeout;
const Gis: React.FC<GisProps> = (props: GisProps) => {
  const [openMenu, setOpenMenu] = useState(false); //是否展开按钮
  const [gisTypeId, setGisTypeId] = useState(-1); //地图的选中类型id
  // const [currentTab, setCurrentTab] = useState(''); //选中的类型名
  const [typeData, setTypeData] = useState<IResourceType[]>([]);//地图全部类型
  const [scenicData, setScenicData] = useState<IScenicData[]>([]); //地图显示标点
  const [allScenicData, setAllScenicData] = useState<IScenicData[]>([]); //地图全部标点
  const [showScenic, setShowScenic] = useState<IScenicData>();
  const [map, setMap] = useState({} as any);
  const [center, setCenter] = useState([117.48554, 34.152405]);
  const [currentParkAnalysis, setCurrentParkAnalysis] = useState<Park[]>([]);
  const [currentToiletAnalysis, setCurrentToiletAnalysis] = useState<Park[]>([]);
  const [position, setPosition] = useState<{ Q?: number, R?: number }>({ Q: 0, R: 0 });
  const [myPostionMark, setMyPostionMark] = useState<any>();
  const [walking, setWalking] = useState<any>(false);
  const [showLine, setShowLine] = useState<boolean>(false);
  const [chooseApp, setChooseApp] = useState<boolean>(false);
  const [detailModal, setDetailModal] = useState<boolean>(false);
  const [introModal, setIntroModal] = useState<boolean>(false);
  const [currentImagePage, setCurrentImagePage] = useState<number>(1);
  const [vedioList, setVedioList] = useState<Video[]>([]);
  const [showScenicLoading, setShowScenicLoading] = useState<boolean>();

  //首次渲染获取类型列表
  useEffect(() => {
    getTypeList();
    getAllScenicData();
    getParkData();
    getToiletData();
    getVedio();
    if (window.navigator.userAgent.toLowerCase().match(/MicroMessenger/i) == 'micromessenger') {
      // 微信中获取定位
      wxWapLogin();
    } else if (props.from === FROM.APP) {
      getAppLocation();
    } else {
    }
    window['gotoBack'] = function () {
      audio.current?.pause();
    }
    window['gotoFront'] = function () {
      getAppLocation();
    }
  }, []);

  useMounted(() => {
    window['gotoBack'] = function () {
      audio.current?.pause();
    }
    let imageLayer = new AMap.ImageLayer({
      bounds: new AMap.Bounds([117.471417, 34.146435], [117.498947, 34.165186]),
      url: background, // 图片 Url
      zIndex: 5,
      zooms: [0, 99] // 设置可见级别，[最小级别，最大级别]
    });
    let map = new AMap.Map(mapRef.current, {
      zoom: 16,  //设置地图显示的缩放级别
      center: center,//设置地图中心点坐标
      layers: [imageLayer, new AMap.TileLayer()],  //设置图层,可设置成包含一个或多个图层的数组
      mapStyle: 'amap://styles/normal',  //设置地图的显示样式
      viewMode: '2D',  //设置地图模式
    });

    AMap.plugin('AMap.Geolocation', function () {
      var geolocation = new AMap.Geolocation({
        // 是否使用高精度定位，默认：true
        enableHighAccuracy: true,
        // 定位按钮的停靠位置的偏移量，默认：Pixel(10, 20)
        buttonOffset: new AMap.Pixel(10, 20),
        //  定位成功后调整地图视野范围使定位位置及精度范围视野内可见，默认：false
        zoomToAccuracy: true,
        noIpLocate: 3
      })
      geolocation.getCurrentPosition(function (status: string, result: any) {
        if (status == 'complete') {
          onComplete(result)
        } else {
          onError(result)
        }
      });
      function onComplete(data: any) {
        console.log(data)
        setPosition({ Q: data.position.lng, R: data.position.lat })
        // data是具体的定位信息
      }

      function onError(data: any) {
        // 定位出错
      }
    })
    setMap(map);
  });

  useEffect(() => {
    map.setCenter && map.setCenter(center, false, 50)
  }, [center]);

  const wxWapLogin = useCallback(async () => {
    // clearInterval(interval);
    let res = await get('/api/v1/getTicketSign', { url: encodeURIComponent(window.location.href.split('#')[0]) })
    if (res.code === 200) {
      wx.config({
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: props.appid, // 必填，公众号的唯一标识
        timestamp: res.data.timestamp, // 必填，生成签名的时间戳
        nonceStr: res.data.noncestr, // 必填，生成签名的随机串
        signature: res.data.signature,// 必填，签名
        jsApiList: [
          'getLocation',
          'openLocation'
        ] // 必填，需要使用的JS接口列表
      })
      wx.ready(() => {
        interval = setInterval(() => {
          wx.getLocation({
            // type: 'wgs84', // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
            type: 'gcj02', // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
            success: function (res: any) {
              setPosition({
                Q: res.latitude,
                R: res.longitude
              })
            }
          });
          if (mapRef.current === null) {
            clearInterval(interval as NodeJS.Timeout)
          }
        }, 1000);
      })
    }
  }, []);

  const getAppLocation = useCallback(() => {
    // clearInterval(interval);
    window['setLocation'] = (locationJSON: string) => {
      console.log(locationJSON)
      // alert(locationJSON, "", [
      //   {
      //     text: "确定",
      //     onPress: () => {
      //       showAlert = false;
      //     }
      //   },
      //   {
      //     text: "取消", onPress: () => {
      //       showAlert = true;
      //     }
      //   },
      // ]);
      try {
        if (JSON.parse(locationJSON).errorCode === 12 && mapRef.current !== null) {
          if (showAlert) {
            alert("app请求获取定位权限", "", [
              {
                text: "确定",
                onPress: () => {
                  showAlert = false;
                  window['jsSetPermissions'].postMessage('');
                }
              },
              {
                text: "取消", onPress: () => {
                  clearTimeout(interval as NodeJS.Timeout)
                  showAlert = true;
                }
              },
            ]);
          }
        } else {
          let location = JSON.parse(locationJSON);
          setPosition({
            Q: location.latitude,
            R: location.longitude
          });
          interval = setTimeout(() => {
            window['jsSendDirect'].postMessage('');
          }, 1000);
          if (mapRef.current === null) {
            clearTimeout(interval as NodeJS.Timeout)
            showAlert = true;
          }
        }
      } catch (e) {

      }
    }
    window['jsSendDirect'].postMessage('');
  }, [])

  // 选中类型改变或这标点数据改变时触发，刷新当前显示的标点
  useEffect(() => {
    let array: IScenicData[] = [];
    allScenicData.map((item: IScenicData) => {
      if (item.gisResourceTypeId === gisTypeId) {
        array.push(item);
      }
    });
    setShowScenic(undefined);
    setScenicData(array);
  }, [allScenicData, gisTypeId])

  useEffect(() => {
    let iconImg = typeData.find((type: IResourceType) => (type.gisResourceTypeId === gisTypeId))?.icon1
    let pos_icon = new AMap.Icon({
      size: new AMap.Size(50, 36),// 图标尺寸
      image: point,// 图标的取图地址
      imageSize: new AMap.Size(25, 36),// 图标所用图片大小 
    });
    markerList.forEach((marker) => {
      map.remove && map.remove(marker);
    });
    markerList = [];
    console.log(scenicData)
    scenicData.forEach((scenic: IScenicData) => {
      let marker = new AMap.Marker({
        icon: pos_icon, // 自定义点标记
        position: scenic.coordinate.split(','), // 基点位置
        offset: new AMap.Pixel(-36, -36), //设置偏移量
        zooms: [(scenic.zoom ? scenic.zoom : 0), 99],
        label: {
          content: `<img style="border-radius: 50%" width="100%" height="100%" src='${iconImg}'/>`,
          offset: new AMap.Pixel(-26, -5),
        },
      });
      markerList.push(marker);
      marker.on('click', () => {
        console.log(scenic)
        setShowScenic(scenic);
        setShowScenicLoading(true);
        setIntroModal(true);
      })
      let touching = false;
      marker.on('touchstart', () => {
        touching = true;
        setTimeout(() => {
          touching = false;
        }, 500)
      })
      marker.on('touchend', () => {
        if (touching) {
          setShowScenic(scenic);
          setShowScenicLoading(true);
          setIntroModal(true);
        }
      })
      map.on('resize', () => {
        touching = false
      })
      map.add(marker);
    })
  }, [scenicData])

  useEffect(() => {
    getShowSenic();
  }, [showScenic, showScenicLoading])

  let getShowSenic = useCallback(async () => {
    if (showScenicLoading) {
      let detail = await get('/api/gis/sourcedetails', {
        gisId: showScenic?.gisId
      })
      if (detail.code === 200) {
        setShowScenicLoading(false);
        setShowScenic({ ...showScenic, content: detail.data.gis.gisDescrition } as IScenicData)
      }
    }
  }, [showScenic, showScenicLoading])
  /**
   * 刷新个人位标
  */
  useEffect(() => {
    if (map.remove && myPostionMark) {
      map.remove(myPostionMark);
    }
    let marker = new AMap.Marker({
      position: [position.R, position.Q],
      anchor: 'center',
      size: new AMap.Size(10, 10),// 图标尺寸
      content: `<div class="circle"></div>`,
    });
    setMyPostionMark(marker);
    map.add && map.add(marker);
  }, [position, map])
  /**
   * typeData,gisTypeId更新时获取tabs的脚标
   * */
  const getTabChoose = useCallback(() => {
    return typeData.map((type: IResourceType, index: number) => {
      return { ...type, index }
    }).find((type: IResourceType, index: number) => {
      if (type.gisResourceTypeId === gisTypeId) {
        return index
      }
    })?.index || -1
  }, [typeData, gisTypeId])

  const tabChoose = useMemo(() => getTabChoose(), [typeData, gisTypeId])

  const tabs = useMemo(() => <Tabs
    tabs={typeData.map((item: IResourceType) => {
      return {
        title: item.resourceTypeName,
        path: item.icon,
        gisResourceTypeId: item.gisResourceTypeId
      };
    })}
    initialPage={tabChoose}
    renderTabBar={(props) => (
      <Tabs.DefaultTabBar {...props} page={3} />
    )}
    onChange={(e: any) => setGisTypeId(e.gisResourceTypeId)}
  ></Tabs>, [typeData, tabChoose, setGisTypeId])


  // 获取全部标点类型
  const getTypeList = useCallback(async () => {
    let res = await get("/api/gis/list");
    if (res.code === 200) {
      setGisTypeId(res.data[0].gisResourceTypeId);
      setTypeData(res.data);
    }
  }, [])
  // 获取全部标点数据
  const getAllScenicData = useCallback(async () => {
    let res = await get("/api/gis/sourcelist", { pageSize: 0 });
    if (res.code === 200) {
      setAllScenicData(res.data)
    }
  }, []);
  // 获取停车场数据
  const getParkData = useCallback(async () => {
    let currentParkAnalysisRes = await get('/api/v1/getCurrentParkAnalysis');
    if (currentParkAnalysisRes.code === 200 && currentParkAnalysisRes.data !== null) {
      setCurrentParkAnalysis(currentParkAnalysisRes.data.data)
    }
  }, []);
  // 获取卫生间数据
  const getToiletData = useCallback(async () => {
    let currentToiletAnalysisRes = await get('/api/v1/getParkToiletInfo');
    console.log(currentToiletAnalysisRes)
    if (currentToiletAnalysisRes.code === 200 && currentToiletAnalysisRes.data !== null) {
      setCurrentToiletAnalysis(currentToiletAnalysisRes.data)
    }
  }, []);
  const getVedio = useCallback(async () => {
    let videoRes = await get('/api/explainingresources/list', { pageSize: 0 });
    if (videoRes.code === 200) {
      setVedioList(videoRes.data)
    }
  }, []);
  const getParkContent = useCallback(() => {
    return `${currentParkAnalysis.find((park: Park) => (showScenic?.parkId === park.theId.toString()))?.remainSpace}/${currentParkAnalysis.find((park: Park) => (showScenic?.parkId === park.theId.toString()))?.totalSpace}`
  }, [currentParkAnalysis, showScenic]);
  const parkContent = useMemo(() => getParkContent(), [currentParkAnalysis, showScenic])
  const getMaleToiletContent = useCallback(() => {
    return currentToiletAnalysis.find((park: Park) => (showScenic?.parkId === park.theId.toString()))?.male
  }, [currentParkAnalysis, showScenic]);
  const maleToiletContent = useMemo(() => getMaleToiletContent(), [currentParkAnalysis, showScenic])
  const getFemaleToiletContent = useCallback(() => {
    return currentToiletAnalysis.find((park: Park) => (showScenic?.parkId === park.theId.toString()))?.female
  }, [currentParkAnalysis, showScenic]);
  const femaleToiletContent = useMemo(() => getFemaleToiletContent(), [currentParkAnalysis, showScenic])
  const getThirdToiletContent = useCallback(() => {
    return currentToiletAnalysis.find((park: Park) => (showScenic?.parkId === park.theId.toString()))?.third
  }, [currentParkAnalysis, showScenic]);
  const thirdToiletContent = useMemo(() => getThirdToiletContent(), [currentParkAnalysis, showScenic])
  // 在下拉中选择标点类型
  const changeTabs = (e: IResourceType) => {
    setOpenMenu(false);
    setGisTypeId(e.gisResourceTypeId);
  };
  const clickOpenMenu = () => {
    setOpenMenu(!openMenu)
  };
  const showDetailModal = () => {
    setDetailModal(true);
    setIntroModal(false);
  };

  const navigation = () => {
    if (showScenic) {
      let destination = showScenic.coordinate.split(",");
      // alert(position.R, position.Q);
      console.log([position.R, position.Q])


      // 创建多边形
      //   var path = [
      //     [117.472524,34.164633],
      //     [117.472615,34.147981],
      //     [117.495467,34.147297],
      //     [117.495863,34.164275],
      // ];
      // var polygon = new AMap.Polygon({
      //     map: map,
      //     fillOpacity:0.4,
      //     path: path
      // });

      //     // 创建点
      //     var marker = new AMap.Marker({
      //       map: map,
      //       draggable:true,
      //       position: [117.485482,34.157579]
      //   });

      //   var point = marker.getPosition();
      //   console.log(point)
      //   var isPointInRing = AMap.GeometryUtil.isPointInRing(point,path);
      //   marker.setLabel({
      //       content:isPointInRing?'内部':'外部',
      //       offset:new AMap.Pixel(20,0)
      //   });

      //旧坐标
      // [117.472524, 34.164633],
      // [117.472615, 34.147981],
      // [117.495467, 34.147297],
      // [117.495863, 34.164275],

      //新坐标


      //创建园博园区域
      var path = [
        [117.472391, 34.165895],
        [117.471457, 34.146574],
        [117.496587, 34.145979],
        [117.496898, 34.165428],
      ];
      var isPointInRing = AMap.GeometryUtil.isPointInRing([position.R, position.Q], path);

      if (isPointInRing) {
        AMap.plugin("AMap.Walking", function () {
          //异步加载插件
          //步行导航
          if (walking && walking.clear) {
            walking.clear();
          }
          let walk = new AMap.Walking({
            map: map,
            panel: "panel",
          });
          //根据起终点坐标规划步行路线
          walk.search(
            [position.R, position.Q],
            destination,
            function (status: any, result: any) {
              // result即是对应的步行路线数据信息，相关数据结构文档请参考https://lbs.amap.com/api/javascript-api/reference/route-search#m_WalkingResult
              if (status === "complete") {
                setShowLine(true);
              } else {
                console.log("步行路线数据查询失败" + result);
                // 距离过远时报错，拉起app导航（微信浏览器中拉起微信地图通过右下角导航按钮拉起手机内的所有地图和打车软件）
                if (window.navigator.userAgent.toLowerCase().match(/MicroMessenger/i) == 'micromessenger') {
                  //微信中拉起微信地图
                  wx.openLocation({
                    latitude: 34.152405, // 纬度，浮点数，范围为90 ~ -90
                    longitude: 117.48554, // 经度，浮点数，范围为180 ~ -180。
                    name: '徐州园博园', // 位置名
                    address: '', // 地址详情说明
                    scale: 13, // 地图缩放级别,整型值,范围从1~28。默认为最大
                    infoUrl: '' // 在查看位置界面底部显示的超链接,可点击跳转
                  });
                } else if (props.from === FROM.APP) {
                  setChooseApp(true);
                }
              }
            }
          );
          setWalking(walk);
        });
      } else {
        console.log("超出园博园范围不进行步行路线导航");
        // 距离过远时报错，拉起app导航（微信浏览器中拉起微信地图通过右下角导航按钮拉起手机内的所有地图和打车软件）
        if (window.navigator.userAgent.toLowerCase().match(/MicroMessenger/i) == 'micromessenger') {
          //微信中拉起微信地图
          wx.openLocation({
            latitude: 34.152405, // 纬度，浮点数，范围为90 ~ -90
            longitude: 117.48554, // 经度，浮点数，范围为180 ~ -180。
            name: '徐州园博园', // 位置名
            address: '', // 地址详情说明
            scale: 13, // 地图缩放级别,整型值,范围从1~28。默认为最大
            infoUrl: '' // 在查看位置界面底部显示的超链接,可点击跳转
          });
        } else if (props.from === FROM.APP) {
          setChooseApp(true);
        }
      }

      // AMap.plugin("AMap.Walking", function () {
      //   //异步加载插件
      //   //步行导航
      //   if (walking && walking.clear) {
      //     walking.clear();
      //   }
      //   let walk = new AMap.Walking({
      //     map: map,
      //     panel: "panel",
      //   });
      //   //根据起终点坐标规划步行路线
      //   walk.search(
      //     [position.R, position.Q],
      //     destination,
      //     function (status: any, result: any) {
      //       // result即是对应的步行路线数据信息，相关数据结构文档请参考https://lbs.amap.com/api/javascript-api/reference/route-search#m_WalkingResult
      //       if (status === "complete") {
      //         setShowLine(true);
      //       } else {
      //         console.log("步行路线数据查询失败" + result);
      //         // 距离过远时报错，拉起app导航（微信浏览器中拉起微信地图通过右下角导航按钮拉起手机内的所有地图和打车软件）
      //         if (window.navigator.userAgent.toLowerCase().match(/MicroMessenger/i) == 'micromessenger') {
      //           //微信中拉起微信地图
      //           wx.openLocation({
      //             latitude: 34.152405, // 纬度，浮点数，范围为90 ~ -90
      //             longitude: 117.48554, // 经度，浮点数，范围为180 ~ -180。
      //             name: '徐州园博园', // 位置名
      //             address: '', // 地址详情说明
      //             scale: 13, // 地图缩放级别,整型值,范围从1~28。默认为最大
      //             infoUrl: '' // 在查看位置界面底部显示的超链接,可点击跳转
      //           });
      //         } else if (props.from === FROM.APP) {
      //           setChooseApp(true);
      //           // window['jsNavigation'].postMessage(JSON.stringify({
      //           //   lon: that.state.position.Q,
      //           //   lat: that.state.position.R,
      //           //   type: 2
      //           // }))
      //         }
      //       }
      //     }
      //   );
      //   setWalking(walk);
      // });

      audio.current?.pause();
      setDetailModal(false);
      setIntroModal(false);
    }
  };
  const scroll = () => {
    if (scorllDiv.current) {
      let top = scorllDiv.current?.scrollTop;
      if (top === 0) {
        audio.current?.pause()
        setDetailModal(false);
      }
    }
  }
  return (
    <div className={style.mapPage}>

      <div className={style.toCenter} onClick={() => {
        // setCenter([117.48554, 34.152405]);
        map.setCenter([position.R, position.Q]); //设置地图中心点
      }}>
        <AimOutlined />
      </div>
      {/* 头部选择器 */}
      <div
        className={style.navBar}
        style={
          props.padding
            ? { marginTop: JSON.parse(props.padding).top + "px" }
            : {}
        }
      >
        {openMenu ? (
          <div className={style.menu}>
            <div className={style.menuTitle}>全部</div>
            <div className={style.menuContent}>
              {typeData.map((item: IResourceType, index: number) => {
                return (
                  <div
                    key={index}
                    className={style.menuItem}
                    onClick={() => changeTabs(item)}
                  >
                    <div className={style.iconDiv}>
                      <img src={item.icon} alt="" />
                    </div>
                    <div
                      className={
                        gisTypeId === item.gisResourceTypeId
                          ? style.hoverStyle
                          : style.defaultStyle
                      }
                    >
                      {item.resourceTypeName}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        ) : (
          <div className={style.tabs}>
            {tabs}
          </div>
        )}
        <div className={style.downIcon} onClick={() => clickOpenMenu()}>
          <Icon type={openMenu ? "up" : "down"} />
        </div>
      </div>
      {showScenic ? (
        <div className={introModal ? style.showIntroModal : style.closeIntroModal}>
          <div className={style.introModalContent}>
            <div className={style.imgBox}>
              {console.log(showScenic.icon.split(",")[0])}
              <img src={showScenic.icon.split(",")[0]} style={{ width: "auto", margin: 'auto', height: "100%" }} alt="" />
            </div>
            <div className={style.contentBox}>
              <div className={style.title}>{showScenic.gisTitle}</div>
              {console.log(currentParkAnalysis)}
              {console.log(showScenic.gisDescrition)}
              {console.log(thirdToiletContent)}
              {
                showScenic.attribute == 2 && currentParkAnalysis.find((park: Park) => (showScenic.parkId === park.theId.toString())) ?
                  <div className={style.content}>剩余车位:{parkContent}个</div> :
                  showScenic.attribute == 3 && currentToiletAnalysis.find((park: Park) => (showScenic.parkId === park.theId.toString())) ?
                    (<div className={style.toiletContainer}>
                      <div className={style.toiletModuleContainer}>
                        <img className={style.toilet} src={menRoom}></img>
                        <div>
                          <div>男厕</div>
                          <div>剩余{maleToiletContent}</div>
                        </div>
                      </div>
                      <div className={style.toiletModuleContainer}>
                        <img className={style.toilet} src={womenRoom}></img>
                        <div>
                          <div>女厕</div>
                          <div>剩余{femaleToiletContent}</div>
                        </div>
                      </div>
                    </div>)
                    : <div className={style.content} dangerouslySetInnerHTML={{ __html: showScenic.gisDescrition }}></div>
              }
              <div
                className={style.closeIntroIcon}
                onClick={() => setShowScenic(undefined)}
              >
                <Icon type="cross" />
              </div>
            </div>
          </div>
          <div className={style.introModalBtn}>
            {showScenic.attribute == 2 || showScenic.attribute == 3 || showScenic.attribute == 4 ? null : <button onClick={() => showDetailModal()}>查看详情</button>}
            {/* <button style={{margin: 'auto'}} onClick={() => navigation()}>导航</button> */}
            {position.Q === 0 && position.R === 0 ? null : <button style={{ margin: 'auto' }} onClick={() => navigation()}>导航</button>}
          </div>
        </div>
      ) : null}
      {showScenic && detailModal ? (
        <div
          className={style.detailModal}
          onClick={() => {
            setDetailModal(false);
            audio.current?.pause()
          }}
          ref={scorllDiv}
          onScroll={() => scroll()}
          style={{
            display: detailModal ? "block" : "none",
          }}
        >
          <div className={style.main} onClick={(e) => {
            e.stopPropagation();
            e.nativeEvent.stopImmediatePropagation();
          }}>
            <div className={style.imgList}>
              <Carousel
                autoplay={false}
                infinite
                style={{ visibility: "visible" }}
                afterChange={(e: number) => {
                  setCurrentImagePage(e + 1);
                }}
              >
                {showScenic?.icon.split(",").filter(item => item !== '').length > 0 ?
                  showScenic.icon.split(",").filter(item => item !== '').map((item) => {
                    return (
                      <a href="a" key={item} className={style.imgBackground} >
                        <img src={item} alt="" style={{ width: "auto", margin: 'auto', height: "30vh" }} onLoad={() => { window.dispatchEvent(new Event("resize")); }} />
                      </a>
                    );
                  }) : <a href="a" className={style.imgBackground}>
                    <img src={defaultCover} alt="" style={{ width: "auto", margin: 'auto', height: "30vh" }} onLoad={() => { window.dispatchEvent(new Event("resize")); }} />
                  </a>}
              </Carousel>
              <div className={style.slideLine}></div>
              <div className={style.pageBox}>
                {currentImagePage}/{showScenic?.icon.split(",").filter(item => item !== '').length > 0 ? showScenic?.icon.split(",").filter(item => item !== '').length : 1}
              </div>
            </div>
            <div className={style.detailModalContent}>
              <div className={style.mainBox}>
                <div className={style.title}>{showScenic?.gisTitle}</div>
                <div className={style.audioBox}>
                  <audio src={vedioList.find(vedio => vedio.explainId === showScenic?.pathUrl)?.explainThumbnail} ref={audio} controls controlsList="nodownload"></audio>
                </div>
                <div className={style.content} dangerouslySetInnerHTML={{ __html: showScenic?.content || '' }}></div>
              </div>
            </div>
            {position.Q === 0 && position.R === 0 ? null :
              <div className={style.footerBox}>
                <button onClick={() => navigation()}>导航</button>
              </div>}
          </div>
        </div>
      ) : null}
      <div className={style.container} ref={mapRef}></div>
      {showLine ? <CloseCircleOutlined className={style.closeIcon} style={{
        color: '#fff', top: props.padding
          ? `calc(${JSON.parse(props.padding).top}px + 13.1vw)`
          : `calc(13.1vw)`
      }}
        onClick={() => {
          if (walking && walking.clear) {
            walking.clear();
            setShowLine(false)
          }
        }} /> : null}
      <div id="panel" style={props.padding
        ? { top: `calc(${JSON.parse(props.padding).top + 20}px + 13.1vw)` }
        : { top: `calc(20px + 13.1vw)` }}></div>
      <Drawer
        title="请选择导航软件"
        placement={'bottom'}
        closable={false}
        height={'calc(30vw + 103px)'}
        onClose={() => setChooseApp(false)}
        visible={chooseApp}
      >
        <div className={style.appBox} onClick={() => {
          window['jsNavigation'].postMessage(JSON.stringify({
            lon: showScenic?.coordinate.split(",")[0],
            lat: showScenic?.coordinate.split(",")[1],
            type: 0
          }))
        }}>
          <img className={style.appImg} src={baiduapp} alt="" />
          <div className={style.appName}>百度地图</div>
        </div>
        <div className={style.appBox} onClick={() => {
          window['jsNavigation'].postMessage(JSON.stringify({
            lon: showScenic?.coordinate.split(",")[0],
            lat: showScenic?.coordinate.split(",")[1],
            type: 1
          }))
        }}>
          <img className={style.appImg} src={txapp} alt="" />
          <div className={style.appName}>腾讯地图</div>
        </div>
        <div className={style.appBox} onClick={() => {
          window['jsNavigation'].postMessage(JSON.stringify({
            lon: showScenic?.coordinate.split(",")[0],
            lat: showScenic?.coordinate.split(",")[1],
            type: 2
          }));
        }}>
          <img className={style.appImg} src={gaodeapp} alt="" />
          <div className={style.appName}>高德地图</div>
        </div>
      </Drawer>
    </div>
  )
}
export default connect(mapStateToProps)(
  withRouter<any, FunctionComponent<GisProps>>(Gis)
);