import { Icon, Modal, NavBar, Toast } from "antd-mobile";
import React, { ComponentClass } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import style from "./index.module.css";
import { get, postData, post } from "../../axios/axios";
import { LoginInfo, UserInfo as user } from "../../store/type";
import { Dispatch } from "redux";
import { login } from "../../store/actions";
import { connect } from "react-redux";
import { FROM, LOGINSTATE } from "../../store/enmu";
import { debug } from "console";
import { message } from "antd";
import noData from "../../asset/amountsExchange/noData.png"

interface IState {
  tableList: listType[];
  userId?: number;
  userName?: string;
}
interface listType {
  period: string;
  date: string;
  accompanyNum: number;
  abnormalType: string;
  status: string;
  reservationId: number;
}
const mapDispatchToProps = (dispatch: Dispatch) => ({
  onLogin: (userinfo: user, token: string) => dispatch(login(userinfo, token)),
});
const mapStateToProps = (
  state: LoginInfo
): {
  userInfo?: user;
  from: FROM | null;
  padding?: string;
  state: LOGINSTATE
} => ({
  userInfo: state.userInfo,
  from: state.from,
  padding: state.padding,
  state: state.state
});
interface IProps extends RouteComponentProps, LoginInfo {
  changeContent?: boolean;
  onSignout: () => {};
  onLogin: (userinfo: user, token: string) => {};
}
class ReservationList extends React.PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      tableList: [],
    };
    this.getData();
  }
  jump(url?: number) {
    url && this.props.history.push(`/reservationDetail/${url}`);
  }
  render() {
    const { tableList } = this.state;
    return (
      <div className={style.ReservationList}>
        <div
          className={style.orderHeader}
          style={
            this.props.padding
              ? { marginTop: JSON.parse(this.props.padding).top + "px" }
              : {}
          }
        >
          {this.props.from != FROM.WXMINI ? <NavBar
            className={style.navBar}
            icon={
              <Icon type="left" onClick={() => this.props.history.goBack()} />
            }
          >
            预约记录
          </NavBar> : null}
        </div>

        <div className={style.main} style={
          this.props.padding
            ? { height: `calc(100vh - ${JSON.parse(this.props.padding).top + JSON.parse(this.props.padding).bottom + 45}px)` }
            : { height: `calc(100vh - 45px)` }
        }>
          {tableList && tableList.length > 0
            ? tableList.map((item) => {
              return (
                <div className={style.itemBox}>
                  <div className={style.top}>
                    <span>预约入园</span>
                    {(() => {
                      switch (item.status) {
                        // case "0":
                        //   return (
                        //     <span className={style.previewText}>已预约</span>
                        //   );
                        case "0":
                          return new Date() < new Date(item.date + ' ' + item.period.split('-')[1]) ?
                            (<span className={style.previewText}>已预约</span>)
                            : (<span className={style.previewText}>已完成</span>);
                        // case "1":
                        //   return <span>已过期</span>;
                        case "2":
                          return <span>已取消</span>;
                      }
                    })()}
                  </div>
                  <div className={style.content}>
                    <p>
                      时间：{item.date} {item.period}
                    </p>
                    <p>随行人数：{item.accompanyNum}人</p>
                    <p>
                      有无异常：
                      {(() => {
                        switch (item.abnormalType) {
                          case "0":
                            return "无症状";
                          case "1":
                            return "发热";
                          case "2":
                            return "咳嗽";
                          case "3":
                            return "乏力";
                        }
                      })()}{" "}
                    </p>
                  </div>
                  <div className={style.footer}>
                    <button onClick={() => this.jump(item.reservationId)}>
                      查看
                    </button>
                    {item.status !== "2" && (new Date() < new Date(item.date + ' ' + item.period.split('-')[1])) ? (
                      <button
                        onClick={this.cancelAppointment.bind(
                          this,
                          item
                        )}
                      >
                        取消
                      </button>
                    ) : null}
                  </div>
                </div>
              );
            })
            : <img className={style.noDataPic} src={noData} alt="" />}
        </div>
      </div>
    );
  }
  cancelAppointment = async (e: any) => {
    const _this = this
    const modal = Modal.alert('请确认', `是否取消${e.date} ${e.period}的预约`, [{
      text: '确认',
      onPress: () => {
        _this.cancleOk(e.reservationId)
      }
    }, {
      text: '取消',
      onPress: () => {
        modal.close()
      }
    }])
    // Modal.confirm({        
    //   content: `是否取消${e.date} ${e.period}的预约`,
    //   onOk() {
    //     _this.cancleOk(e.reservationId)
    //   },
    //   okText: '确定',
    //   cancelText: '取消'
    // });
  };

  async cancleOk(e: any) {
    let res = await post("/api/user/cancelreserve", {
      reservationId: e,
      userId: this.state.userId,
      userName: this.state.userName,
      status: "2",
    });
    if (res.code === 200) {
      Toast.info("取消成功");
      this.getData();
    }
  }
  getData = async () => {
    if (this.props.userInfo) {
      let userId = this.props.userInfo?.userId;
      let res = await post("/api/user/reservelist", { userId: userId, pageSize: 0 });
      this.setState({
        tableList: res.data,
        userId: res.data.userId,
        userName: res.data.userName,
      });
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter<any, ComponentClass<IProps, IState>>(ReservationList));
