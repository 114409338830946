let routerList: {[key:string]: string} = {
    "/information/contentList/news": "/information/contentList/news",
    "/information/noticeList/announcement": "/information/contentList/announcement",
    "/information/contentList/party": "/information/contentList/party",
    "/information/contentList/event": "/information/contentList/event",
    "/information/imageList/impression": "/information/contentList/impression",
    "/about/contentPage/introduction": "/about/contentList/introduction",
    "/about/organization/org": "/about/contentList/org",
    "/about/imageCardList/honor": "/about/contentList/honor",
    "/about/contentPage/spirit": "/about/contentList/spirit",
    "/about/contentList/memorabilia": "/about/contentList/memorabilia",
    "/about/imageCardList/promo": "/about/contentList/promo",
    "/about/contentList/plan": "/about/contentList/plan",
    "/touristNotice/contentPage/searchOpeningTime": "/touristNotice/contentList/searchOpeningTime",
    "/touristNotice/contentList/notice": "/touristNotice/contentList/notice",
    "/touristNotice/imageCardList/line": "/touristNotice/contentList/line",
    "/touristNotice/contentList/trafficservice": "/touristNotice/contentList/trafficservice",
    "/touristNotice/contentList/commonproblem": "/touristNotice/ContentList/commonproblem",
}

export const breadcrumbNameMap:{[key:string]: string} = {//跟路由路径保持一致
    "/": "首页",
    "/403": "403",
    "/404": "404",
    "/paycode": "会员码",
    "/giftCode": "礼金券",
    "/amounts": "礼金券",
    "/userInfo": "个人中心",
    "/myOrder": "我的订单",
    "/reservation": "预约入园",
    "/reservationList": "预约记录",
    "/find": "寻人寻物",
    "/detail/find": "寻人寻物",
    "/homePage": "首页",
    "/suggestionList": "咨询建议",
    "/suggestion": "咨询建议",
    "/complaintList": "投诉举报",
    "/complaint": "违规行为投诉",
    "/detail/complaint": "投诉举报详情",
    "/detail/information": "景区资讯",
    "/detail/about": "关于园博",
    "/detail/touristNotice": "游客须知",
    "/detail/guide/guide": "游客攻略",
    "/detail/information/power": "游客攻略",
    "/detail/information/news": "园博新闻",
    "/detail/information/announcement": "园博公告",
    "/detail/information/party": "党政工作",
    "/detail/information/event": "园博活动",
    "/detail/information/impression": "园博印象",
    "/detail/about/introduction": "园博简介",
    "/detail/about/org": "组织结构",
    "/detail/about/honor": "园博荣誉",
    "/detail/about/spirit": "园博精神",
    "/detail/about/memorabilia": "园博大事记",
    "/detail/about/promo": "园博视讯",
    "/detail/about/plan": "园博规划",
    "/detail/touristNotice/searchOpeningTime": "开放时间",
    "/detail/touristNotice/notice": "入园须知",
    "/detail/touristNotice/line": "路线推荐",
    "/detail/touristNotice/trafficservice": "交通服务",
    "/detail/touristNotice/commonproblem":"常见问题",
    "/detail/about/plant": "植物百科",
    "/information": "景区资讯",
    "/about": "关于园博",
    "/touristNotice": "游客须知",
    "/guide": "游客攻略",
    "/information/contentList/guide": "游客攻略",
    "/information/contentList/news": "园博新闻",
    "/information/contentList/announcement": "园博公告",
    "/information/contentList/party": "党政工作",
    "/information/contentList/event": "园博活动",
    "/information/contentList/impression": "园博印象",
    "/about/contentPage/introduction": "园博简介",
    "/about/organization/org": "组织结构",
    "/about/contentList/honor": "园博荣誉",
    "/about/contentPage/spirit": "园博精神",
    "/about/contentList/memorabilia": "园博大事记",
    "/about/contentList/promo": "园博视讯",
    "/about/contentList/plan": "园博规划",
    "/about/imageCardList/plant": "植物百科",
    "/touristNotice/contentPage/searchOpeningTime": "开放时间",
    "/touristNotice/contentList/notice": "入园须知",
    "/touristNotice/contentList/line": "路线推荐",
    "/touristNotice/contentList/trafficservice": "交通服务",
    "/touristNotice/contentList/commonproblem":"常见问题",
};
export default routerList