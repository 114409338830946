
import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { RouterProps, withRouter } from "react-router";
import { FROM } from "../../store/enmu";
import { LoginInfo } from "../../store/type";
import style from './index.module.css';
import counselBackground from '../../asset/counsel/counselBackground.png';
import { Icon, NavBar } from "antd-mobile";
import React from "react";
import { content, IContentListProps } from "../ContentList";
import OptionTitle, { titleInfo } from "../../components/OptionTitle";
import { get,post } from "../../axios/axios";
import { AboutRouter } from "../../routers";
import { Redirect, Route, Switch } from "react-router-dom";
import { ymd } from "../../components/ymd";
import { IContentPageProps } from "../ContentPage";
import PagingLoad from "../../components/PagingLoad";
import useMounted from "../../components/hooks/useMounted";
interface IProps extends RouterProps,LoginInfo {

}
const scorllDiv = React.createRef<HTMLDivElement>()
const contentDiv = React.createRef<HTMLDivElement>()
const OptionTitleRef = React.createRef<OptionTitle>()

const titleList = [{
    title: '园博简介',
    path: '/contentPage/introduction'    
// }, {
//     title: '组织结构',
//     path: '/organization/org'    
// }, {
//     title: '园博精神',
//     path: '/contentPage/spirit'    
}, {
    title: '园博视讯',
    path: '/contentList/promo'    
// }, {
//     title: '园博荣耀',
//     path: '/contentList/honor',
// }, {
//     title: '园博大事件',
//     path: '/contentList/memorabilia',
// }, {
//     title: '园博规划',
//     path: '/contentList/plan',
}]
/**
 *（点击checkOption/加载页面） -> props.history.location.pathname -> active变化 -> settings/pageNum触发请求
 *  pageNum变化 -> 触发请求
*/
const About: React.FC<IProps> = (props: IProps) => {
    const [titleFixed, setTitleFixed] = useState(false);
    const [optioneTitleStyle, setOptioneTitleStyle] = useState([style.optioneTitle]);
    const [bodyStyle, setBodyStyle] = useState([style.body, style.bodyOnTop]);
    const [atBottom, setAtBottom] = useState(false);
    const [active, setActive] = useState(0); 
    const [pageSize, setPageSize] = useState(10);
    const [pageNum, setPageNum] = useState(1);
    const [settings, setSettings] = useState({
        dataList: [] as content[],
    } as IContentListProps | IContentPageProps);
    const [loading, setLoading] = useState(false);
    const [total, setTotal] = useState(0);
    useMounted(() => {
        OptionTitleRef.current?.refreshScroll();
    })
    // 路由改变触发的effect
    useEffect(() => {
        if(!loading){
            setTotal(0);
            setPageNum(1);
            switch(props.history.location.pathname) {
                case '/about/contentPage/introduction': 
                    setActive(0);
                    setSettings({content: ''})
                    break;
                case '/about/organization/org': 
                    setActive(1);
                    setSettings({
                        dataList: [] as content[]
                    })
                    break;
                case '/about/contentPage/spirit': 
                    setActive(2);
                    setSettings({content: ''})
                    break;
                case '/about/contentList/honor': 
                    setActive(4);
                    setSettings({
                        dataList: [] as content[],
                        goItem: (id: number) => {
                            props.history.push(`/detail/about/honor/${id}`);
                        }
                    })
                    break;
                case '/about/contentList/memorabilia': 
                    setActive(5);
                    setSettings({
                        dataList: [] as content[],
                        goItem: (id: number) => {
                            props.history.push(`/detail/about/memorabilia/${id}`);
                        }
                    })
                    break;
                case '/about/contentList/promo': 
                    // setActive(3);
                    setActive(1);
                    setSettings({
                        dataList: [] as content[],
                        goItem: (id: number) => {
                            props.history.push(`/detail/about/promo/${id}`);
                        }
                    })
                    break;
                case '/about/contentList/plan':
                    setActive(6);
                    setSettings({
                        dataList: [] as content[],
                        goItem: (id: number) => {
                            props.history.push(`/detail/about/plan/${id}`);
                        }
                    })
                    break; 
            }         
        }
    }, [props.history.location.pathname]);
    useEffect(() => {
        if(!loading &&(total === 0 || (settings as IContentListProps).dataList && total > (settings as IContentListProps).dataList.length)){
            setLoading(true);
            switch(props.history.location.pathname) {
                case '/about/contentPage/introduction': 
                    getIntroductionData()
                    break;
                case '/about/organization/org': 
                    getOragnizationData()
                    break;
                case '/about/contentPage/spirit': 
                    getSpiritData()
                    break;
                case '/about/contentList/honor': 
                    getHonorData();
                    break;
                case '/about/contentList/memorabilia': 
                    getMemorabiliaData();
                    break;
                case '/about/contentList/promo': 
                    getPromoData();
                    break;
                case '/about/contentList/plan':
                    getPlanData();
                    break;          
            }
        }
    }, [active, pageNum]);
    const getIntroductionData = async() => {
        const requestData = await get('/api/expoinformat/searchIntroduction');
        setLoading(false);
        if(requestData.code === 200) {
            setSettings({
                content: requestData.data[0].parkIntroduction
            })
            setTotal(requestData.message);
        }
    }
    const getOragnizationData = async() => {
        const requestData = await get('/api/basis/searchOrganization');
        setLoading(false);
        if(requestData.code === 200) {
            setSettings({
                dataList: requestData.that.organizationTree(requestData.data)
            })
            setTotal(requestData.message);
        }
    }
    const getSpiritData = async() => {
        const requestData = await get('/api/expoinformat/searchIntroduction');
        setLoading(false);
        if(requestData.code === 200) {
            setSettings({
                content: requestData.data[0].spirit
            })
            setTotal(requestData.message);
        }
    }
    const getHonorData = async() => {
        const requestData = await post('/api/honor/list', {
            reviewStatus: 3,
            dispalyStatus: 1,
            pageSize: pageSize,
            pageNum: pageNum,
        });
        setLoading(false);
        if(requestData.code === 200) {
            let result: content[] = []
            requestData.data.forEach((element: any) => {
                result.push({
                    id: element.gloryId,
                    title: element.gloryTitle,
                    content: element.gloryContent,
                    imgUrl: element.gloryThumbnail,
                    createTime: ymd(new Date(element.createtime))
                })
            });
            setSettings({
                ...settings,
                dataList: [...(settings as IContentListProps).dataList, ...result]
            })
            setTotal(requestData.message);
        }
    }
    const getMemorabiliaData = async() => {
        const requestData = await post('/api/memorabilia/list', {
            reviewStatus: 3,
            pageSize: pageSize,
            pageNum: pageNum,
            dispalyStatus: 1
        });
        setLoading(false);
        if(requestData.code === 200) {
            let result: content[] = [];
            requestData.data.forEach((element: any) => {
                result.push({
                    id: element.memorabiliaId,
                    title: element.memorabiliaTitle,
                    content: element.memorabiliaContent,
                    imgUrl: element.memorabiliaThumbnail,
                    createTime: ymd(new Date(element.createtime))
                })
            });
            setSettings({
                dataList: [...(settings as IContentListProps).dataList, ...result],
                goItem: (id: number) => {
                    props.history.push(`/detail/about/memorabilia/${id}`);
                }
            })
            setTotal(requestData.message);
        }
    }
    const getPromoData = async() => {
        const requestData = await post('/api/promo/list', {
            reviewStatus: 3,
            pageSize: pageSize,
            pageNum: pageNum,
        });
        setLoading(false);
        if(requestData.code === 200) {
            let result: content[] = []
            requestData.data.forEach((element: any) => {
                result.push({
                    id: element.promoId,
                    title: element.promoTitle,
                    content: element.promoContent,
                    imgUrl: element.promoThumbnail,
                    createTime: ymd(new Date(element.createtime)),
                    pathUrl: element.pathUrl
                })
            });
            setSettings({
                dataList: [...(settings as IContentListProps).dataList, ...result],
                goItem: (id: number) => {
                    props.history.push(`/detail/about/promo/${id}`);
                }
            })
            setTotal(requestData.message);
        }
    }
    const getPlanData = async() => {
        const requestData = await post('/api/plan/list', {
            reviewStatus: 3,
            pageSize: pageSize,
            pageNum: pageNum,
            dispalyStatus: 1
        });
        setLoading(false);
        if(requestData.code === 200) {
            let result: content[] = []
            requestData.data.forEach((element: any) => {
                result.push({
                    id: element.planningId,
                    title: element.planningTitle,
                    content: element.planningContent,
                    imgUrl: element.planningThumbnail,
                    createTime: ymd(new Date(element.createtime))
                })
            });
            setSettings({
                dataList: [...(settings as IContentListProps).dataList, ...result],
                goItem: (id: number) => {
                    props.history.push(`/detail/about/plan/${id}`);
                }
            })
            setTotal(requestData.message);
        }
    }
    const scroll = (scorllDiv: React.RefObject<HTMLDivElement>) => {
        if(scorllDiv.current) {
          var scole = Math.abs(scorllDiv.current.clientHeight + scorllDiv.current?.scrollTop - scorllDiv.current?.scrollHeight);
            if(scorllDiv.current?.scrollTop >= scorllDiv.current.clientWidth / 2 - 30 - (props.padding ?JSON.parse(props.padding).top : 0)) {
                setTitleFixed(true);
                setOptioneTitleStyle([style.optioneFixedTitle])
            } else {                  
                setTitleFixed(false);
                setOptioneTitleStyle([style.optioneTitle])
          }
        }
        if(contentDiv.current && scorllDiv.current && scorllDiv.current.scrollTop >= contentDiv.current.clientHeight - window.screen.height - 45) {
            setAtBottom(true);
        } else {      
            setAtBottom(false);
        }
    }
    const checkOption = (title: titleInfo) => {
        if(!loading){
            props.history.replace('/about' + title.path)
            if(scorllDiv.current) {
                if(scorllDiv.current?.scrollTop >= scorllDiv.current.clientWidth / 2 - 30 - (props.padding ?JSON.parse(props.padding).top : 0)) {
                    scorllDiv.current.scrollTop = scorllDiv.current.clientWidth / 2 - 30 - (props.padding ?JSON.parse(props.padding).top : 0);
                }
            }
        }
    }
    return (
        <div className={style.Counsel}>
            <PagingLoad atBottom={atBottom} load={() => {setPageNum(pageNum + 1)}}>
                <div className={style.top}
                    style={
                    props.padding
                    ? {
                        paddingTop: JSON.parse(props.padding).top + "px",
                        background: "url(" + counselBackground + ") no-repeat",
                        backgroundSize: "100%"
                    }
                    : {
                        background: "url(" + counselBackground + ") no-repeat",
                        backgroundSize: "100%"
                    }}
                >
                {
                    props.from != FROM.WXMINI?<NavBar
                    icon={<Icon onClick={() => props.history.goBack()} color="#fff" type="left" />}
                    onLeftClick={() => console.log('onLeftClick')}
                    className={style.NavBar}
                    mode="dark"
                    >关于园博</NavBar>: null
                }
                    <div className={style.scorll}
                        ref={scorllDiv}
                        onScroll={() => scroll(scorllDiv)}
                        style={{height: props.padding? 'calc(100vh - ' + (45 + Number(JSON.parse(props.padding).top)) + 'px)':'calc(100vh - 45px)'}}>
                        <div className={bodyStyle.join(' ')} ref={contentDiv}>
                            <div className={optioneTitleStyle.join(" ")} style={{top: props.from === FROM.WXMINI? '0':(props.padding? (45 + Number(JSON.parse(props.padding).top)) + 'px':'45px')}}>
                                <OptionTitle active={active} titleList={titleList} showNumber={2} barfootWidth={7} ref={OptionTitleRef} onChange={(title:titleInfo) => checkOption(title)}/>
                            </div>
                            <div className={style.switchContent} style={{marginTop: titleFixed?'15vw': '0'}}></div>
                            <Switch>
                                {
                                    AboutRouter.children.map(router => (
                                        <Route path={router.path} render={() => {return <router.component {...settings}/>}} key={router.key}/>
                                    ))
                                }
                                <Redirect path="/" to="/about/contentPage/introduction" />
                            </Switch>
                        </div>
                    </div>
                </div>
            </PagingLoad>
        </div>
    )
}
const mapStateToProps = (
    state: LoginInfo
  ): {
    from: FROM | null;
    padding?: string;
  } => ({
    from: state.from,
    padding: state.padding
  });
export default connect(mapStateToProps)(withRouter<any, FunctionComponent<IProps>>(About));
export {titleList};