import { Button, message } from 'antd';
import { Icon, NavBar, Toast } from 'antd-mobile';
import React, { ComponentClass } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import wx from 'weixin-js-sdk';
import { FROM } from '../../store/enmu';
import { LoginInfo } from "../../store/type";
import style from "./index.module.css";

interface IProps extends LoginInfo, RouteComponentProps {
  name: string,
  src: string
}
interface IState {
  url: string
}
declare var window: {
  [key: string]: any; // missing index defintion
  prototype: Window;
  new(): Window;
};
const mapStateToProps = (
  state: LoginInfo
): {
  from: FROM | null;
  padding?: string;
} => ({
  from: state.from,
  padding: state.padding,
});
const ifreamRef = React.createRef<HTMLIFrameElement>();
class OutURL extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    window['gotoMyOrder'] = function (memberId: string) {
      if (ifreamRef.current) {
        ifreamRef.current.src = `https://h5ticket.xzyby.cn/Ticket/MyOrder?memberId=${memberId}&openId=APP&type=2`
      }
    }
    window['getOrder'] = function (orderId: string) {
      props.history.replace(`/invoice/${orderId}/ticket`);
    }
    window['goReplace'] = function (orderId: string, callback: string) {
      props.history.replace(`/ticketreplace/${orderId}/${callback}`);
    }
    window['ticketRedirect'] = function (url: string) {
      props.history.push(decodeURIComponent(url));
    }
    window['appSetAiImagePath'] = function (url: string) {
      // Toast.info(url, 1)
      const childFrameObj: any = document?.getElementById('frame');
      childFrameObj?.contentWindow.postMessage(url, '*');
      // childFrameObj?.contentWindow.appSetAiImagePath(url);
    }
    // alert(localStorage.getItem('ticketCallback'))
    this.state = {
      url: localStorage.getItem('ticketCallback') !== null ? localStorage.getItem('ticketCallback') as string : (this.props.match.params as { name: string, url: string }).url
    }
    // this.state = {
    //   url: (this.props.match.params as {name: string, url: string}).url
    // }
    localStorage.removeItem('ticketCallback');
  }
  jump(url: string) {
    if (this.props.from === FROM.WXMINI) {
      wx.miniProgram.navigateTo({
        url: '../jumpPage/jumpPage?url=' + `https://h5.xzyby.cn/#/wxminiOpen/${this.props.userToken}/${encodeURIComponent(url)}`,
      });
    } else {
      this.props.history.push(url)
    }
  }
  render() {
    const { url } = this.state;
    const { name } = this.props.match.params as { name: string, url: string };
    return (
      <div className={style.OutURL}>
        <div
          className={style.orderHeader}
          style={
            this.props.padding
              ? { marginTop: JSON.parse(this.props.padding).top + "px" }
              : {}
          }
        >
          {this.props.from != FROM.WXMINI ? <NavBar
            className={style.navBar}
            icon={
              <Icon type="left" onClick={() => name == '我的订单' ? this.jump("/myOrder") : this.props.history.goBack()} />
            }
            onLeftClick={() => console.log("onLeftClick")}
          >
            {name}
          </NavBar> : null}
        </div>
        <iframe id="frame" name="frame" className={style.iframe} ref={ifreamRef} style={
          this.props.padding
            ? { height: 'calc(100vh - ' + (45 + JSON.parse(this.props.padding).top + JSON.parse(this.props.padding).bottom) + 'px)' }
            : {}
        } src={decodeURIComponent(url)}></iframe>
      </div>
    )
  }
}
export default connect(mapStateToProps)(
  withRouter<any, ComponentClass<IProps>>(OutURL)
);