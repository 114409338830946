import React, { RefObject } from 'react';
import {RouteComponentProps} from 'react-router-dom';
import counselBackground from '../../asset/counsel/counselBackground.png';
import service from '../../service';
import { Icon, NavBar } from 'antd-mobile';
import './index.css'
import style from './index.module.css';
import { breadcrumbNameMap } from '../../components/routerList';
import { FROM } from '../../store/enmu';
import { LoginInfo } from '../../store/type';
import { connect } from 'react-redux';

declare var window: {
    [key: string]: any; // missing index defintion
    prototype: Window;
    new (): Window;
  };
interface kv{
    [key: string]: RefObject<HTMLDivElement>
}
interface IProps extends RouteComponentProps, LoginInfo{
}interface ILocaltionState {
  id: number;
  component: string;
  module: string;
}
interface PageBasic {
  id?: number;
  title?: string;
  content?: string;
  imgUrl?: string;
  createTime?: string;
  pathUrl?: string;
  fileUrl?: [] | ""
}
interface fileDetail {
    filePath: string,
    filename: string,
    id: number
}
export interface PageInfo extends PageBasic {
  up: PageBasic;
  down: PageBasic;
  more: PageBasic[];
}
interface IState {
  location: ILocaltionState;
  pageInfo: PageInfo;
  bodyStyle: string[];
}
const divref = React.createRef<HTMLDivElement>();
class DetailPage extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            location: {
                id: (this.props.match.params as ILocaltionState).id,
                component: (this.props.match.params as ILocaltionState).component,
                module: (this.props.match.params as ILocaltionState).module
            },
            pageInfo: {
                up: {},
                down: {},
                more: []
            },
            bodyStyle: [style.body, style.bodyOnTop]
        }
    }
    componentDidMount() {
        this.getData();
    }
    scroll(scorllDiv: React.RefObject<HTMLDivElement>) {
    //   if(scorllDiv.current) {
    //     var scole = Math.abs(scorllDiv.current.clientHeight + scorllDiv.current?.scrollTop - scorllDiv.current?.scrollHeight);
    //     if(scole <= 30 && scole > 20) {
    //       this.setState({
    //         bodyStyle: [style.body, style.bodyOnTop]
    //       })
    //       scorllDiv.current.scrollTop = scorllDiv.current.scrollTop + 30;
    //     } else if (scole <= 20 && scole > 5) {
    //         scorllDiv.current.scrollTop = scorllDiv.current.scrollTop - 26;
    //     }
    //     if(scole <= 30) {
    //       this.setState({
    //         bodyStyle: [style.body, style.bodyOnTop]
    //       })
    //     } else {      
    //       this.setState({
    //         bodyStyle: [style.body]
    //       })
    //     }
    //   }
    }
    async getData() {
        let pageInfo:PageInfo = await service[this.state.location.module][this.state.location.component](this.state.location.id);
        if(divref.current) {
            divref.current.scrollTop = 0;
        }
        this.setState({
            pageInfo: pageInfo
        })
    }
    jump(id?: number) {
        if(id) {
            this.props.history.replace(`/detail/${this.state.location.module}/${this.state.location.component}/${id}`);
            this.setState({
                location: {
                    ...this.state.location,
                    id: id
                }
            },() => this.getData());
        }
    }
    downLoad(filePath: string) {
        window['downloadFile'].postMessage(filePath);
    }
    render() {
        const {title,content,createTime,up,down,more,pathUrl,fileUrl} = this.state.pageInfo;
        const pathSnippets = this.props.location.pathname.split("/").filter((i) => isNaN(Number(i))? i: undefined);
        const url = `/${pathSnippets.join("/")}`;
        const { padding } = this.props;
        return (
            <div className={style.Counsel}>
                <div
                className={style.top}
                style={
                    padding
                    ? {
                        paddingTop: JSON.parse(padding).top + "px",
                        background: "url(" + counselBackground + ") no-repeat",
                        backgroundSize: "100%"
                    }
                    : {
                        background: "url(" + counselBackground + ") no-repeat",
                        backgroundSize: "100%"
                    }}
                >
                {this.props.from != FROM.WXMINI?<NavBar
                    icon={<Icon onClick={() => this.props.history.goBack()} color="#fff" type="left" />}
                    onLeftClick={() => console.log('onLeftClick')}
                    className={style.NavBar}
                    mode="dark"
                    >{breadcrumbNameMap[url]}</NavBar>: null}
                    <div className={style.scorll}
                    ref={divref}
                    onScroll={(e) => this.scroll(divref)}
                    style={{height: padding? 'calc(100vh - ' + (45 + Number(JSON.parse(padding).top)) + 'px)':'calc(100vh - 45px)'}}>
                    <div className={this.state.bodyStyle.join(' ')}>
                    <span className={style.articleTitle}>{title}</span>
                    {createTime && createTime !== ''?<span className={style.articleTime}>发布时间：{createTime}</span>: null}
                    {this.state.location.component === 'promo'?
                    <div className={style.radio}>
                        <video src={pathUrl} width="100%" controls></video>
                    </div>: 
                    <div>
                        <div className={style.content} dangerouslySetInnerHTML={{__html: content? content: ''}}></div>
                        {fileUrl && fileUrl.length > 0? 
                            <div style={{textAlign:"left", marginLeft:"5vw", wordBreak:"break-all"}}>
                            <div>附件下载</div>
                            {fileUrl.map((file: fileDetail) => (
                                <div key={file.id} onClick={() => this.downLoad(file.filePath)}>{
                                    this.props.from === FROM.APP? <a>{file.filename}</a>: 
                                    <a href={file.filePath.replace('https://oos-sccd.ctyunapi.cn/', 'http://ybyoss.seaeagletech.com/')}>{file.filename}</a>
                                }
                                </div>
                            ))}
                            </div>
                        : null}
                    </div>
                    }
                    {more.length > 0 || up || down? 
                    <div className={style.AboutHomeTitle}>
                        <span><p>更多</p>内容</span>
                    </div>: ''}
                    {more.map(item => (
                        <div className={style.otherNew} key={item.id} onClick={() => this.jump(item.id)}>
                            {item.imgUrl && item.imgUrl !== ''? <div className={style.otherNewImg} style={{background:"url('" + item.imgUrl + "') no-repeat",backgroundPosition: "center",backgroundSize: ' auto 100%'}}></div>: null}
                            <div className={style.otherNewTitle} style={item.imgUrl && item.imgUrl !== '' && item.imgUrl !== null?{}: {width: '90%'}}>{item.title}</div>
                            <div className={style.otherNewTime}>{item.createTime}</div>
                            <div className={style.otherNewContent} style={item.imgUrl && item.imgUrl !== '' && item.imgUrl !== null?{}: {width: '90%'}}>{item.content}</div>
                        </div>
                    ))}
                    {down.title?<div className={style.otherNew} key={down.id} onClick={() => this.jump(down.id)}>
                            {down.imgUrl && down.imgUrl !== ''? <div className={style.otherNewImg} style={{background:"url('" + down.imgUrl + "') no-repeat",backgroundPosition: "center",backgroundSize: ' auto 100%'}}></div>: null}
                            <div className={style.otherNewTitle} style={down.imgUrl && down.imgUrl !== '' && down.imgUrl !== null?{}: {width: '90%'}}>{down.title}</div>
                            <div className={style.otherNewTime}>{down.createTime}</div>
                            <div className={style.otherNewContent} style={down.imgUrl && down.imgUrl !== '' && down.imgUrl !== null?{}: {width: '90%'}}>{down.content}</div>
                        </div>: <div></div>}
                    {up.title?<div className={style.otherNew} key={up.id} onClick={() => this.jump(up.id)}>
                            {up.imgUrl && up.imgUrl !== ''? <div className={style.otherNewImg} style={{background:"url('" + up.imgUrl + "') no-repeat",backgroundPosition: "center",backgroundSize: ' auto 100%'}}></div>: null}
                            <div className={style.otherNewTitle} style={up.imgUrl && up.imgUrl !== '' && up.imgUrl !== null?{}: {width: '90%'}}>{up.title}</div>
                            <div className={style.otherNewTime}>{up.createTime}</div>
                            <div className={style.otherNewContent} style={up.imgUrl && up.imgUrl !== '' && up.imgUrl !== null?{}: {width: '90%'}}>{up.content}</div>
                        </div>: <div></div>}
                </div>
                </div>
            </div>
            </div>
        );
  }
}

const mapStateToProps = (
    state: LoginInfo
  ): {
    from: FROM | null;
    padding?: string;
  } => ({
    from: state.from,
    padding: state.padding
  });

  export default connect(mapStateToProps)(DetailPage);
