import React, { ComponentClass } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Icon, NavBar } from "antd-mobile";
import style from "./index.module.css";
import { FROM } from "../../store/enmu";
import { Dispatch } from "redux";
import { login, signout } from "../../store/actions";
import { LoginInfo, UserInfo as user } from "../../store/type";
import { get } from "../../axios/axios";
interface IProps extends RouteComponentProps, LoginInfo {
  onSignout: () => {};
  onLogin: (userinfo: user, token: string) => {};
}
interface IState {
  detail?: listType;
}
interface listType {
  period: string;
  date: string;
  accompanyNum: number;
  abnormalType: string;
  status: string;
  reservationId: number;
  createTime: string;
  userName: string;
  idCard: string;
  phone: string;
}
const mapDispatchToProps = (dispatch: Dispatch) => ({
  onSignout: () => dispatch(signout()),
  onLogin: (userinfo: user, token: string) => dispatch(login(userinfo, token)),
});
const mapStateToProps = (
  state: LoginInfo
): {
  userInfo?: user;
  from: FROM | null;
  padding?: string;
  userToken: string;
} => ({
  userInfo: state.userInfo,
  from: state.from,
  padding: state.padding,
  userToken: state.userToken as string,
});

class ReservationDetail extends React.PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {};
    this.getData();
  }
  render() {
    const { detail } = this.state;
    const userInfo = this.props.userInfo;

    return (
      <div className={style.ReservationDetail}>
        <div
          style={
            this.props.padding
              ? { marginTop: JSON.parse(this.props.padding).top + "px" }
              : {}
          }
        >
          {this.props.from != FROM.WXMINI?<NavBar
            className={style.navBar}
            icon={
              <Icon type="left" onClick={() => this.props.history.goBack()} />
            }
          >
            预约详情
          </NavBar>: null}
        </div>
        {detail && userInfo ? (
          <div className={style.main}>
            <div className={style.smallText}>入园时间</div>
            <div className={style.input}>
              <span>预约时间</span>
              <span>{detail.date}</span>
            </div>
            <div className={style.input}>
              <span>下单时间</span>
              <span>{this.formatDate(detail.createTime)}</span>
            </div>
            <div className={style.input}>
              <span>状态</span>
              <span>
                {(() => {
                  switch (detail.status) {
                    case "0":
                      return <span className={style.previewText}>已预约</span>;
                    case "1":
                      return <span>已过期</span>;
                    case "2":
                      return <span>已取消</span>;
                  }
                })()}
              </span>
            </div>
            <div className={style.smallText}>人员信息</div>
            <div className={style.input}>
              <span>姓名</span>
              <span>{detail.userName}</span>
            </div>
            <div className={style.input}>
              <span>身份证</span>
              <span>{userInfo.idCard}</span>
            </div>
            <div className={style.input}>
              <span>手机号</span>
              <span>{userInfo.phone}</span>
            </div>
            <div className={style.input}>
              <span>有无异常</span>
              <span>
                {(() => {
                  switch (detail.abnormalType) {
                    case "0":
                      return "无症状";
                    case "1":
                      return "发热";
                    case "2":
                      return "咳嗽";
                    case "3":
                      return "乏力";
                  }
                })()}{" "}
              </span>
            </div>
            <div className={style.input}>
              <span>随行人数</span>
              <span>{detail.accompanyNum}</span>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
  formatDate(time: string) {
    let curTime = new Date(parseInt(time));
    let year = curTime.getFullYear();
    let month =
      curTime.getMonth() + 1 < 10
        ? "0" + (curTime.getMonth() + 1)
        : curTime.getMonth() + 1;
    let day =
      curTime.getDate() < 10 ? "0" + curTime.getDate() : curTime.getDate();
    let hour =
      curTime.getHours() < 10 ? "0" + curTime.getHours() : curTime.getHours();
    let minute =
      curTime.getMinutes() < 10
        ? "0" + curTime.getMinutes()
        : curTime.getMinutes();
    let second =
      curTime.getSeconds() < 10
        ? "0" + curTime.getSeconds()
        : curTime.getSeconds();
    let datetime =
      year + "-" + month + "-" + day + " " + hour + ":" + minute + ":" + second;
    return datetime;
  }
  getData = async () => {
    let path = this.props.location.pathname;
    let array = path.split("/");
    let id = array[array.length - 1];
    let res = await get("/api/user/reservedetails", {
      reservationId: parseInt(id),
    });
    console.log(res);

    this.setState({
      detail: res.data,
    });
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter<any, ComponentClass<IProps>>(ReservationDetail));
