import { Icon, NavBar } from "antd-mobile";
import React, { ComponentClass } from "react";
import { connect } from "react-redux";
import { RouterProps, withRouter } from "react-router";
import { get, post } from "../../axios/axios";
import { FROM } from "../../store/enmu";
import { LoginInfo, UserInfo } from "../../store/type";
import style from "./index.module.css";
import list from "../../asset/amounts/used.png"
import noData from "../../asset/amountsExchange/noData.png"

interface IProps extends RouterProps, LoginInfo { }
interface IState {
    amountsList: IAmounts[]
}

interface IAmounts {
    coupAmt: string,
    coupId: string,
    coupName: string,
    couponType: string,
    lastDate: string,
    sendDate: string,
    sta: string,
    ticketCode: string
}

class AmountsHistory extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            amountsList: []
        }
        this.getData();
    }
    async getData() {
        let res = await post('/api/user/vipCouponQueryApi', {
            mobileNo: this.props.userInfo?.phone
        });
        if (res.code === 200) {
            this.setState({
                amountsList: res.data.couponList
            });
        }
    }
    render() {
        const { amountsList } = this.state;
        return (
            <div className={style.amounts}>
                <div
                    className={style.amountsHeader}
                    style={
                        this.props.padding ? { marginTop: JSON.parse(this.props.padding).top + "px" } : {}
                    }
                >
                    {this.props.from != FROM.WXMINI ? <NavBar
                        className={style.navBar}
                        icon={
                            <Icon type="left" onClick={() => this.props.history.goBack()} />
                        }
                    >
                        礼金券使用记录
                    </NavBar> : null}
                    {amountsList.filter(v => v.sta == "1").length > 0 ? amountsList.map((rule: IAmounts, index) => (
                        rule.sta === "1" ? (
                            <div className={style.amountsCard} key={index} style={{ background: 'url(' + list + ') no-repeat', backgroundSize: '100%' }}>
                                <div className={style.cardLeft}>
                                    <div className={style.cardTitle}>{rule.coupName}</div>
                                    <div className={style.data}>有效期至{rule.lastDate}</div>
                                </div>
                                <div className={style.cardRight}>
                                    <div className={style.couamt}>
                                        <div className={style.couamtTitle}>￥</div>
                                        <div className={style.couamtNumber}>{rule.coupAmt}</div>
                                    </div>
                                </div>
                            </div>) : null)) :
                        <img className={style.noDataPic} src={noData} alt="" />
                    }
                    {/* {amountsList.map((rule: IAmounts, index) => (
                        rule.sta === "1" ? (
                            <div className={style.amountsCard} key={index} style={{ background: 'url(' + list + ') no-repeat', backgroundSize: '100%' }}>
                                <div className={style.cardLeft}>
                                    <div className={style.cardTitle}>{rule.coupName}</div>
                                    <div className={style.data}>有效期至{rule.lastDate}</div>
                                </div>
                                <div className={style.cardRight}>
                                    <div className={style.couamt}>
                                        <div className={style.couamtTitle}>￥</div>
                                        <div className={style.couamtNumber}>{rule.coupAmt}</div>
                                    </div>
                                </div>
                            </div>) : null))
                    } */}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (
    state: LoginInfo
): {
    from: FROM | null;
    padding?: string;
    userInfo?: UserInfo;
} => ({
    from: state.from,
    padding: state.padding,
    userInfo: state.userInfo
});
export default connect(mapStateToProps)(withRouter<any, ComponentClass<IProps>>(AmountsHistory));