import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { RouterProps, withRouter } from "react-router";
import { FROM } from "../../store/enmu";
import { LoginInfo } from "../../store/type";
import style from './index.module.css';
import counselBackground from '../../asset/counsel/counselBackground.png';
import { Icon, NavBar } from "antd-mobile";
import React from "react";
import { content, IContentListProps } from "../ContentList";
import OptionTitle, { titleInfo } from "../../components/OptionTitle";
import { get,post } from "../../axios/axios";
import { TouristNoticeRouter } from "../../routers";
import { Redirect, Route, Switch } from "react-router-dom";
import { ymd } from "../../components/ymd";
import { IContentPageProps } from "../ContentPage";
import PagingLoad from "../../components/PagingLoad";
import useMounted from "../../components/hooks/useMounted";
interface IProps extends RouterProps,LoginInfo {

}
const scorllDiv = React.createRef<HTMLDivElement>()
const contentDiv = React.createRef<HTMLDivElement>()
const OptionTitleRef = React.createRef<OptionTitle>()

const titleList = [{
  title: '开放时间',
  path: '/contentPage/searchOpeningTime'    
}, {
  title: '入园须知',
  path: '/contentList/notice'    
}, {
  title: '路线推荐',
  path: '/contentList/line'    
}, {
  title: '交通服务',
  path: '/contentList/trafficservice'    
}, {
  title: '常见问题',
  path: '/contentList/commonproblem',
}]
/**
 *（点击checkOption/加载页面） -> props.history.location.pathname -> active变化 -> settings/pageNum触发请求
 *  pageNum变化 -> 触发请求
*/
const TouristNotice: React.FC<IProps> = (props: IProps) => {
    const [titleFixed, setTitleFixed] = useState(false);
    const [optioneTitleStyle, setOptioneTitleStyle] = useState([style.optioneTitle]);
    const [bodyStyle, setBodyStyle] = useState([style.body, style.bodyOnTop]);
    const [atBottom, setAtBottom] = useState(false);
    const [active, setActive] = useState(0); 
    const [pageSize, setPageSize] = useState(10);
    const [pageNum, setPageNum] = useState(1);
    const [settings, setSettings] = useState({
        dataList: [] as content[],
    } as IContentListProps | IContentPageProps);
    const [loading, setLoading] = useState(false);
    const [total, setTotal] = useState(0);
    useMounted(() => {
        OptionTitleRef.current?.refreshScroll();
    })
    // 路由改变触发的effect
    useEffect(() => {
        if(!loading){
            setTotal(0);
            setPageNum(1);
            switch(props.history.location.pathname) {
                case '/touristNotice/contentPage/searchOpeningTime': 
                    setActive(0);
                    setSettings({content: ''})
                    break;
                case '/touristNotice/contentList/notice': 
                    setActive(1);
                    setSettings({
                        dataList: [] as content[],
                        goItem: (id: number) => {
                            props.history.push(`/detail/touristNotice/notice/${id}`);
                        }
                    })
                    break;
                case '/touristNotice/contentList/line': 
                    setActive(2);
                    setSettings({
                        dataList: [] as content[],
                        goItem: (id: number) => {
                            props.history.push(`/detail/touristNotice/line/${id}`);
                        }
                    })
                    break;
                case '/touristNotice/contentList/trafficservice': 
                    setActive(3);
                    setSettings({
                        dataList: [] as content[],
                        goItem: (id: number) => {
                            props.history.push(`/detail/touristNotice/trafficservice/${id}`);
                        }
                    })
                    break;
                case '/touristNotice/contentList/commonproblem': 
                    setActive(4);
                    setSettings({
                        dataList: [] as content[],
                        goItem: (id: number) => {
                            props.history.push(`/detail/touristNotice/commonproblem/${id}`);
                        }
                    })
                    break;
            }         
        }
    }, [props.history.location.pathname]);
    useEffect(() => {
        if(!loading &&(total === 0 || (settings as IContentListProps).dataList && total > (settings as IContentListProps).dataList.length)){
            setLoading(true);
            switch(props.history.location.pathname) {
                case '/touristNotice/contentPage/searchOpeningTime': 
                    getSearchOpeningTimeData()
                    break;
                case '/touristNotice/contentList/notice': 
                    getNoticeData()
                    break;
                case '/touristNotice/contentList/line': 
                    getLineData()
                    break;
                case '/touristNotice/contentList/trafficservice': 
                    getTrafficserviceData();
                    break;
                case '/touristNotice/contentList/commonproblem': 
                    getCommonproblemData();
                    break;       
            }
        }
    }, [active, pageNum]);
    const getSearchOpeningTimeData = async() => {
        const requestData = await get('/api/expoinformat/searchOpeningTime');
        setLoading(false);
        if(requestData.code === 200) {
            setSettings({
                content: requestData.data[0].openingHours
            })
            setTotal(requestData.message);
        }
    }
    const getNoticeData = async() => {
        const requestData = await get('/api/notice/list', {
            pageSize: pageSize,
            pageNum: pageNum,
        });
        setLoading(false);
        if(requestData.code === 200) {
            let result: content[] = []
            requestData.data.forEach((element: any) => {
                result.push({
                    id: element.noticeId,
                    title: element.noticeTitle,
                    content: element.noticeContent,
                    imgUrl: element.noticeThumbnail,
                    createTime: ymd(new Date(element.createtime))
                })
            });
            setSettings({
                dataList: [...(settings as IContentListProps).dataList, ...result],
                goItem: (id: number) => {
                    props.history.push(`/detail/touristNotice/notice/${id}`);
                }
            })
            setTotal(requestData.message);
        }
    }
    const getLineData = async() => {
        const requestData = await post('/api/line/list', {
            reviewStatus: 3,
            dispalyStatus: 1,
            pageSize: pageSize,
            pageNum: pageNum,
        });
        setLoading(false);
        if(requestData.code === 200) {
            let result: content[] = []
            requestData.data.forEach((element: any) => {
                result.push({
                    id: element.lineId,
                    title: element.lineTitle,
                    content: element.lineContent,
                    imgUrl: element.lineThumbnail,
                    createTime: ymd(new Date(element.createtime))
                })
            });
            setSettings({
                dataList: [...(settings as IContentListProps).dataList, ...result],
                goItem: (id: number) => {
                    props.history.push(`/detail/touristNotice/line/${id}`);
                }
            })
            setTotal(requestData.message);
        }
    }
    const getTrafficserviceData = async() => {
        const requestData = await post('/api/trafficservice/list', {
            pageSize: pageSize,
            pageNum: pageNum,
        });
        setLoading(false);
        if(requestData.code === 200) {
            let result: content[] = []
            requestData.data.forEach((element: any) => {
                result.push({
                  id: element.trafficId,
                  title: element.trafficTitle,
                  content: element.trafficContent,
                  imgUrl: element.trafficThumbnail,
                  createTime: ymd(new Date(element.createtime))
                })
            });
            setSettings({
                dataList: [...(settings as IContentListProps).dataList, ...result],
                goItem: (id: number) => {
                    props.history.push(`/detail/touristNotice/trafficservice/${id}`);
                }
            })
            setTotal(requestData.message);
        }
    }
    const getCommonproblemData = async() => {
        const requestData = await get('/api/commonproblem/list', {
            pageSize: pageSize,
            pageNum: pageNum,
        });
        setLoading(false);
        if(requestData.code === 200) {
            let result: content[] = [];
            requestData.data.forEach((element: any) => {
                result.push({
                    id: element.problemId,
                    title: element.problemTitle,
                    content: element.problemContent,
                    imgUrl: element.problemThumbnail,
                    createTime: ymd(new Date(element.createtime))
                })
            });
            setSettings({
                dataList: [...(settings as IContentListProps).dataList, ...result],
                goItem: (id: number) => {
                    props.history.push(`/detail/touristNotice/commonproblem/${id}`);
                }
            })
            setTotal(requestData.message);
        }
    }
    const scroll = (scorllDiv: React.RefObject<HTMLDivElement>) => {
        if(scorllDiv.current) {
            if(scorllDiv.current?.scrollTop >= scorllDiv.current.clientWidth / 2 - 30 - (props.padding ?JSON.parse(props.padding).top : 0)) {
                setTitleFixed(true);
                setOptioneTitleStyle([style.optioneFixedTitle])
            } else {                  
                setTitleFixed(false);
                setOptioneTitleStyle([style.optioneTitle])
            }
        }
        if(contentDiv.current && scorllDiv.current && scorllDiv.current.scrollTop >= contentDiv.current.clientHeight - window.screen.height - 45) {
            setAtBottom(true);
        } else {      
            setAtBottom(false);
        }
    }
    const checkOption = (title: titleInfo) => {
        if(!loading){
            props.history.replace('/touristNotice' + title.path)
            if(scorllDiv.current) {
                if(scorllDiv.current?.scrollTop >= scorllDiv.current.clientWidth / 2 - 30 - (props.padding ?JSON.parse(props.padding).top : 0)) {
                    scorllDiv.current.scrollTop = scorllDiv.current.clientWidth / 2 - 30 - (props.padding ?JSON.parse(props.padding).top : 0);
                }
            }
        }
    }
    return (
        <div className={style.Counsel}>
            <PagingLoad atBottom={atBottom} load={() => {setPageNum(pageNum + 1)}}>
                <div className={style.top}
                    style={
                    props.padding
                    ? {
                        paddingTop: JSON.parse(props.padding).top + "px",
                        background: "url(" + counselBackground + ") no-repeat",
                        backgroundSize: "100%"
                    }
                    : {
                        background: "url(" + counselBackground + ") no-repeat",
                        backgroundSize: "100%"
                    }}
                >
                {
                    props.from != FROM.WXMINI?<NavBar
                    icon={<Icon onClick={() => props.history.goBack()} color="#fff" type="left" />}
                    onLeftClick={() => console.log('onLeftClick')}
                    className={style.NavBar}
                    mode="dark"
                    >游客须知</NavBar>: null
                }
                    <div className={style.scorll}
                        ref={scorllDiv}
                        onScroll={() => scroll(scorllDiv)}
                        style={{height: props.padding? 'calc(100vh - ' + (45 + Number(JSON.parse(props.padding).top)) + 'px)':'calc(100vh - 45px)'}}>
                        <div className={bodyStyle.join(' ')} ref={contentDiv}>
                            <div className={optioneTitleStyle.join(" ")} style={{top: props.from === FROM.WXMINI? '0':(props.padding? (45 + Number(JSON.parse(props.padding).top)) + 'px':'45px')}}>
                                <OptionTitle active={active} titleList={titleList} showNumber={4.5} barfootWidth={7} ref={OptionTitleRef} onChange={(title:titleInfo) => checkOption(title)}/>
                            </div>
                            <div className={style.switchContent} style={{marginTop: titleFixed?'15vw': '0'}}></div>
                            <Switch>
                                {
                                    TouristNoticeRouter.children.map(router => (
                                        <Route path={router.path} render={() => {return <router.component {...settings}/>}} key={router.key}/>
                                    ))
                                }
                                <Redirect path="/" to="/touristNotice/contentPage/searchOpeningTime" />
                            </Switch>
                        </div>
                    </div>
                </div>
            </PagingLoad>
        </div>
    )
}
const mapStateToProps = (
    state: LoginInfo
  ): {
    from: FROM | null;
    padding?: string;
  } => ({
    from: state.from,
    padding: state.padding
  });
export default connect(mapStateToProps)(withRouter<any, FunctionComponent<IProps>>(TouristNotice));
export {titleList};