import { Carousel } from 'antd-mobile';
import React, { ComponentClass, FunctionComponent, SyntheticEvent, useCallback, useState } from 'react';
import { RouterProps, withRouter } from 'react-router-dom';
import style from './index.module.css'
import titleIcon from '../../asset/home/ybyx.png';
import SvgLog from '../SvgLog';
import { Image } from 'antd';
import { CompositionImage, ImageProps } from 'antd/lib/image';
import { ArrowRightOutlined } from '@ant-design/icons';

export interface ImpressionCarouselImg{
    impressionId?: number, //id
    impressionTitle?: string, //印象名称
    impressionThumbnail?: string // 图片地址
}
export interface IImpressionProps {
    imgList: ImpressionCarouselImg[]
}
interface IProps extends RouterProps{
    imgList: ImpressionCarouselImg[]
}

function Impression(props: IProps) {
    const [imgType, setImgType] = useState<Array<{
        width: number,
        height: number,
        h_w: boolean
    }>>([]);
    const jump = useCallback((url?: number) => {
        url && props.history.push(`/detail/information/impression/${url}`)
    }, [])
    const { imgList } = props;
    return (
        <div className={style.Impression}>
            <div className={style.ImpressionTitle}>
                <div className={style.ImpressionImg}>
                    <SvgLog name={'yx'} width='100%' height='100%' color='#4f4f4f' viewBox={'0 0 20 20'}/>
                </div>
                {/* <img className={style.ImpressionImg} width="100%" height="100%" src={titleIcon} alt="" /> */}
                <span><p>园博</p>印象</span>
                <ArrowRightOutlined style={{float:'right',color: '#acacac',lineHeight: '1.8'}} onClick={() => {props.history.push('/information/contentList/impression')}}/>
            </div>
            <div className={style.ImpressionCarousel}>
                <Carousel 
                cellSpacing={10}
                slideWidth={0.9}
                dotStyle={{display: 'none'}} 
                dotActiveStyle={{display: 'none'}}>
                    {imgList.map((val: ImpressionCarouselImg, index: number) => {
                        return (
                            <div key={index} className={style.imgBox} onClick={() => jump(val.impressionId)}>
                                <div className={style.premissionName}>{val.impressionTitle}</div>
                                <img src={val.impressionThumbnail}
                                    style={imgType[index] !== undefined? 
                                        (imgType[index] && imgType[index].h_w?
                                            {marginTop: `-${imgType[index].height / imgType[index].width * 45 - 28}vw`}:
                                            {marginLeft: `-${imgType[index].width / imgType[index].height * 28 - 45}vw`})
                                        :{}}
                                    width={imgType[index] && imgType[index].h_w? '100%': 'auto'}
                                    height={imgType[index] && imgType[index].h_w? 'auto': '100%'}
                                    onLoad={(e: SyntheticEvent<HTMLImageElement>) => {
                                        let IMGwh = imgType.concat();
                                        if(e.currentTarget.clientHeight/e.currentTarget.clientWidth > (56 / 90)) {
                                            IMGwh[index] = {
                                                h_w: true,
                                                width: e.currentTarget.clientWidth,
                                                height: e.currentTarget.clientHeight
                                            };
                                        } else {
                                            IMGwh[index] = {
                                                h_w: false,
                                                width: e.currentTarget.clientWidth,
                                                height: e.currentTarget.clientHeight
                                            };
                                        }
                                        setImgType(IMGwh);
                                    }}
                                    className={style.img}/>
                            </div>
                        );
                    })}
                </Carousel>
            </div>
        </div>
    );

}
// class Impression extends React.Component<IProps> {
//     // state = {
//     //   data: ['AiyWuByWklrrUDlFignR', 'TekJlZRVCjLFexlOCuWn', 'IJOtIlfsYdTyaDTRVrLI'],
//     //   imgHeight: 176,
//     // }
//     jump (url?: number) {
//         url && this.props.history.push(`/detail/information/impression/${url}`)
//     }
//     render () {
//         const { imgList } = this.props;
//         return (
//             <div className={style.Impression}>
//                 <div className={style.ImpressionTitle}>
//                     <div className={style.ImpressionImg}>
//                         <SvgLog name={'yx'} width='100%' height='100%' color='#4f4f4f' viewBox={'0 0 20 20'}/>
//                     </div>
//                     {/* <img className={style.ImpressionImg} width="100%" height="100%" src={titleIcon} alt="" /> */}
//                     <span><p>园博</p>印象</span>
//                 </div>
//                 <div className={style.ImpressionCarousel}>
//                     <Carousel 
//                     cellSpacing={10}
//                     slideWidth={0.9}
//                     dotStyle={{display: 'none'}} 
//                     dotActiveStyle={{display: 'none'}}>
//                         {imgList.map((val: ImpressionCarouselImg, index: number) => {
//                             return (
//                                 <div key={index} className={style.imgBox} onClick={() => this.jump(val.impressionId)}>
//                                     <Image src={val.impressionThumbnail} alt="" className={style.img}/>
//                                 </div>
//                             );
//                         })}
//                     </Carousel>
//                 </div>
//             </div>
//         );
//     }
// }

export default  withRouter<any, FunctionComponent<IProps>>(Impression);