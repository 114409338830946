import { get,post } from "../../axios/axios";
import { ymd } from "../../components/ymd";

interface KF {
    [key: string]: Function
}

async function news(id: number) {
    const requestData = await post('/api/news/details', {newId: id});if(requestData) {
        return {
            id: requestData.data.news.newId,
            title: requestData.data.news.newTitle,
            content: requestData.data.news.newContent,
            imgUrl: requestData.data.news.newThumbnail,
            createTime: ymd(new Date(requestData.data.news.createtime)),
            fileUrl: requestData.data.upload,
            up: requestData.data.up === ""? {}: {
                id: requestData.data.up.newId,
                title: requestData.data.up.newTitle,
                content: requestData.data.up.newContent,
                imgUrl: requestData.data.up.newThumbnail,
                createTime: ymd(new Date(requestData.data.up.createtime)),
            },
            down: requestData.data.down === ""? {}: {
                id: requestData.data.down.newId,
                title: requestData.data.down.newTitle,
                content: requestData.data.down.newContent,
                imgUrl: requestData.data.down.newThumbnail,
                createTime: ymd(new Date(requestData.data.down.createtime)),
            },
            more: requestData.data.more === ""? []: requestData.data.more.map((more: any) => {
                return {
                    id: more.newId,
                    title: more.newTitle,
                    content: more.newContent,
                    imgUrl: more.newThumbnail,
                    createTime: ymd(new Date(more.createtime)),
                }
            })
        }
    }
}
async function announcement(id: number) {
    const requestData = await post('/api/announcement/details', {announcementId: id});
    if(requestData) {
        return {
            id: requestData.data.announcement.announcementId,
            title: requestData.data.announcement.announcementTitle,
            content: requestData.data.announcement.announcementContent,
            imgUrl: requestData.data.announcement.announcementThumbnail,
            createTime: ymd(new Date(requestData.data.announcement.createtime)),
            fileUrl: requestData.data.upload,
            up: requestData.data.up === ""? {}: {
            id: requestData.data.up.announcementId,
            title: requestData.data.up.announcementTitle,
            content: requestData.data.up.announcementContent,
            imgUrl: requestData.data.up.announcementThumbnail,
            createTime: ymd(new Date(requestData.data.up.createtime)),
            },
            down: requestData.data.down === ""? {}: {
            id: requestData.data.down.announcementId,
            title: requestData.data.down.announcementTitle,
            content: requestData.data.down.announcementContent,
            imgUrl: requestData.data.down.announcementThumbnail,
            createTime: ymd(new Date(requestData.data.down.createtime)),
            },
            more: requestData.data.more === ""? []: requestData.data.more.map((more: any) => {
                return {
                    id: more.announcementId,
                    title: more.announcementTitle,
                    content: more.announcementContent,
                    imgUrl: more.announcementThumbnail,
                    createTime: ymd(new Date(more.createtime)),
                }
            })
        }
    }
}
async function party(id: number) {
    const requestData = await post('/api/party/details', {partyId: id});
    if(requestData) {
        return {
            id: requestData.data.party.partyId,
            title: requestData.data.party.partyTitle,
            content: requestData.data.party.partyContent,
            imgUrl: requestData.data.party.partyThumbnail,
            createTime: ymd(new Date(requestData.data.party.createtime)),
            fileUrl: requestData.data.upload,
            up: requestData.data.up === ""? {}: {
            id: requestData.data.up.partyId,
            title: requestData.data.up.partyTitle,
            content: requestData.data.up.partyContent,
            imgUrl: requestData.data.up.partyThumbnail,
            createTime: ymd(new Date(requestData.data.up.createtime)),
            },
            down: requestData.data.down === ""? {}: {
            id: requestData.data.down.partyId,
            title: requestData.data.down.partyTitle,
            content: requestData.data.down.partyContent,
            imgUrl: requestData.data.down.partyThumbnail,
            createTime: ymd(new Date(requestData.data.down.createtime)),
            },
            more: requestData.data.more === ""? []: requestData.data.more.map((more: any) => {
                return {
                    id: more.partyId,
                    title: more.partyTitle,
                    content: more.partyContent,
                    imgUrl: more.partyThumbnail,
                    createTime: ymd(new Date(more.createtime)),
                }
            })
        }
    }
}
async function event(id: number) {
    const requestData = await post('/api/event/details', {activityId: id});
    if(requestData) {
        return {
            id: requestData.data.activity.activityId,
            title: requestData.data.activity.activityTitle,
            content: requestData.data.activity.activityContent,
            imgUrl: requestData.data.activity.activityThumbnail,
            createTime: ymd(new Date(requestData.data.activity.createtime)),
            fileUrl: requestData.data.upload,
            up: requestData.data.up === ""? {}: {
            id: requestData.data.up.activityId,
            title: requestData.data.up.activityTitle,
            content: requestData.data.up.activityContent,
            imgUrl: requestData.data.up.activityThumbnail,
            createTime: ymd(new Date(requestData.data.up.createtime)),
            },
            down: requestData.data.down === ""? {}: {
            id: requestData.data.down.activityId,
            title: requestData.data.down.activityTitle,
            content: requestData.data.down.activityContent,
            imgUrl: requestData.data.down.activityThumbnail,
            createTime: ymd(new Date(requestData.data.down.createtime)),
            },
            more: requestData.data.more === ""? []: requestData.data.more.map((more: any) => {
                return {
                    id: more.activityId,
                    title: more.activityTitle,
                    content: more.activityContent,
                    imgUrl: more.activityThumbnail,
                    createTime: ymd(new Date(more.createtime)),
                }
            })
        }
    }
}
async function impression(id: number) {
    const requestData = await post('/api/impression/details', {impressionId: id});
    if(requestData) {
        return {
            id: requestData.data.impression.impressionId,
            title: requestData.data.impression.impressionTitle,
            content: requestData.data.impression.impressionContent,
            imgUrl: requestData.data.impression.impressionThumbnail,
            createTime: ymd(new Date(requestData.data.impression.createtime)),
            fileUrl: requestData.data.upload,
            up: requestData.data.up === ""? {}: {
            id: requestData.data.up.impressionId,
            title: requestData.data.up.impressionTitle,
            content: requestData.data.up.impressionContent,
            imgUrl: requestData.data.up.impressionThumbnail,
            createTime: ymd(new Date(requestData.data.up.createtime)),
            },
            down: requestData.data.down === ""? {}: {
            id: requestData.data.down.impressionId,
            title: requestData.data.down.impressionTitle,
            content: requestData.data.down.impressionContent,
            imgUrl: requestData.data.down.impressionThumbnail,
            createTime: ymd(new Date(requestData.data.down.createtime)),
            },
            more: requestData.data.more === ""? []: requestData.data.more.map((more: any) => {
                return {
                    id: more.impressionId,
                    title: more.impressionTitle,
                    content: more.impressionContent,
                    imgUrl: more.impressionThumbnail,
                    createTime: ymd(new Date(more.createtime)),
                }
            })
        }
    }
}
async function power(id: number) {
    const requestData = await post('/api/power/details', {raidersId: id});
    if(requestData) {
        return {
            id: requestData.data.raiders.raidersId,
            title: requestData.data.raiders.raidersTitle,
            content: requestData.data.raiders.raidersContent,
            imgUrl: requestData.data.raiders.raidersThumbnail,
            createTime: ymd(new Date(requestData.data.raiders.createtime)),
            fileUrl: requestData.data.upload,
            up: requestData.data.up === ""? {}: {
            id: requestData.data.up.raidersId,
            title: requestData.data.up.raidersTitle,
            content: requestData.data.up.raidersContent,
            imgUrl: requestData.data.up.raidersThumbnail,
            createTime: ymd(new Date(requestData.data.up.createtime)),
            },
            down: requestData.data.down === ""? {}: {
            id: requestData.data.down.raidersId,
            title: requestData.data.down.raidersTitle,
            content: requestData.data.down.raidersContent,
            imgUrl: requestData.data.down.raidersThumbnail,
            createTime: ymd(new Date(requestData.data.down.createtime)),
            },
            more: requestData.data.more === ""? []: requestData.data.more.map((more: any) => {
                return {
                    id: more.raidersId,
                    title: more.raidersTitle,
                    content: more.raidersContent,
                    imgUrl: more.raidersThumbnail,
                    createTime: ymd(new Date(more.createtime)),
                }
            })
        }
    }
}
const informationMap: KF = {
    news: news,
    announcement: announcement,
    party: party,
    event: event,
    impression: impression,
    power: power
}
export {informationMap};