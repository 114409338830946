import { Icon, NavBar } from "antd-mobile";
import React, { ComponentClass } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { LoginInfo, UserInfo } from "../../store/type";
import ticketOrder from "../../asset/mine/TicketOrder.png";
import hotelOrder from "../../asset/mine/hotelOrder.png";
import retailOrder from "../../asset/mine/retailOrder.png";
import rightArrow from "../../asset/mine/rightArrow.png";
import style from "./index.module.css";
import { FROM } from "../../store/enmu";

interface IProps extends LoginInfo, RouteComponentProps { }
interface IState { }

var wx = require('weixin-js-sdk');
declare var window: {
  [key: string]: any; // missing index defintion
  prototype: Window;
  new(): Window;
};
const mapStateToProps = (
  state: LoginInfo
): {
  userInfo?: UserInfo;
  from: FROM | null;
  padding?: string;
} => ({
  userInfo: state.userInfo,
  from: state.from,
  padding: state.padding,
});
class MyOrder extends React.PureComponent<IProps, IState> {
  toTicket() {
    if (localStorage.getItem('userToken') === null) {
      this.props.history.push('/main/mine');
      return;
    }
    if (this.props.from === FROM.WXMINI) {
      wx.miniProgram.navigateTo({
        url: '../jumpPage/jumpPage?type=7',
      });
    } else if (this.props.from === FROM.APP) {
      this.props.history.push('/OutURL/' + encodeURIComponent(`https://h5ticket.xzyby.cn/?access_token=${localStorage.getItem('userToken')}&type=2`) + '/我的订单')
    } else {
      this.props.history.push('/OutURL/' + encodeURIComponent(`https://wxticket.xzyby.cn/?access_token=${localStorage.getItem('userToken')}&type=2`) + '/我的订单')
    }
    return;
  }
  render() {
    return (
      <div className={style.MyOrder}>
        <div
          className={style.orderHeader}
          style={this.props.padding ? { marginTop: JSON.parse(this.props.padding).top + "px" } : {}}
        >
          {this.props.from != FROM.WXMINI ? <NavBar
            className={style.navBar}
            // icon={<Icon type="left" onClick={() => this.props.history.goBack()} />}
            icon={<Icon type="left" onClick={() => this.props.history.push('/main/mine')} />}
            onLeftClick={() => console.log("onLeftClick")}
          >
            我的订单
          </NavBar> : null}
        </div>
        <div className={style.main}>
          <div onClick={() => this.toTicket()}>
            <img src={ticketOrder} width="100%" height="100%" alt="" />
            <span>票务订单</span>
            <img src={rightArrow} alt="" />
          </div>
          {this.props.from !== FROM.H5 ?
            <div onClick={() => {
              if (this.props.from === FROM.APP) {
                window['jsGoToWxMini'].postMessage("{\"username\": \"gh_d6f0fa7afa9a\",\"path\": \"/pages/list/reserve/index\"}");
              } else if (this.props.from === FROM.WXMINI) {
                wx.miniProgram.navigateTo({
                  url: '../testPage/testPage',
                });
              }
            }}>
              <img src={hotelOrder} width="100%" height="100%" alt="" />
              <span>酒店订单</span>
              <img src={rightArrow} alt="" />
            </div> : null
          }
          <div onClick={() => this.props.history.push('/resaleOrder')}>
            <img src={retailOrder} width="100%" height="100%" alt="" />
            <span>零售订单</span>
            <img src={rightArrow} alt="" />
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(
  withRouter<any, ComponentClass<IProps, IState>>(MyOrder)
);
