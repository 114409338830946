import React, { ComponentClass, FunctionComponent, SyntheticEvent, useState } from 'react';
import { RouterProps } from 'react-router';
import style from './index.module.css'
import titleIcon from '../../asset/home/ykgl.png';
import { withRouter } from 'react-router-dom';
import SvgLog from '../SvgLog';
import { ArrowRightOutlined } from '@ant-design/icons';

interface Strategy {
    raidersId: string,
    raidersTitle: string,
    raidersContent: string,
    raidersThumbnail: string,
}
interface IProps extends RouterProps{
    strategyList: Strategy[]
}
export interface IStrategyProps {
    strategyList: Strategy[]
}
function Strategy(props: IProps) {
    const [imgType, setImgType] = useState<Array<{
        width: number,
        height: number,
        h_w: boolean
    }>>([]);
    const { strategyList } = props;
    const title = strategyList.length>0?strategyList[0]: null
    return (
        <div className={style.Strategy}>
            <div className={style.StrategyTitle}>
                <div className={style.StrategyImg}>
                    <SvgLog name={'gl'} width='100%' height='100%' color='#4f4f4f' viewBox={'0 0 20 20'}/>
                </div>
                <span><p>游客</p>攻略</span>
                <ArrowRightOutlined style={{float:'right',color: '#acacac',lineHeight: '1.8'}} onClick={() => {props.history.push('/information/contentList/guide')}}/>
            </div>
            {title!==null?
                <div className={style.titleCard} onClick={() =>{ console.log(`/detail/guide/guide/${title.raidersId}`);props.history.push(`/detail/guide/guide/${title.raidersId}`)}}>
                    <div className={style.titleImgBox}>
                        <img src={strategyList[0].raidersThumbnail}
                            style={imgType[0] !== undefined? 
                                (imgType[0] && imgType[0].h_w?
                                    {marginTop: `-${imgType[0].height / imgType[0].width * 45 - 25}vw`}:
                                    {marginLeft: `-${imgType[0].width / imgType[0].height * 25 - 45}vw`})
                                :{}}
                            width={imgType[0] && imgType[0].h_w? '100%': 'auto'}
                            height={imgType[0] && imgType[0].h_w? 'auto': '100%'}
                            onLoad={(e: SyntheticEvent<HTMLImageElement>) => {
                                let IMGwh = imgType.concat();
                                if(e.currentTarget.clientHeight/e.currentTarget.clientWidth > (50 / 90)) {
                                    IMGwh[0] = {
                                        h_w: true,
                                        width: e.currentTarget.clientWidth,
                                        height: e.currentTarget.clientHeight
                                    };
                                } else {
                                    IMGwh[0] = {
                                        h_w: false,
                                        width: e.currentTarget.clientWidth,
                                        height: e.currentTarget.clientHeight
                                    };
                                }
                                setImgType(IMGwh);
                            }}
                            className={style.img}/>
                    </div>
                    <div className={style.topTitle}>{title.raidersTitle}</div>
                    <div className={style.topContent} dangerouslySetInnerHTML={{__html: title.raidersContent}}></div>
                </div>
            : null}
            <div className={style.leftBox}>
                {strategyList.map((strategy,index) => {return index%2 === 1?(
                    <div className={style.Card} key={index} onClick={() => {console.log(`/detail/guide/guide/${strategy.raidersId}`);props.history.push(`/detail/guide/guide/${strategy.raidersId}`)}}>
                        <div className={style.imgBox}>
                            <img src={strategy.raidersThumbnail}
                                style={imgType[index] !== undefined? 
                                    (imgType[index] && imgType[index].h_w?
                                        {marginTop: `-${imgType[index].height / imgType[index].width * 22 - 19}vw`}:
                                        {marginLeft: `-${imgType[index].width / imgType[index].height * 19 - 22}vw`})
                                    :{}}
                                width={imgType[index] && imgType[index].h_w? '100%': 'auto'}
                                height={imgType[index] && imgType[index].h_w? 'auto': '100%'}
                                onLoad={(e: SyntheticEvent<HTMLImageElement>) => {
                                    let IMGwh = imgType.concat();
                                    if(e.currentTarget.clientHeight/e.currentTarget.clientWidth > (38 / 44)) {
                                        IMGwh[index] = {
                                            h_w: true,
                                            width: e.currentTarget.clientWidth,
                                            height: e.currentTarget.clientHeight
                                        };
                                    } else {
                                        IMGwh[index] = {
                                            h_w: false,
                                            width: e.currentTarget.clientWidth,
                                            height: e.currentTarget.clientHeight
                                        };
                                    }
                                    setImgType(IMGwh);
                                }}
                                className={style.img}/>
                        </div>
                       { strategy.raidersTitle.length>6?(<div className={style.title}>{ strategy.raidersTitle.substr(0,4) + "..."}</div>):
                       (<div className={style.title}>{ strategy.raidersTitle}</div>)
                        }
                        <div className={style.content}>{strategy.raidersContent}</div>
                    </div>
                ): null})}
            </div>
            <div className={style.rightBox}>
                {strategyList.map((strategy,index) => {return index%2 === 0 && index !== 0 ?(
                    <div className={style.Card} key={index} onClick={() => props.history.push(`/detail/guide/guide/${strategy.raidersId}`)}>
                        <div className={style.imgBox} >
                            <img src={strategy.raidersThumbnail}
                                style={imgType[index] !== undefined? 
                                    (imgType[index] && imgType[index].h_w?
                                        {marginTop: `-${imgType[index].height / imgType[index].width * 22 - 19}vw`}:
                                        {marginLeft: `-${imgType[index].width / imgType[index].height * 19 - 22}vw`})
                                    :{}}
                                width={imgType[index] && imgType[index].h_w? '100%': 'auto'}
                                height={imgType[index] && imgType[index].h_w? 'auto': '100%'}
                                onLoad={(e: SyntheticEvent<HTMLImageElement>) => {
                                    let IMGwh = imgType.concat();
                                    if(e.currentTarget.clientHeight/e.currentTarget.clientWidth > (38 / 44)) {
                                        IMGwh[index] = {
                                            h_w: true,
                                            width: e.currentTarget.clientWidth,
                                            height: e.currentTarget.clientHeight
                                        };
                                    } else {
                                        IMGwh[index] = {
                                            h_w: false,
                                            width: e.currentTarget.clientWidth,
                                            height: e.currentTarget.clientHeight
                                        };
                                    }
                                    setImgType(IMGwh);
                                }}
                                className={style.img}/>
                        </div>
                        { strategy.raidersTitle.length>6?(<div className={style.title}>{ strategy.raidersTitle.substr(0,4) + "..."}</div>):
                       (<div className={style.title}>{ strategy.raidersTitle}</div>)
                        }
                        <div className={style.content}>{strategy.raidersContent}</div>
                    </div>
                ): null})}
            </div>
        </div>
    )
}

export default withRouter<any, FunctionComponent<IProps>>(Strategy);