import { Icon, NavBar, Toast } from "antd-mobile";
import React, { useCallback, useEffect, useReducer, useState } from "react";
import { RouterProps } from "react-router";
import { get } from "../../axios/axios";
import { FROM } from "../../store/enmu";
import reducter, { loginInfo } from "../../store/reducter";
import style from "./index.module.css";
import title from "../../asset/paycode/title.png";
import { RouteComponentProps } from 'react-router-dom';

interface IProps extends RouteComponentProps { }

const PayCode: React.FC<IProps> = (props: IProps) => {
    const [url, setUrl] = useState('');
    const [state] = useReducer(reducter, loginInfo);
    let top = JSON.parse(localStorage.getItem('padding') as string) ? JSON.parse(localStorage.getItem('padding') as string).top : ''

    const getUrl = useCallback(async () => {
        let params = props.match.params as { ticketCode?: string }
        let res = await get('/api/user/getTicketCode', {
            ticketCode: params?.ticketCode
        })
        if (res.code === 200) {
            setUrl(res.data)
        }
    }, []);

    useEffect(() => { getUrl() }, [])
    
    return (
        <div className={style.PayCode}>
            <div
                className={style.orderHeader}
                style={top ? { marginTop: top + "px" } : {}}
            >
                {state.from != FROM.WXMINI ? <NavBar
                    className={style.navBar}
                    icon={
                        <Icon type="left" onClick={() => props.history.goBack()} />
                    }
                    onLeftClick={() => console.log("onLeftClick")}
                >
                    礼金劵
                </NavBar> : null}
            </div>
            <div className={style.PayCodeBox}>
                <div className={style.headTitle}><img className={style.titleImg} src={title} width='10vw' height='10vw' />徐州园博园</div>
                <div className={style.goldTop}></div>
                <div className={style.leftBoll}></div>
                <div className={style.rightBoll}></div>
                {url === '' ? <div className={style.paycode}>二维码加载中,请稍侯...</div> : <img className={style.paycode} src={url} width="100%" height="100%" alt="" />}
            </div>
        </div>
    );
}

export default PayCode;