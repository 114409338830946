import { Icon, NavBar, Tabs, ListView } from "antd-mobile";
import React, { ComponentClass } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { LoginInfo, UserInfo } from "../../store/type";
import style from "./index.module.css";
import { get, post } from "../../axios/axios";
import { PhoneOutlined } from "@ant-design/icons";
import { FROM } from "../../store/enmu";
interface IProps extends LoginInfo, RouteComponentProps { }
interface IState {
  list: [];
  telephone: string;
  dataSource: any
}
declare var window: {
  [key: string]: any; // missing index defintion
  prototype: Window;
  new(): Window;
};
const mapStateToProps = (
  state: LoginInfo
): {
  userInfo?: UserInfo;
  from: FROM | null;
  padding?: string;
} => ({
  userInfo: state.userInfo,
  from: state.from,
  padding: state.padding,
});
class ComplaintListList extends React.PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    const dataSource = new ListView.DataSource({
      rowHasChanged: (row1: any, row2: any) => row1 !== row2
    })
    this.state = {
      list: [],
      telephone: '',
      dataSource: dataSource,
    };
    this.getData();
  }
  getData = async () => {
    if (this.props.userInfo) {
      let userId = this.props.userInfo.userId;
      let res = await post("/api/complaints/historylist", {
        userId: userId,
        complaintTypeId: 1,
        pageSize: 0
      });
      this.setState({
        list: res.data,
      });
      let telephone = await get('/api/phone/details')
      this.setState({
        telephone: telephone.data.complaintPhone,
        dataSource: this.state.dataSource.cloneWithRows(res.data),
      })
    }
  };
  jump(url?: number) {
    url && this.props.history.push(`/detail/complaint/${url}`);
  }
  tel(phone: string) {
    if (this.props.from === FROM.APP) {
      window['jsTel'].postMessage(phone);
    }
  }
  render() {
    const { list } = this.state;

    const row = (rowData: any, sectionID: any, rowID: any) => {
      let titleText;
      if (rowData.title !== undefined && rowData.title !== "" && rowData.title !== null) {
        titleText = rowData.title as string;
        console.log(titleText.length)
        if (titleText.length > 12) {
          titleText = titleText.substr(0, 12) + "...";
        }
      }

      return (
        <div className={style.main}>
          <div className={style.adviceBox}>
            <span>{titleText}</span>
            <div
              className={style.rightText}
              onClick={() => this.jump(rowData.complaintId)}
            >
              {
                rowData.content === "undefined" || rowData.content === null || rowData.content === "" ?
                  null : (
                    (rowData.status === 0) ?
                      (<span className={style.unReplyText}>未回复</span>)
                      :
                      (<span className={style.replyText}>已回复</span>)
                  )
              }
              <Icon type="right" />
            </div>
          </div>
        </div>
      )
    };

    const separator = (sectionID: any, rowID: any) => (
      <div
        key={`${sectionID}-${rowID}`}
        style={{
          height: 10,
          backgroundColor: '#f4f4f4',
          // backgroundColor: '#F5F5F9',
          // height: 8,
          // borderTop: '1px solid #ECECED',
          // borderBottom: '1px solid #ECECED',
        }}
      />
    );

    return (
      <div className={style.complaintList}>
        <div
          className={style.orderHeader}
          style={
            this.props.padding
              ? { marginTop: JSON.parse(this.props.padding).top + "px" }
              : {}
          }
        >
          {this.props.from != FROM.WXMINI ? <NavBar
            className={style.navBar}
            icon={
              <Icon type="left" onClick={() => this.props.history.goBack()} />
            }
          >
            投诉举报
          </NavBar> : null}
        </div>

        <div className={style.list}>
          <ListView
            // ref={el => this.lv = el}
            dataSource={this.state.dataSource}
            //renderHeader={() => <span>header</span>}
            renderSeparator={separator}
            renderRow={row}
            className="list1"
            pageSize={5}
            useBodyScroll
            onScroll={() => { console.log('scroll'); }}
            scrollRenderAheadDistance={500}
            onEndReachedThreshold={10}
            initialListSize={0}
          // style={{height:"70%" ,overflow:"hidden"}}
          />
        </div>
        <div className={style.footer}>
          <span onClick={() => this.tel(this.state.telephone)}>
            <PhoneOutlined />
            {this.props.from === FROM.APP ?
              <a>电话投诉</a>
              : <a href={"tel: " + this.state.telephone}>电话投诉</a>}
          </span>
          <span onClick={() => this.props.history.push("/complaint")}>
            我要投诉
          </span>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(
  withRouter<any, ComponentClass<IProps, IState>>(ComplaintListList)
);
