import { Icon, NavBar, Tabs } from "antd-mobile";
import React, { ComponentClass } from "react";
import { connect } from "react-redux";
import { RouterProps, withRouter } from "react-router";
import { get, post } from "../../axios/axios";
import { FROM } from "../../store/enmu";
import { LoginInfo, UserInfo } from "../../store/type";
import style from "./index.module.css";
import list from "../../asset/amounts/list.png"
import noData from "../../asset/amountsExchange/noData.png"
import past from "../../asset/amountsExchange/past.png"
import used from "../../asset/amountsExchange/used.png"


interface IProps extends RouterProps, LoginInfo { }
interface IState { amountsList: IAmounts[] }
interface IAmounts {
    coupAmt: string,
    coupId: string,
    coupName: string,
    couponType: string,
    lastDate: string,
    sendDate: string,
    sta: string,
    ticketCode: string
}
var wx = require('weixin-js-sdk');

class Amounts extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            amountsList: []
        }
        this.getData();
    }

    async getData() {
        let res = await post('/api/user/vipCouponQueryApi', {
            mobileNo: this.props.userInfo?.phone
        });
        if (res.code === 200) {
            this.setState({
                amountsList: res.data.couponList
            });
        }
    }

    jump(url: string) {
        console.log(this.props)
        if (this.props.from === FROM.WXMINI) {
            wx.miniProgram.navigateTo({
                url: '../jumpPage/jumpPage?url=' + `https://h5.xzyby.cn/#/wxminiOpen/${this.props.userToken}/${encodeURIComponent(url)}`,
            });
        } else {
            this.props.history.push(url)
        }
    }

    // getData = async (type: number) => {
    //     let res = await post("/api/complaints/historylist", {
    //         complaintTypeId: type,
    //         status: 1,
    //     });

    //     this.setState({
    //         list: res.data,
    //     });
    // };

    changeTabs = (e: any) => {
        // if (e.title === "全部") {
        //     this.getData(5);
        // } else if (e.title === "寻人") {
        //     this.getData(3);
        // } else {
        //     this.getData(4);
        // }
    };

    render() {
        const { amountsList } = this.state;
        return (
            <div className={style.amounts}>
                <div
                    className={style.amountsHeader}
                    style={
                        this.props.padding
                            ? { marginTop: JSON.parse(this.props.padding).top + "px" }
                            : {}
                    }
                >
                    {this.props.from != FROM.WXMINI ? <NavBar
                        className={style.navBar}
                        icon={
                            <Icon type="left" onClick={() => this.props.history.goBack()} />
                        }
                    >礼金券</NavBar> : null}
                    <Tabs
                        tabs={[{ title: "未使用" }, { title: "已使用" }, { title: "已过期" }]}
                        initialPage={0}
                        animated={false}
                        useOnPan={false}
                        onChange={this.changeTabs}
                    >
                        <div>
                            {amountsList.filter(v => v.sta == "0").length > 0 ? amountsList.map((rule: IAmounts, index) => (
                                rule.sta === "0" ? (
                                    <div className={style.amountsCard} key={index} style={{ background: 'url(' + list + ') no-repeat', backgroundSize: '100%' }}>
                                        <div className={style.cardLeft}>
                                            <div className={style.cardTitle}>{rule.coupName}</div>
                                            <div className={style.data}>有效期至{rule.lastDate}</div>
                                        </div>
                                        <div className={style.cardRight}>
                                            <div className={style.couamt}>
                                                <div className={style.couamtTitle}>￥</div>
                                                <div className={style.couamtNumber}>{rule.coupAmt}</div>
                                            </div>
                                            {/* <div className={style.exchange} onClick={() => { this.jump('/giftCode/' + rule.ticketCode) }}>立即使用</div> */}
                                        </div>
                                    </div>) : null))
                                : <img className={style.noDataPic} src={noData} alt="" />}
                        </div>
                        <div>
                            {amountsList.filter(v => v.sta == "1").length > 0 ? amountsList.map((rule: IAmounts, index) => (
                                rule.sta === "1" ? (
                                    <div className={style.amountsCard} key={index} style={{ background: 'url(' + used + ') no-repeat', backgroundSize: '100%' }}>
                                        <div className={style.cardLeft}>
                                            <div className={style.cardTitle}>{rule.coupName}</div>
                                            <div className={style.data}>有效期至{rule.lastDate}</div>
                                        </div>
                                        <div className={style.cardRight}>
                                            <div className={style.couamt}>
                                                <div className={style.couamtTitle}>￥</div>
                                                <div className={style.couamtNumber}>{rule.coupAmt}</div>
                                            </div>
                                        </div>
                                    </div>) : null)) :
                                <img className={style.noDataPic} src={noData} alt="" />
                            }
                        </div>
                        <div>
                            {amountsList.filter(v => v.sta == "2").length > 0 ? amountsList.map((rule: IAmounts, index) => (
                                rule.sta === "2" ? (
                                    <div className={style.amountsCard} key={index} style={{ background: 'url(' + past + ') no-repeat', backgroundSize: '100%' }}>
                                        <div className={style.cardLeft}>
                                            <div className={style.cardTitle}>{rule.coupName}</div>
                                            <div className={style.data}>有效期至{rule.lastDate}</div>
                                        </div>
                                        <div className={style.cardRight}>
                                            <div className={style.couamt}>
                                                <div className={style.couamtTitle}>￥</div>
                                                <div className={style.couamtNumber}>{rule.coupAmt}</div>
                                            </div>
                                        </div>
                                    </div>) : null)) :
                                <img className={style.noDataPic} src={noData} alt="" />
                            }
                        </div>
                    </Tabs>
                    {/* {amountsList.map((rule: IAmounts, index) => (
                                rule.sta === "0" ? (
                                    <div className={style.amountsCard} key={index} style={{ background: 'url(' + list + ') no-repeat', backgroundSize: '100%' }}>
                                        <div className={style.cardLeft}>
                                            <div className={style.cardTitle}>{rule.coupName}</div>
                                            <div className={style.data}>有效期至{rule.lastDate}</div>
                                        </div>
                                        <div className={style.cardRight}>
                                            <div className={style.couamt}>
                                                <div className={style.couamtTitle}>￥</div>
                                                <div className={style.couamtNumber}>{rule.coupAmt}</div>
                                            </div>
                                            <div className={style.exchange} onClick={() => { this.jump('/giftCode/' + rule.ticketCode) }}>立即使用</div>
                                        </div>
                                    </div>) : null))
                            } */}
                    {/* <div className={style.foot}>
                        <div className={style.newFoot} onClick={() => { this.props.history.push('/amountshistory') }}>使用记录</div>
                        <div className={style.rightFoot} onClick={() => { this.props.history.push('/exchangeamounts') }}>去兑换礼金券</div>
                    </div> */}
                </div>
            </div>
        );
    }


}

const mapStateToProps = (
    state: LoginInfo
): {
    from: FROM | null;
    padding?: string;
    userInfo?: UserInfo;
    userToken?: string;
} => ({
    from: state.from,
    padding: state.padding,
    userInfo: state.userInfo,
    userToken: state.userToken
});
export default connect(mapStateToProps)(withRouter<any, ComponentClass<IProps>>(Amounts));