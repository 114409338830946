import { CheckCircleOutlined, CloseCircleOutlined, ExclamationCircleOutlined, ShopOutlined } from '@ant-design/icons';
import { Icon, NavBar, Toast } from 'antd-mobile';
import React, { ComponentClass } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { get } from '../../axios/axios';
import { FROM, LOGINSTATE } from '../../store/enmu';
import { LoginInfo, UserInfo } from '../../store/type';
import { InvoiceForm } from '../Invoice';
import style from './index.module.css';


const mapStateToProps = (
    state: LoginInfo
  ): {
    userInfo?: UserInfo;
    state?: LOGINSTATE;
    from: FROM;
    padding?: string;
  } => ({
    userInfo: state.userInfo,
    state: state.state,
    from: state.from as FROM,
    padding: state.padding
  });
interface IProps extends LoginInfo, RouteComponentProps{

}
interface PayArray {
    amt: string,
    payCode: string,
    payName: string,
    payTime: string,
    payType: string,
    sno: number,
    tranNo: string
}
interface PluArray {
    amt: string,
    disAmt: string,
    pluBCD: string,
    pluId: number,
    pluName: string,
    qty: string,
    slprc: string,
    sno: number,
    taxCode: string,
    taxRate: string
}
interface OrderDetail {
    cashierNo?: string;
    posNo?: string;
    tranId?: string,
    tranType?: number,
    branName?: string,
    payArr?: PayArray[],
    pluArr?: PluArray[],
    amt?: string,
    saleDate?: string
}
interface IState {
    orderDetail: OrderDetail
}
declare var window: {
    [key: string]: any; // missing index defintion
    prototype: Window;
    new (): Window;
  };
interface InvoiceGood {
    index: string,
    attribute: string,
    discountIndex: string,
    name: string,
    sn: string,
    taxRate: string,
    priceIncludingTax: string,
    quantity: string,
    unit: string,
    model: string
}
export interface InvoiceData {
    amount: string,
    merOrderId: string,
    goodsDetail: InvoiceGood[]
}

class ResaleDetail extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            orderDetail: {}
        }
        this.getData();
    }
    async getData() {
        let params = this.props.match.params as {orderId: string}
        let data = await get('/api/user/getTranInfo', {tranId: params.orderId})
        if(data.code === 200) {
            this.setState({
                orderDetail: data.data.tranMain
            })
        }
    }
    render() {
        const {orderDetail} = this.state
        return (
            <div className={style.ResaleOrder}>
                <div
                className={style.orderHeader}
                >
                    {this.props.from != FROM.WXMINI?<NavBar
                        style={
                            this.props.padding
                            ? { marginTop: JSON.parse(this.props.padding).top + "px" }
                            : {}
                        }
                        className={style.navBar}
                        icon={
                        <Icon type="left" onClick={() => this.props.history.goBack()} />
                        }
                    >
                        零售订单详情
                    </NavBar>: null}
                </div>
                <div className={style.pageDetail}
                style={
                    this.props.padding
                    ? { marginTop: JSON.parse(this.props.padding).top + "px" }
                    : {}
                }>
                    <div className={style.orderStyle}>
                        {orderDetail.tranType === 1? <><CheckCircleOutlined style={{
                            width: '6.13vw',
                            height: '6.13vw',
                            float: 'left',
                            margin: '4.533vw 0 0 5.633vw',
                            fontSize: '6.13rem',
                            color: '#048779'
                        }}/><div className={style.orderType}>交易成功</div></>: <><ExclamationCircleOutlined style={{
                            width: '6.13vw',
                            height: '6.13vw',
                            float: 'left',
                            fontSize: '6.13rem',
                            margin: '4.533vw 0 0 5.633vw'
                        }}/><div className={style.orderType}>交易失败</div></>}
                    </div>
                    <div className={style.title}>商品详情</div>
                    <div className={style.amtList}>
                        <div className={style.shopName}><ShopOutlined className={style.shopIcon}/>{orderDetail.branName}</div>
                        {orderDetail.pluArr?.map((payInfo: PluArray) => (
                        <div className={style.payInfo}>
                            <div className={style.payName}>{payInfo.pluName}</div>
                            <div className={style.payQty}>×{payInfo.qty}</div>
                            <div className={style.saleMoney}>￥{payInfo.amt}</div>
                        </div>
                        ))}
                        <div className={style.payInfo}>
                            <div className={style.payMoney}>商品总价</div>
                            <div className={style.saleAllMoney}>￥{orderDetail.amt}</div>
                        </div>
                        <div className={style.payInfo}>
                            <div className={style.payMoney}>实付金额</div>
                            <div className={style.saleRealMoney}>￥{orderDetail.amt}</div>
                        </div>
                    </div>
                    <div className={style.title}>订单信息</div>
                    <div className={style.amtList}>
                        <div className={style.payInfo}>
                            <div className={style.payMoney}>下单时间</div>
                            <div className={style.saleAllMoney}>{orderDetail.saleDate}</div>
                        </div>
                        <div className={style.payInfo}>
                            <div className={style.payMoney}>订单编号</div>
                            <div className={style.saleAllMoney}>{orderDetail.tranId}</div>
                        </div>
                        <div className={style.payInfo}>
                            <div className={style.payMoney}>收银员号</div>
                            <div className={style.saleAllMoney}>{orderDetail.cashierNo}</div>
                        </div>
                        {/* <div className={style.payInfo}>
                            <div className={style.payMoney}>终端机号</div>
                            <div className={style.saleAllMoney}>{orderDetail.posNo}</div>
                        </div> */}
                    </div>
                    <div className={style.invoiceButton} onClick={() => this.invoice(orderDetail)}>开发票</div>
                </div>
            </div>
        );
    }
    async invoice(orderDetail: OrderDetail) {
        let res = await get('/api/user/checkInvoice', {
            merOrderId: orderDetail.tranId
        })
        if(res.code === 400) {
            var invoiceData: InvoiceForm = {
                buyerTaxCode: '',
                branName: orderDetail.branName as string,
                amount: orderDetail.amt as string,
                merOrderId: orderDetail.tranId as string,
                invoiceType: '1',
                buyerName: '',
                buyerAddress: '',
                buyerTelephone: '',
                buyerBank: '',
                buyerAccount: '',
                remark: '',
                notifyMobileNo: '',
                notifyEMail: '',
                goodsDetail: orderDetail.pluArr? orderDetail.pluArr.map((payInfo: PluArray, index: number) => ({
                    index: (index + 1).toFixed(0),
                    attribute: "0",
                    discountIndex: "",
                    name: payInfo.pluName,
                    sn: payInfo.taxCode && payInfo.taxCode !== ""? payInfo.taxCode: "3070401000000000000",
                    taxRate: payInfo.taxRate && payInfo.taxRate !== ""?payInfo.taxRate: "6",
                    priceIncludingTax: payInfo.amt,
                    quantity:"1",
                    unit:"",
                    model:""
                })): []
            }
            localStorage.setItem('orderDetail', JSON.stringify(invoiceData))
            this.props.history.push(`/invoice/${orderDetail.tranId}/resale`)
        } else if(res.code === 200){
            
            if(this.props.from === FROM.APP) {
                let props = this.props;
                Toast.info('即将跳转到浏览器查看发票',3)
                setTimeout(() => {
                    props.history.go(-1);
                    window['jsKaiFaPiao'].postMessage(res.data.pdfPreviewUrl);
                }, 3000)
            } else {
                window.location.replace(res.data.pdfPreviewUrl);
            }
            // window.location.replace(res.data.pdfPreviewUrl);
            // window['jsKaiFaPiao'].postMessage(res.data.pdfPreviewUrl);
            // this.props.history.push(`/OutURL/${encodeURIComponent(res.data.pdfPreviewUrl)}/开具发票`);
        }
    }
}

export default connect(mapStateToProps)(withRouter<any, ComponentClass<IProps>>(ResaleDetail));