import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { RouterProps, withRouter } from "react-router";
import { FROM } from "../../store/enmu";
import { LoginInfo } from "../../store/type";
import style from './index.module.css';
import counselBackground from '../../asset/counsel/counselBackground.png';
import { Icon, NavBar } from "antd-mobile";
import React from "react";
import { content, IContentListProps } from "../ContentList";
import OptionTitle, { titleInfo } from "../../components/OptionTitle";
import { get,post } from "../../axios/axios";
import { InformationRouter } from "../../routers";
import { Redirect, Route, Switch } from "react-router-dom";
import { ymd } from "../../components/ymd";
import { IContentPageProps } from "../ContentPage";
import PagingLoad from "../../components/PagingLoad";
import useMounted from "../../components/hooks/useMounted";
interface IProps extends RouterProps,LoginInfo {

}
const scorllDiv = React.createRef<HTMLDivElement>()
const contentDiv = React.createRef<HTMLDivElement>()
const OptionTitleRef = React.createRef<OptionTitle>()

const titleList = [
  {
    title: "园博新闻",
    path: "/contentList/news",
  },
  {
    title: "园博公告",
    path: "/contentList/announcement",
  },
  {
    title: "园博活动",
    path: "/contentList/event",
  },
  {
    title: "园博印象",
    path: "/contentList/impression",
  },
  // {
  //   title: "党政工作",
  //   path: "/contentList/party",
  // },
  {
    title: "游客攻略",
    path: "/contentList/guide",
  },
];
/**
 *（点击checkOption/加载页面） -> props.history.location.pathname -> active变化 -> active/pageNum触发请求
 *  pageNum变化 -> 触发请求
*/
const Information: React.FC<IProps> = (props: IProps) => {
    const [titleFixed, setTitleFixed] = useState(false);
    const [optioneTitleStyle, setOptioneTitleStyle] = useState([style.optioneTitle]);
    const [bodyStyle, setBodyStyle] = useState([style.body, style.bodyOnTop]);
    const [atBottom, setAtBottom] = useState(false);
    const [active, setActive] = useState(0); 
    const [pageSize, setPageSize] = useState(10);
    const [pageNum, setPageNum] = useState(1);
    const [settings, setSettings] = useState({
        dataList: [] as content[],
    } as IContentListProps | IContentPageProps);
    const [loading, setLoading] = useState(false);
    const [total, setTotal] = useState(0);
    useMounted(() => {
        OptionTitleRef.current?.refreshScroll();
    })
    // 路由改变触发的effect
    useEffect(() => {
        if(!loading){
            setTotal(0);
            setPageNum(1);
            switch(props.history.location.pathname) {
                case '/information/contentList/news': 
                    setActive(0);
                    setSettings({
                        dataList: [] as content[],
                        goItem: (id: number) => {
                            props.history.push(`/detail/information/news/${id}`);
                        }
                    })
                    break;
                case '/information/contentList/announcement': 
                    setActive(1);
                    setSettings({
                        dataList: [] as content[],
                        goItem: (id: number) => {
                            props.history.push(`/detail/information/announcement/${id}`);
                        }
                    })
                    break;
                case '/information/contentList/event': 
                    setActive(2);
                    setSettings({
                        dataList: [] as content[],
                        goItem: (id: number) => {
                            props.history.push(`/detail/information/event/${id}`);
                        }
                    })
                    break;
                case '/information/contentList/impression': 
                    setActive(3);
                    setSettings({
                        dataList: [] as content[],
                        goItem: (id: number) => {
                            props.history.push(`/detail/information/impression/${id}`);
                        }
                    })
                    break;
                case '/information/contentList/party': 
                    setActive(4);
                    setSettings({
                        dataList: [] as content[],
                        goItem: (id: number) => {
                            props.history.push(`/detail/information/party/${id}`);
                        }
                    })
                    break;
                case '/information/contentList/guide': 
                    // setActive(5);
                    setActive(4);
                    setSettings({
                        dataList: [] as content[],
                        goItem: (id: number) => {
                            props.history.push(`/detail/information/power/${id}`);
                        }
                    })
                    break;
            }         
        }
    }, [props.history.location.pathname]);
    useEffect(() => {
        if(!loading &&(total === 0 || (settings as IContentListProps).dataList && total > (settings as IContentListProps).dataList.length)){
            setLoading(true);
            switch(props.history.location.pathname) {
              case '/information/contentList/news': 
                    getNewsData();
                    break;
                case '/information/contentList/announcement': 
                    getAnnouncementData();
                    break;
                case '/information/contentList/event': 
                    getEventData();
                    break;
                case '/information/contentList/impression': 
                    getImpressionData();
                    break;
                case '/information/contentList/party': 
                    getPartyData();
                    break;
                case '/information/contentList/guide': 
                    getGuideData();
                    break;
            }
        }
    }, [active, pageNum]);
    const getNewsData = async() => {
      const requestData = await post('/api/news/list', {
        reviewStatus: 3,
        dispalyStatus: 1,
        pageSize: pageSize,
        pageNum: pageNum,
      });
      setLoading(false);
      if(requestData.code === 200) {
        let result: content[] = []
        requestData.data.forEach((element: any) => {
            result.push({
                  id: element.newId,
                  title: element.newTitle,
                  content: element.newContent,
                  imgUrl: element.newThumbnail,
                  createTime: ymd(new Date(element.createtime)),
            })
        });
        setSettings({
            dataList: [...(settings as IContentListProps).dataList, ...result],
            goItem: (id: number) => {
                props.history.push(`/detail/information/news/${id}`);
            }
        })
        setTotal(requestData.message);
      }
    }
    const getAnnouncementData = async() => {
      const requestData = await post('/api/announcement/list', {
        reviewStatus: 3,
        dispalyStatus: 1,
        pageSize: pageSize,
        pageNum: pageNum,
      });
      setLoading(false);
      if(requestData.code === 200) {
        let result: content[] = []
        requestData.data.forEach((element: any) => {
            result.push({
                  id: element.announcementId,
                  title: element.announcementTitle,
                  content: element.announcementContent,
                  imgUrl: element.announcementThumbnail,
                  createTime: ymd(new Date(element.createtime)),
            })
        });
        setSettings({
            dataList: [...(settings as IContentListProps).dataList, ...result],
            goItem: (id: number) => {
                props.history.push(`/detail/information/announcement/${id}`);
            }
        })
        setTotal(requestData.message);
      }
    }
    const getEventData = async() => {
      const requestData = await post('/api/event/list', {
        reviewStatus: 3,
        dispalyStatus: 1,
        pageSize: pageSize,
        pageNum: pageNum,
      });
      setLoading(false);
      if(requestData.code === 200) {
        let result: content[] = []
        requestData.data.forEach((element: any) => {
            result.push({
                  id: element.activityId,
                  title: element.activityTitle,
                  content: element.activityContent,
                  imgUrl: element.activityThumbnail,
                  createTime: ymd(new Date(element.createtime))
            })
        });
        setSettings({
            dataList: [...(settings as IContentListProps).dataList, ...result],
            goItem: (id: number) => {
                props.history.push(`/detail/information/event/${id}`);
            }
        })
        setTotal(requestData.message);
      }
    }
    const getImpressionData = async() => {
        const requestData = await post('/api/impression/list', {
            reviewStatus: 3,
            dispalyStatus: 1,
            pageSize: pageSize,
            pageNum: pageNum,
        });
        setLoading(false);
        if(requestData.code === 200) {
            let result: content[] = []
            requestData.data.forEach((element: any) => {
                result.push({
                  id: element.impressionId,
                  title: element.impressionTitle,
                  content: element.impressionContent,
                  imgUrl: element.impressionThumbnail,
                  createTime: ymd(new Date(element.createtime)),
                })
            });
            setSettings({
                dataList: [...(settings as IContentListProps).dataList, ...result],
                goItem: (id: number) => {
                    props.history.push(`/detail/information/impression/${id}`);
                }
            })
            setTotal(requestData.message);
        }
    }
    const getPartyData = async() => {
        const requestData = await post('/api/party/list', {
            reviewStatus: 3,
            pageSize: pageSize,
            pageNum: pageNum,
            dispalyStatus: 1
        });
        setLoading(false);
        if(requestData.code === 200) {
            let result: content[] = [];
            requestData.data.forEach((element: any) => {
                result.push({
                  id: element.partyId,
                  title: element.partyTitle,
                  content: element.partyContent,
                  imgUrl: element.partyThumbnail,
                  createTime: ymd(new Date(element.createtime)),
                })
            });
            setSettings({
                dataList: [...(settings as IContentListProps).dataList, ...result],
                goItem: (id: number) => {
                    props.history.push(`/detail/information/party/${id}`);
                }
            })
            setTotal(requestData.message);
        }
    }
    const getGuideData = async() => {
        const requestData = await get('/api/power/list', {
            reviewStatus: 3,
            pageSize: pageSize,
            pageNum: pageNum,
        });
        setLoading(false);
        if(requestData.code === 200) {
            let result: content[] = []
            requestData.data.forEach((element: any) => {
                result.push({
                  id: element.raidersId,
                  title: element.raidersTitle,
                  content: element.raidersContent,
                  imgUrl: element.raidersThumbnail,
                  createTime: ymd(new Date(element.createtime)),
                })
            });
            setSettings({
                dataList: [...(settings as IContentListProps).dataList, ...result],
                goItem: (id: number) => {
                    props.history.push(`/detail/information/power/${id}`);
                }
            })
            setTotal(requestData.message);
        }
    }
    const scroll = (scorllDiv: React.RefObject<HTMLDivElement>) => {
        if(scorllDiv.current) {
          var scole = Math.abs(scorllDiv.current.clientHeight + scorllDiv.current?.scrollTop - scorllDiv.current?.scrollHeight);
            if(scorllDiv.current?.scrollTop >= scorllDiv.current.clientWidth / 2 - 30 - (props.padding ?JSON.parse(props.padding).top : 0)) {
                setTitleFixed(true);
                setOptioneTitleStyle([style.optioneFixedTitle])
            } else {                  
                setTitleFixed(false);
                setOptioneTitleStyle([style.optioneTitle])
          }
        }
        if(contentDiv.current && scorllDiv.current && scorllDiv.current.scrollTop >= contentDiv.current.clientHeight - window.screen.height - 45) {
            setAtBottom(true);
        } else {      
            setAtBottom(false);
        }
    }
    const checkOption = (title: titleInfo) => {
        if(!loading){
            props.history.replace('/information' + title.path)
            if(scorllDiv.current) {
                if(scorllDiv.current?.scrollTop >= scorllDiv.current.clientWidth / 2 - 30 - (props.padding ?JSON.parse(props.padding).top : 0)) {
                    scorllDiv.current.scrollTop = scorllDiv.current.clientWidth / 2 - 30 - (props.padding ?JSON.parse(props.padding).top : 0);
                }
            }
        }
    }
    return (
        <div className={style.Counsel}>
            <PagingLoad atBottom={atBottom} load={() => {setPageNum(pageNum + 1)}}>
                <div className={style.top}
                    style={
                    props.padding
                    ? {
                        paddingTop: JSON.parse(props.padding).top + "px",
                        background: "url(" + counselBackground + ") no-repeat",
                        backgroundSize: "100%"
                    }
                    : {
                        background: "url(" + counselBackground + ") no-repeat",
                        backgroundSize: "100%"
                    }}
                >
                {
                    props.from != FROM.WXMINI?<NavBar
                    icon={<Icon onClick={() => props.history.goBack()} color="#fff" type="left" />}
                    onLeftClick={() => console.log('onLeftClick')}
                    className={style.NavBar}
                    mode="dark"
                    >园博资讯</NavBar>: null
                }
                    <div className={style.scorll}
                        ref={scorllDiv}
                        onScroll={() => scroll(scorllDiv)}
                        style={{height: props.padding? 'calc(100vh - ' + (45 + Number(JSON.parse(props.padding).top)) + 'px)':'calc(100vh - 45px)'}}>
                        <div className={bodyStyle.join(' ')} ref={contentDiv}>
                            <div className={optioneTitleStyle.join(" ")} style={{top: props.from === FROM.WXMINI? '0':(props.padding? (45 + Number(JSON.parse(props.padding).top)) + 'px':'45px')}}>
                                <OptionTitle active={active} titleList={titleList} showNumber={4} barfootWidth={7} ref={OptionTitleRef} onChange={(title:titleInfo) => checkOption(title)}/>
                            </div>
                            <div className={style.switchContent} style={{marginTop: titleFixed?'15vw': '0'}}></div>
                            <Switch>
                                {
                                    InformationRouter.children.map(router => (
                                        <Route path={router.path} render={() => {return <router.component {...settings}/>}} key={router.key}/>
                                    ))
                                }
                                <Redirect path="/" to="/information/contentList/news" />
                            </Switch>
                        </div>
                    </div>
                </div>
            </PagingLoad>
        </div>
    )
}
const mapStateToProps = (
    state: LoginInfo
  ): {
    from: FROM | null;
    padding?: string;
  } => ({
    from: state.from,
    padding: state.padding
  });
export default connect(mapStateToProps)(withRouter<any, FunctionComponent<IProps>>(Information));
export {titleList};