import 'es5-shim';
import 'es5-shim/es5-sham';
import 'console-polyfill';
import 'core-js/es';
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import React, { Suspense } from 'react'
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {Provider} from "react-redux";
import { createStore,combineReducers} from "redux";
import reducter from "./store/reducter";
import { BrowserRouter, Route, Switch } from 'react-router-dom'

let store = createStore(reducter);

ReactDOM.render(
  <BrowserRouter>
    {/* 使用了路由懒加载，所以需要使用<Suspense>包起来 */}
    <Suspense fallback={<div></div>}>       
      <Provider store={store}>
        <App/>
      </Provider>
    </Suspense>
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
