import React from 'react';
import OptionToast from './OptionToast';

interface IProps {

}
const OptionToastRef = React.createRef<OptionToast>();
var optionToast: OptionToast;
class ImportComponent extends React.Component<IProps> {
    constructor(props: IProps) {
        super(props);
    }
    componentDidMount() {
        optionToast = OptionToastRef.current? OptionToastRef.current: new OptionToast({});
    }
    render() {
        return (
            <div style={{width: '100vw', height: '100vh', position: 'fixed', pointerEvents: 'none'}}>
                <div style={{width: '100%',height: '100%', position: 'relative'}}>
                    <OptionToast ref={OptionToastRef}/>
                </div>
            </div>
        )
    }
}

export {optionToast};
export default ImportComponent;