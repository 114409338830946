import { Icon, NavBar } from 'antd-mobile';
import React, { ComponentClass } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import style from './index.module.css';
import headImg from '../../asset/aboutHome/headImg.png';
import ybjj from '../../asset/aboutHome/yuanbojianjie.png';
import ybjs from '../../asset/aboutHome/yuanbojingshen.png';
import zzjg from '../../asset/aboutHome/zuzhijiegou.png';
import ContentList, { ListData } from '../../components/ContentList';
import { get,post } from '../../axios/axios';
import { ymd } from '../../components/ymd';
import { FROM } from '../../store/enmu';
import { LoginInfo } from '../../store/type';
import { connect } from 'react-redux';

interface IProps extends RouteComponentProps,LoginInfo {

}

class AboutHome extends React.Component<IProps> {
    render() {
        const that = this;
        const settingList = {
            honorSettings: {
            async getData() {
                const requestData = await post('/api/honor/list', {
                reviewStatus: 3
                });
                if(requestData) {
                    var result: ListData = {
                        total: requestData.message,
                        dataList: []
                    };
                    requestData.data.forEach((element: any) => {
                        result.dataList.push({
                            id: element.gloryId,
                            title: element.gloryTitle,
                            content: element.gloryContent,
                            imgUrl: element.gloryThumbnail,
                            createTime: ymd(new Date(element.createtime))
                        })
                    });
                    return result
                }
            },
            async goItem(id: number) {
                that.props.history.push(`/detail/about/honor/${id}`);
            }
        },
        memorabiliaSettings: {
                async getData(pageSize: number, page: number) {
                    const requestData = await post('/api/memorabilia/list', {reviewStatus: 3,pageSize: pageSize,pageNum:page});
                    if(requestData) {
                        var result: ListData = {
                            total: requestData.message,
                            dataList: []
                        };
                        requestData.data.forEach((element: any) => {
                            result.dataList.push({
                                id: element.memorabiliaId,
                                title: element.memorabiliaTitle,
                                content: element.memorabiliaContent,
                                imgUrl: element.memorabiliaThumbnail,
                                createTime: ymd(new Date(element.createtime))
                            })
                        });
                        return result
                    }
                },
                async goItem(id: number) {
                    that.props.history.push(`/detail/about/memorabilia/${id}`);
                }
            },
            promoSettings: {
                async getData() {
                    const requestData = await post('/api/promo/list', {
                    reviewStatus: 3
                    });
                    if(requestData) {
                        var result: ListData = {
                            total: requestData.message,
                            dataList: []
                        };
                        requestData.data.forEach((element: any) => {
                            result.dataList.push({
                                id: element.promoId,
                                title: element.promoTitle,
                                content: element.promoContent,
                                imgUrl: element.promoThumbnail,
                                createTime: ymd(new Date(element.createtime))
                            })
                        });
                        return result
                    }
                },
                async goItem(id: number) {
                    that.props.history.push(`/detail/about/promo/${id}`);
                }
            },
            planSettings: {
                async getData(pageSize: number, page: number) {
                    const requestData = await post('/api/plan/list', {reviewStatus: 3,pageSize: pageSize,pageNum:page});
                    if(requestData) {
                    var result: ListData = {
                        total: requestData.message,
                        dataList: []
                    };
                    requestData.data.forEach((element: any) => {
                        result.dataList.push({
                            id: element.planningId,
                            title: element.planningTitle,
                            content: element.planningContent,
                            imgUrl: element.planningThumbnail,
                            createTime: ymd(new Date(element.createtime))
                        })
                    });
                    return result
                    }
                },
                async goItem(id: number) {
                    that.props.history.push(`/detail/about/plan/${id}`);
                }
            }
        }
        return (
            <div className={style.Counsel}>
                <div className={style.top}>
                {this.props.from != FROM.WXMINI?<NavBar
                    icon={<Icon onClick={() => this.props.history.goBack()} color="#000" type="left" />}
                    onLeftClick={() => console.log('onLeftClick')}
                    className={style.NavBar}
                    mode="light"
                    >关于园博</NavBar>: null}
                    <img className={style.headImg} src={headImg} alt="" />
                </div>
                <div className={style.jumpImgBox}>
                    <div className={style.jumpImg}>
                        <img src={ybjj} width="30%" height="auto" alt="" />
                        <span>园博简介</span>
                    </div>
                    <div className={style.jumpImg}>
                        <img src={zzjg} width="30%" height="auto" alt="" />
                        <span>组织结构</span>
                    </div>
                    <div className={style.jumpImg}>
                        <img src={ybjs} width="30%" height="auto" alt="" />
                        <span>园博精神</span>
                    </div>
                </div>
                <div className={style.AboutHomeTitle}>
                    <span><p>园博</p>视讯</span>
                </div>
                <ContentList {...settingList.promoSettings}/>
                <div className={style.AboutHomeTitle}>
                    <span><p>园博</p>荣耀</span>
                </div>
                <ContentList {...settingList.honorSettings}/>
                <div className={style.AboutHomeTitle}>
                    <span><p>园博</p>大事记</span>
                </div>
                <ContentList {...settingList.memorabiliaSettings}/>
                <div className={style.AboutHomeTitle}>
                    <span><p>园博</p>规划</span>
                </div>
                <ContentList {...settingList.planSettings}/>
            </div>
        );
    }
}

const mapStateToProps = (state: LoginInfo): {
    from: FROM | null
  } => ({
    from: state.from
  })
export default connect(mapStateToProps)(withRouter<any, ComponentClass<IProps>>(AboutHome));