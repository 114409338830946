import { Icon, NavBar } from 'antd-mobile';
import React, { ComponentClass } from 'react';
import { connect } from 'react-redux';
import { RouterProps, withRouter } from 'react-router';
import { get, post } from '../../axios/axios';
import { ymd } from '../../components/ymd';
import { FROM, LOGINSTATE } from '../../store/enmu';
import { LoginInfo, UserInfo } from '../../store/type';
import style from './index.module.css';
import noData from "../../asset/amountsExchange/noData.png"

const mapStateToProps = (
    state: LoginInfo
): {
    userInfo?: UserInfo;
    state?: LOGINSTATE;
    from: FROM;
    padding?: string;
} => ({
    userInfo: state.userInfo,
    state: state.state,
    from: state.from as FROM,
    padding: state.padding
});
interface IState {
    pageNo: number,
    timeBegin: string,
    timeEnd: string,
    orderList: any[]
}
interface IProps extends LoginInfo, RouterProps { }
class ResaleOrder extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        let now = new Date();
        let defaultStart = new Date();
        defaultStart.setDate(defaultStart.getDate() - 365);
        // 默认查询当前之前7天的数据
        this.state = {
            pageNo: 1,
            timeBegin: ymd(defaultStart),
            timeEnd: ymd(now),
            orderList: []
        }
        this.getData();
    }
    async getData() {
        let transRes = await post('/api/user/vipTransQuery', {
            mobileNo: this.props.userInfo?.phone,
            pageNo: this.state.pageNo,
            pageSize: 10,
            timeBegin: this.state.timeBegin,
            timeEnd: this.state.timeEnd
        });
        if (transRes.code === 200) {
            this.setState({
                pageNo: this.state.pageNo + 1,
                orderList: transRes.data.result
            })
        }
    }
    render() {
        const { orderList } = this.state
        return (
            <div className={style.ResaleOrder}>
                <div
                    className={style.orderHeader}
                >
                    {this.props.from != FROM.WXMINI ? <NavBar
                        style={
                            this.props.padding
                                ? { top: JSON.parse(this.props.padding).top + "px" }
                                : {}
                        }
                        className={style.navBar}
                        icon={
                            <Icon type="left" onClick={() => this.props.history.goBack()} />
                        }
                    >
                        零售订单详情
                    </NavBar> : null}
                </div>
                <div className={style.orderList}
                    style={
                        this.props.padding
                            ? { marginTop: JSON.parse(this.props.padding).top + "px" }
                            : {}
                    }>
                    {orderList && orderList.length > 0 ?
                        orderList.map((order) => (
                            <div className={style.order} onClick={() => { this.props.history.push('/resaleDetail/' + order.tranId) }}>
                                <div className={style.orderLeft}>
                                    <div className={style.orderName}>{order.branName}</div>
                                    <div className={style.orderTime}>{order.slDate}</div>
                                </div>
                                <div className={style.orderRight}>
                                    <div className={style.orderamt}>{order.amt}</div>
                                    <div className={style.orderInvoice}>
                                        {
                                            order.tranType === '1' ? '已完成' : '退货'
                                        }
                                    </div>
                                </div>
                            </div>
                        )) : <img className={style.noDataPic} src={noData} alt="" />
                    }
                </div>
            </div>
        )
    }
}

export default connect(mapStateToProps)(withRouter<any, ComponentClass<IProps>>(ResaleOrder));