import { Icon, NavBar, Tabs, ListView } from "antd-mobile";
import React, { ComponentClass } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { LoginInfo, UserInfo } from "../../store/type";
import style from "./index.module.css";
// import styles from './index.module.less';
import { get, post } from "../../axios/axios";
import { FROM } from "../../store/enmu";
interface IProps extends LoginInfo, RouteComponentProps { }
interface IState {
  list: [];
  dataSource: any
}
const mapStateToProps = (
  state: LoginInfo
): {
  userInfo?: UserInfo;
  from: FROM | null;
  padding?: string;
} => ({
  userInfo: state.userInfo,
  from: state.from,
  padding: state.padding,
});
class SuggestionList extends React.PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    const dataSource = new ListView.DataSource({
      rowHasChanged: (row1: any, row2: any) => row1 !== row2
    })

    console.log(dataSource)

    this.state = {
      list: [],
      dataSource: dataSource,
    };
    this.getData();

  }
  getData = async () => {
    if (this.props.userInfo) {
      let userId = this.props.userInfo.userId;
      let res = await post("/api/complaints/historylist", {
        userId: userId,
        complaintTypeId: 2,
        pageSize: 0
      });
      console.log(res.data);
      console.log(this.state.dataSource.cloneWithRows(res.data))
      this.setState({
        list: res.data,
        dataSource: this.state.dataSource.cloneWithRows(res.data),
      });
    }
  };
  jump(url?: number) {
    url && this.props.history.push(`/detail/suggestion/${url}`);
  }
  render() {
    const { list } = this.state;
    console.log(list);
    const row = (rowData: any, sectionID: any, rowID: any) => {
      let titleText;
      if (rowData.title !== undefined && rowData.title !== "" && rowData.title !== null) {
        titleText = rowData.title as string;
        if (titleText.length > 12) {
          titleText = titleText.substr(0, 12) + "...";
        }
      }

      return (
        <div className={style.adviceBox} key={rowID}>
          <span>{titleText}</span>
          <div
            className={style.rightText}
            onClick={() => this.jump(rowData.complaintId)}
          >
            {rowData.status === 0 ? (
              <span className={style.unReplyText}>未回复</span>
            ) : (
              <span className={style.replyText}>已回复</span>
            )}
            <Icon type="right" />
          </div>
        </div>
      )
    };

    const separator = (sectionID: any, rowID: any) => (
      <div
        key={`${sectionID}-${rowID}`}
        style={{
          height: 10,
          backgroundColor: '#f4f4f4',
          // borderBottom: '1px solid #f4f4f4',
        }}
      />
    );

    return (
      <div className={style.suggestionList}>
        <div
          className={style.orderHeader}
          style={
            this.props.padding
              ? { marginTop: JSON.parse(this.props.padding).top + "px" }
              : {}
          }
        >
          {this.props.from != FROM.WXMINI ? <NavBar
            className={style.navBar}
            icon={
              <Icon type="left" onClick={() => this.props.history.goBack()} />
            }
          >
            咨询建议
          </NavBar> : null}
        </div>


        <div className={style.list}>
          <ListView
            // ref={el => this.lv = el}
            dataSource={this.state.dataSource}
            //renderHeader={() => <span>header</span>}
            renderSeparator={separator}
            renderRow={row}
            // className={styles.list1}
            className='list1'
            pageSize={5}
            useBodyScroll
            // onScroll={() => { console.log('scroll'); }}
            scrollRenderAheadDistance={500}
            onEndReachedThreshold={10}
            initialListSize={0}
          // style={{height:"70%" ,overflow:"hidden"}}
          />
        </div>
        <div
          className={style.footer}
          onClick={() => this.props.history.push("/suggestion")}>咨询建议</div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(
  withRouter<any, ComponentClass<IProps, IState>>(SuggestionList)
);
