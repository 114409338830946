import React, { PropsWithChildren, ReactPropTypes, useState } from "react";

interface IProps {
    atBottom: Boolean;
    load?: Function;
}
const PagingLoad: React.FC<IProps> = (props: PropsWithChildren<IProps>) => {
    const [translate, setTranslate] = useState(0);
    const [moving, setMoving] = useState(0);
    const [firstMove, setFirstMove] = useState(true);
    return <div style={{float: 'left',transform: `translateY(-${translate / 6}px)`}}
     onTouchMove={(e: React.TouchEvent<HTMLDivElement>) => {
        if(props.atBottom) {
            setMoving(e.changedTouches[0].clientY);
            if(moving !== 0 || !firstMove) {
                console.log('moving' + moving)
                console.log(firstMove)
                setFirstMove(false)
                setTranslate(translate + moving - e.changedTouches[0].clientY);
            }
        } else {
            setTranslate(0);
        }
    }} 
    onTouchEnd={(e: React.TouchEvent<HTMLDivElement>) => {
        setFirstMove(true)
        setMoving(0);
        setTranslate(0);
        if(translate > 100 && props.load) {
            props.load();
        }
    }}>
        {props.children}
    </div>
}
export default PagingLoad;