import React, { ComponentClass } from 'react';
import { connect } from 'react-redux';
import { RouterProps, withRouter } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';
import { LoginInfo } from '../../store/type';

interface IProps extends RouteComponentProps, LoginInfo { }

declare var window: {
    [key: string]: any; // missing index defintion
    prototype: Window;
    new(): Window;
};
class GZHRedirect extends React.Component<IProps>{
    constructor(props: IProps) {
        super(props);
        console.log(props);
        const params = props.match.params as { opetion: string }
        switch (params.opetion) {
            case "buyTicket":
                // 公众号购票
                if (props.userInfo && props.userInfo.publicOpenid && props.userInfo.publicOpenid !== '' && props.userInfo.publicOpenid !== null && props.userInfo.phone && props.userInfo.phone !== '' && props.userInfo.phone !== null) {
                    window.location.replace(`https://wxticket.xzyby.cn/?openId=${props.userInfo?.publicOpenid}&memberId=${props.userInfo?.phone}`)
                } else {
                    localStorage.setItem('redirectURL', '/GZHRedirect/buyTicket')
                    props.history.push('/accountLogin')
                }
        }
    }
    render() {
        return (
            <div></div>
        );
    }
}


export default connect((state) => state)(withRouter<any, ComponentClass<IProps>>(GZHRedirect));