import { aboutMap } from "./about";
import { guideMap } from "./guide";
import { informationMap } from "./information";
import { touristNoticeMap } from "./touristNotice";

interface KF {
    [key: string]: Function
}
const service: {[key: string]: KF} = {
    about: aboutMap,
    information: informationMap,
    touristNotice: touristNoticeMap,
    guide: guideMap
}
export default service;