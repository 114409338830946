import { RightOutlined } from '@ant-design/icons';
import { Input, Radio, RadioChangeEvent } from 'antd';
import { Icon, NavBar } from 'antd-mobile';
import React, { ChangeEvent, ComponentClass } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';
import { get } from '../../axios/axios';
import { FROM, LOGINSTATE } from '../../store/enmu';
import { LoginInfo, UserInfo } from '../../store/type';
import { InvoiceForm } from '../Invoice';
import { InvoiceData } from '../ResaleDetail';
import style from './index.module.css';


const mapStateToProps = (
    state: LoginInfo
  ): {
    userInfo?: UserInfo;
    state?: LOGINSTATE;
    from: FROM;
    padding?: string;
  } => ({
    userInfo: state.userInfo,
    state: state.state,
    from: state.from as FROM,
    padding: state.padding
  });
interface IProps extends RouteComponentProps, LoginInfo {}
interface IState {
    form: InvoiceForm
}
class InvoiceAmt extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            form: {
                ...localStorage.getItem('orderDetail') !== null? JSON.parse(localStorage.getItem('orderDetail') as string): {},
            }
        }

    }
    goInvoiceMore() {
        localStorage.setItem('orderDetail', JSON.stringify(this.state.form))
        this.props.history.push('/invoiceMore');
    }
    render() {
        const { form } = this.state;
        return (
            <div className={style.ResaleOrder}>
                <div
                className={style.orderHeader}
                >
                    {this.props.from != FROM.WXMINI?<NavBar
                        style={
                            this.props.padding
                            ? { marginTop: JSON.parse(this.props.padding).top + "px" }
                            : {}
                        }
                        className={style.navBar}
                        icon={
                        <Icon type="left" onClick={() => this.props.history.goBack()} />
                        }
                    >
                        开具发票
                    </NavBar>: null}
                </div>
                <div className={style.pageDetail}
                    style={
                        this.props.padding
                        ? { marginTop: JSON.parse(this.props.padding).top + "px" }
                        : {}
                    }>
                    <div className={style.amtBoxLine}>
                        <div className={style.amtTitle}>发票总额</div>
                        <div className={style.amtNum}>{form.amount}</div>
                    </div>
                    <div  className={style.infoBoxLine}>
                        <div className={style.invoiceBorderLine}>
                            <div className={style.invoiceBorderLineLeft}>消费店铺</div><div className={style.invoiceBorderLineRight}>{form.branName}</div>
                        </div>
                        <div className={style.invoiceBorderLine}>
                            <div className={style.invoiceBorderLineLeft}>开票方</div><div className={style.invoiceBorderLineRight}>徐州园博园</div>
                        </div>
                        <div className={style.invoiceBorderLine}>
                            <div className={style.invoiceBorderLineLeft}>发票总额</div><div className={style.invoiceBorderLineRight}>{form.amount}元</div>
                        </div>
                    </div>
                    <div className={style.invoiceButton} onClick={() => this.props.history.goBack()}>确定</div>
                </div>
            </div>
        )
    }
}

export default connect(mapStateToProps)(withRouter<any, ComponentClass<IProps>>(InvoiceAmt));