import { message } from "antd";
import { Toast } from "antd-mobile";
import { NavBarProps } from "antd-mobile/lib/nav-bar/PropsType";
import React from "react";
import { connect } from "react-redux";
import { RouterProps, withRouter } from "react-router-dom";
import { FROM } from "../../store/enmu";
import { LoginInfo } from "../../store/type";
import SvgLog from "../SvgLog";
import style from "./index.module.css";

interface INavbarProps extends RouterProps, LoginInfo {}
interface INavbarState {}

const mapStateToProps = (
  state: LoginInfo
): {
  from: FROM | null;
  padding?: string;
} => ({
  from: state.from,
  padding: state.padding,
});

class Navbar extends React.Component<INavbarProps, INavbarState> {
  constructor(props: INavbarProps) {
    super(props);
  }
  click(path: string) {
    if (path === "order") {
      Toast.info("敬请期待");
      return;
    }
    let pathName = this.props.history.location.pathname.split("/");
    this.setState({
      onCheck: pathName[2],
    });
    pathName[2] = path;
    this.props.history.push(pathName.join("/"));
  }
  render() {
    let pathName = this.props.history.location.pathname.split("/");
    const onCheck = pathName[2];
    let bottom = "0";
    if (this.props.padding && this.props.padding !== null) {
      bottom = JSON.parse(this.props.padding as string).bottom + "px";
    }
    if (this.props.from !== FROM.WXMINI) {
      return (
        <div className={style.Navbar} style={{ paddingBottom: bottom, height:`calc(16vw + ${bottom}px)` }}>
          <div
            className={
              onCheck === "home" ? (style.checkbar, style.bar) : style.bar
            }
            onClick={() => this.click("home")}
          >
            <div className={style.barImg}>
              {/* <div className={onCheck === 'home'? style.checkbarBall: ''}></div>
                            <SvgLog 
                                name="NavHome"
                                width="100%"
                                height="100%"
                                color={onCheck === '' ? '#000000':'#666666'}
                                viewBox="0 0 33 33"/> */}
              {onCheck === "home" ? (
                <SvgLog
                  name="NavHome"
                  width="100%"
                  height="100%"
                  color={""}
                  viewBox="0 0 28 28"
                />
              ) : (
                <SvgLog
                  name="NavHomeClose"
                  width="100%"
                  height="100%"
                  color={""}
                  viewBox="0 0 28 28"
                />
              )}
            </div>
            <div className={style.barText}>首页</div>
          </div>
          <div
            className={
              onCheck === "gis" ? (style.checkbar, style.bar) : style.bar
            }
            onClick={() => this.click("gis")}
          >
            <div className={style.barImg}>
              {/* <div className={onCheck === 'gis'? style.checkbarBall: ''}></div>
                            <SvgLog 
                                name="NavGis"
                                width="100%"
                                height="100%"
                                color={onCheck === 'gis' ? '#000000':'#666666'}
                                viewBox="0 0 33 33"/> */}

              {onCheck === "gis" ? (
                <SvgLog
                  name="NavGis"
                  width="100%"
                  height="100%"
                  color={""}
                  viewBox="0 0 28 28"
                />
              ) : (
                <SvgLog
                  name="NavGisClose"
                  width="100%"
                  height="100%"
                  color={""}
                  viewBox="0 0 28 28"
                />
              )}
            </div>
            <div className={style.barText}>导览</div>
          </div>
          {/* <div
            className={
              onCheck === "order" ? (style.checkbar, style.bar) : style.bar
            }
            onClick={() => this.click("order")}
          >
            <div className={style.barImg}>

              {onCheck === "order" ? (
                <SvgLog
                  name="NavOrder"
                  width="100%"
                  height="100%"
                  color={""}
                  viewBox="0 0 28 28"
                />
              ) : (
                <SvgLog
                  name="NavOrderClose"
                  width="100%"
                  height="100%"
                  color={""}
                  viewBox="0 0 28 28"
                />
              )}
            </div>
            <div className={style.barText}>订单</div>
          </div> */}
          <div
            className={
              onCheck === "mine" ? (style.checkbar, style.bar) : style.bar
            }
            onClick={() => this.click("mine")}
          >
            <div className={style.barImg}>
              {/* <div className={onCheck === 'mine'? style.checkbarBall: ''}></div>
                            <SvgLog 
                                name="NavMine"
                                width="100%"
                                height="100%"
                                color={onCheck === 'mine' ? '#000000':'#666666'}
                                viewBox="0 0 33 33"/> */}
              {onCheck === "mine" ? (
                <SvgLog
                  name="NavMine"
                  width="100%"
                  height="100%"
                  color={""}
                  viewBox="0 0 28 28"
                />
              ) : (
                <SvgLog
                  name="NavMineClose"
                  width="100%"
                  height="100%"
                  color={""}
                  viewBox="0 0 28 28"
                />
              )}
            </div>
            <div className={style.barText}>我的</div>
          </div>
        </div>
      );
    } else {
      return null;
    }
  }
}

export default connect(mapStateToProps)(
  withRouter<any, React.ComponentClass<INavbarProps, INavbarState>>(Navbar)
);
