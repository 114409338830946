import { RightOutlined } from '@ant-design/icons';
import { Drawer, Input, message, Radio, RadioChangeEvent, Button } from 'antd';
import { Icon, NavBar, Toast } from 'antd-mobile';
import Form from 'rc-field-form/es/Form';
import React, { ChangeEvent, ComponentClass } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';
import { post } from '../../axios/axios';
import { FROM, LOGINSTATE } from '../../store/enmu';
import { LoginInfo, UserInfo } from '../../store/type';
import { InvoiceData } from '../ResaleDetail';
import style from './index.module.css';
import qs from "querystring";

const mapStateToProps = (
    state: LoginInfo
): {
    userInfo?: UserInfo;
    state?: LOGINSTATE;
    from: FROM;
    padding?: string;
    userToken?: string;
} => ({
    userInfo: state.userInfo,
    state: state.state,
    from: state.from as FROM,
    padding: state.padding,
    userToken: state.userToken
});
interface IProps extends RouteComponentProps, LoginInfo { }
export interface InvoiceForm extends InvoiceData {
    buyerTaxCode: string;
    invoiceType: string,
    buyerName: string,
    buyerAddress: string,
    buyerTelephone: string,
    buyerBank: string,
    buyerAccount: string,
    remark: string,
    branName: string,
    notifyMobileNo: string,
    notifyEMail: string,
    // ticketUseTime: string,
}
interface InvoiceRequest {
    buyerTaxCode: string;
    invoiceType: string,
    buyerName: string,
    buyerAddress: string,
    buyerTelephone: string,
    buyerBank: string,
    buyerAccount: string,
    remark: string,
    branName: string,
    amount: string,
    merOrderId: string,
    goodsDetail: string,
    notifyMobileNo: string,
    notifyEMail: string,
    // ticketUseTime: string,
}
declare var window: {
    [key: string]: any; // missing index defintion
    prototype: Window;
    new(): Window;
};
interface IState {
    visible: boolean,
    form: InvoiceForm,
    loading: boolean,
    titleVisible: string
}
class Invoice extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        var orderDetail = localStorage.getItem('orderDetail');

        const { search } = props.location;
        const { titleVisible } = qs.parse(search.slice(1));

        this.state = {
            visible: false,
            form: {
                ...orderDetail !== null ? JSON.parse(orderDetail as string) : {},
            },
            loading: false,
            titleVisible: titleVisible as string,
        }
        // console.log(this.state)
        // console.log(props.location.search)
    }
    async invoice() {
        if (this.state.form.invoiceType == '2') {
            this.state.form.buyerTaxCode = ''
        }

        this.state.form.buyerName = this.state.form.buyerName.replace(/\s*/g, "")
        this.state.form.buyerTaxCode = this.state.form.buyerTaxCode.replace(/\s*/g, "")

        var form: InvoiceRequest = {
            ...this.state.form,
            goodsDetail: JSON.stringify(this.state.form.goodsDetail),
            invoiceType: "PLAIN",
            amount: (Number(this.state.form.amount) * 100).toFixed(0)
        };
        console.log(form)
        const that = this;
        this.setState({ loading: true })
        let data = await post('/api/user/invoice', form)
        // console.log(data)
        if (data.code === 200) {
            // Toast.loading('请稍等')
            if (data.data.resultCode === "BAD_REQUEST") {
                Toast.info('请确认开票信息是否正确')
            } else {
                let props = this.props;
                let checkData = await post('/api/user/checkInvoiceStatus', data.data1)
                if (checkData.code === 200 && checkData.data.resultCode == "SUCCESS") {
                    let waitTimer = setInterval(async () => {
                        if (checkData.data.status == "ISSUING") {
                            checkData = await post('/api/user/checkInvoiceStatus', data.data1)
                        } else if (checkData.data.status == "ISSUED") {
                            clearInterval(waitTimer);
                            this.setState({ loading: false })
                            if (this.props.from === FROM.APP) {
                                props.history.go(-1);
                                window['jsKaiFaPiao'].postMessage(data.data.qrCode);
                            } else {
                                window.location.replace(data.data.qrCode);
                            }
                        }
                    }, 3000);
                } else {
                    setTimeout(() => {
                        this.setState({ loading: false })
                        if (this.props.from === FROM.APP) {
                            props.history.go(-1);
                            window['jsKaiFaPiao'].postMessage(data.data.qrCode);
                        } else {
                            window.location.replace(data.data.qrCode);
                        }
                    }, 6000)
                }

                // setTimeout(() => {
                //     if (this.props.from === FROM.APP) {
                //         Toast.info('即将跳转到浏览器查看发票', 3)
                //         setTimeout(() => {
                //             window['jsKaiFaPiao'].postMessage(data.data.qrCode);
                //             props.history.go(-2);
                //         }, 3000)
                //     } else {
                //         window.location.replace(data.data.qrCode);
                //     }
                //     // that.props.history.push(`/OutURL/${encodeURIComponent(data.data.pdfPreviewUrl)}/开具发票`);
                // }, 5000)
            }
        } else {
            Toast.fail(data.message, 1);
            this.setState({ loading: false })
        }
    }
    goInvoiceMore() {
        localStorage.setItem('orderDetail', JSON.stringify(this.state.form))
        this.props.history.push('/invoiceMore');
    }
    showInvoice() {
        if (this.state.form.invoiceType === '1') {
            if (this.state.form.buyerName === '') {
                Toast.info('公司名称不可为空');
                return false;
            } else if (this.state.form.buyerTaxCode === '') {
                Toast.info('公司税号不可为空');
                return false;
            }
        } else {
            if (this.state.form.buyerName === '') {
                Toast.info('抬头名称不可为空');
                return false;
            }
        }
        if (this.state.form.notifyMobileNo && !/^[1][3,4,5,6,7,8,9][0-9]{9}$/.test(this.state.form.notifyMobileNo)) {
            Toast.info('手机号格式错误')
            return false
        }
        let reg = /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/
        if (this.state.form.notifyEMail && !reg.test(this.state.form.notifyEMail)) {
            Toast.info("邮箱格式错误");
            return false
        }
        this.setState({
            visible: true
        })
    }
    render() {
        const { form, visible, loading, titleVisible } = this.state;
        return (
            <div className={style.ResaleOrder}>
                <div
                    className={style.orderHeader}
                >
                    {(this.props.from != FROM.WXMINI && titleVisible != "2") || (this.props.from != FROM.WXMINI && !titleVisible) ? <NavBar
                        style={
                            this.props.padding
                                ? { marginTop: JSON.parse(this.props.padding).top + "px" }
                                : {}
                        }
                        className={style.navBar}
                        icon={
                            <Icon type="left" onClick={() => {
                                if (this.props.history.location.pathname.split('/')[1] === 'invoice' && this.props.history.location.pathname.split('/')[3] === 'ticket') {
                                    switch (this.props.from) {
                                        case FROM.APP:
                                            this.props.history.replace(`/OutURL/${encodeURIComponent(`https://h5ticket.xzyby.cn/Ticket/MyOrder?memberId=${this.props.userInfo?.phone}&openId=${this.props.userToken}&type=2`)}/我的订单`)
                                            break;
                                        case FROM.H5:
                                            console.log((`/OutURL/${encodeURIComponent(`https://wxticket.xzyby.cn/Ticket/MyOrder?memberId=${this.props.userInfo?.phone}&openId=${this.props.userToken}&type=2`)}/我的订单`))
                                            this.props.history.replace(`/OutURL/${encodeURIComponent(`https://wxticket.xzyby.cn/Ticket/MyOrder?memberId=${this.props.userInfo?.phone}&openId=${this.props.userToken}&type=2`)}/我的订单`)
                                            break;
                                        case FROM.WXMINI:
                                            this.props.history.replace(`/OutURL/${encodeURIComponent(`https://ticket.xzyby.cn/Ticket/MyOrder?memberId=${this.props.userInfo?.phone}&openId=${this.props.userToken}&type=2`)}/我的订单`)
                                            break;
                                    }
                                } else {
                                    this.props.history.goBack()
                                }
                            }} />
                        }
                    >
                    </NavBar> : null}
                </div>
                <div className={style.pageDetail}
                    style={
                        this.props.padding
                            ? { marginTop: JSON.parse(this.props.padding).top + "px" }
                            : {}
                    }>
                    <div className={style.title}>发票详情</div>
                    <div className={style.formGroup}>
                        <div className={style.form}>
                            <div className={style.formName}>抬头类型</div>
                            <Radio.Group className={style.radio} value={form.invoiceType} onChange={(e: RadioChangeEvent) => {
                                this.setState({ form: { ...form, invoiceType: e.target.value } })
                            }}>
                                <Radio value={'1'}>企业单位</Radio>
                                <Radio value={'2'}>个人/非企业单位</Radio>
                            </Radio.Group>
                        </div>
                        {form.invoiceType === '2' ?
                            <>
                                <div className={style.line}></div>
                                <div className={style.form}>
                                    <div className={style.formName}>抬头名称</div>
                                    <Input className={style.input} placeholder={'填写抬头名称'} value={form.buyerName} onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                        this.setState({ form: { ...form, buyerName: e.target.value } })
                                    }} />
                                </div>
                            </>
                            :
                            <>
                                <div className={style.line}></div>
                                <div className={style.form}>
                                    <div className={style.formName}>公司名称</div>
                                    <Input className={style.input} placeholder={'填写公司名称'} value={form.buyerName} onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                        this.setState({ form: { ...form, buyerName: e.target.value } })
                                    }} />
                                </div>
                                <div className={style.line}></div>
                                <div className={style.form}>
                                    <div className={style.formName}>公司税号</div>
                                    <Input className={style.input} placeholder={'填写公司税号'} value={form.buyerTaxCode} onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                        this.setState({ form: { ...form, buyerTaxCode: e.target.value } })
                                    }} />
                                </div>
                            </>}
                        <div className={style.line}></div>
                        <div className={style.form}>
                            <div className={style.formName}>更多内容</div>
                            <div className={[style.input, style.clickInput].join(' ')} onClick={() => this.goInvoiceMore()}>填写备注、地址等非必填项</div>
                            <RightOutlined className={style.right} />
                        </div>
                    </div>
                    <div className={style.title}></div>
                    <div className={style.formGroup}>
                        <div className={style.form} onClick={() => this.props.history.push('/invoiceAmt')}>
                            <div className={style.formName}>总金额</div>
                            <div className={[style.input, style.amtInput].join(' ')}><span>{form.amount}</span><p>元</p></div>
                            <div className={style.message}>共1张，查看详情<RightOutlined /></div>
                        </div>
                    </div>
                </div>
                <div className={style.invoiceButton} onClick={() => this.showInvoice()}>确定</div>

                <div className={style.title}>接收方式(非必填)</div>
                <div className={style.formGroup}>
                    <div className={style.line}></div>
                    <div className={style.form}>
                        <div className={style.formName}>电子邮箱</div>
                        <Input className={style.input} placeholder={'填写电子邮箱'} value={form.notifyEMail} onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            this.setState({ form: { ...form, notifyEMail: e.target.value } })
                        }} />
                    </div>
                    <div className={style.line}></div>
                    <div className={style.form}>
                        <div className={style.formName}>手机号码</div>
                        <Input className={style.input} placeholder={'填写手机号码'} value={form.notifyMobileNo} onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            this.setState({ form: { ...form, notifyMobileNo: e.target.value } })
                        }} />
                    </div>
                </div>

                <Drawer
                    title="开具电子发票"
                    placement={'bottom'}
                    closable={false}
                    onClose={() => this.setState({ visible: false })}
                    visible={visible}
                >
                    <div>
                        {form.invoiceType === '1' ?
                            <>
                                <div className={style.invoiceBorderLine}>
                                    <div className={style.invoiceBorderLineLeft}>公司名称</div><div className={style.invoiceBorderLineRight}>{form.buyerName}</div>
                                </div>
                                <div className={style.invoiceBorderLine}>
                                    <div className={style.invoiceBorderLineLeft}>公司税号</div><div className={style.invoiceBorderLineRight}>{form.buyerTaxCode}</div>
                                </div>
                                <Button className={style.invoiceButton1} loading={loading} onClick={() => this.invoice()}>确定</Button></> :
                            <>
                                <div className={style.invoiceBorderLine}>
                                    <div className={style.invoiceBorderLineLeft}>抬头名称</div><div className={style.invoiceBorderLineRight}>{form.buyerName}</div>
                                </div>
                                <Button className={style.invoiceButton1} loading={loading} onClick={() => this.invoice()}>确定</Button></>
                        }
                    </div>
                </Drawer>
            </div>
        )
    }
}

export default connect(mapStateToProps)(withRouter<any, ComponentClass<IProps>>(Invoice));