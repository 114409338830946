import React from 'react';
import style from './index.module.css';

export interface titleInfo{
    title: string,
    path: string
}
interface IOptionTitleProps {
    titleList: titleInfo[],
    showNumber: number,
    barfootWidth: number,
    onChange: Function,
    active?: number
}
interface IOptionTitleState {
    barStyle: React.CSSProperties,
    active: number
}
const scrollRef = React.createRef<HTMLDivElement>();
class OptionTitle extends React.Component<IOptionTitleProps, IOptionTitleState>{
    constructor(props: IOptionTitleProps) {
        super(props);
        this.state = {
            barStyle: {
                left: (props.active? props.active: 0) * 100 / props.showNumber + (100 / props.showNumber - props.barfootWidth)/2 + '%',
                width: props.barfootWidth + '%'
            },
            active: props.active? props.active:0
        }
    }
    refreshScroll() {
        if(scrollRef.current){
            scrollRef.current.scrollLeft = (this.props.active? this.props.active: 0) * scrollRef.current.clientWidth / this.props.showNumber
        }
    }
    componentWillUpdate(props: IOptionTitleProps){
        if(props.active !== this.props.active){
            this.setState({
                barStyle: {
                    left: (props.active? props.active: 0) * 100 / props.showNumber + (100 / props.showNumber - props.barfootWidth)/2 + '%',
                    width: props.barfootWidth + '%'
                },
                active: props.active? props.active:0
            })
        }
    }
    checkOption(title: titleInfo, index: number) {
        this.setState({
            barStyle: {
                ...this.state.barStyle,
                left: (index * 100 / this.props.showNumber + (100 / this.props.showNumber - this.props.barfootWidth)/2) + '%'
            },
            active: index
        });
        this.props.onChange(title);
    }
    render() {
        const { barStyle, active } = this.state;
        const { titleList } = this.props;
        return (
            <div className={style.optionsBox} ref={scrollRef}>
                <div className={style.optionList}>
                    {
                        titleList.map((title, index) => {
                            return (
                            <div className={ active===index?[style.active,style.option].join(' '): style.option } style={{width: 100/this.props.showNumber + '%'}} key={index} onClick={() => this.checkOption(title, index)}>{title.title}</div>
                            )
                        })
                    }
                    <div className={style.barFoot} style={barStyle}></div>
                </div>
            </div>
        )
    }
}

export default OptionTitle;